import React, { useEffect, useState } from "react";
import "./PricePlan.scss";
import { Box } from "@mui/material";
import Headline from "../../../components/Comon/Headline/Headline";
import BusinessPlan from "../../../components/Admin Panel/BusinessPlan/BusinessPlan";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSubscriptionPlan,
  getBillingPortal,
} from "../../../../core/axios/services";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";
import { addUser } from "../../../../redux/actions/user";

const PricePlan = ({ showGlobalSnackbar }) => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "businessPlan":
          changePlan(app.popUpData?.selectedType);
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [app]);

  useEffect(() => {
    getBillingPortal(user).then((res) => {
      setUrl(res.data.data.url);
    });
  }, [user]);

  const changePlan = (type) => {
    showGlobalSnackbar(i18n.t("pleaseWait"));
    changeSubscriptionPlan(user, type)
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      });
  };

  const updateBusinessPlan = (plan) => {
    if (plan.id === user?.user?.clinic?.businessPlan?.id) {
      window.open(url, "_self");
    } else {
      if (plan.id === 3) {
        window.location = "mailto:support@chiropracticoutcomes.com";
      } else {
        const data = {
          showPopup: true,
          data: {
            title: i18n.t("confirmUpgradePlan"),
            confirmButton: i18n.t("confirmUpgradePlanButton"),
            denyButton: i18n.t("denyUpgradePlanButton"),
            type: "businessPlan",
            selectedType: plan.id,
          },
        };
        dispatch(showPopup(data));
      }
    }
  };

  return (
    <div className="PricePlan">
      <Box className={"sectionChange sectionChangePlans"}>
        <Headline text={i18n.t("pricePlans")} type={"Headline-medium"} />

        <Box className={"PlansContainer"}>
          {app.app_data?.businessPlans.map((businessPlan, index) => {
            return (
              <Box className={"otherBusinessPlanContainer"} key={index}>
                {businessPlan.name === "Advanced" ? (
                  <div className="mostPopular">Most popular</div>
                ) : (
                  <div className="hide">hide</div>
                )}
                <BusinessPlan
                  isSignup={false}
                  changePlan={(plan) => updateBusinessPlan(plan)}
                  app={app}
                  i18n={i18n}
                  isSelected={
                    user.user?.clinic?.businessPlan.id === businessPlan.id
                  }
                  businessPlan={businessPlan}
                  isUpgradable={
                    user.user?.clinic?.businessPlan.id !== businessPlan.id
                  }
                  mode={"other"}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </div>
  );
};

export default PricePlan;
