import React, { useState } from "react";
import { Box } from "@mui/material";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Headline from "../../../components/Comon/Headline/Headline";
import Button from "../../../components/Comon/Button/Button";
import Dropdown from "../../../components/Admin Panel/Dropdown/Dropdown";
import {practiceHubSetApiKey, practiceHubSyncUsers, practiceHubUpdateSettings} from "../../../../core/axios/services";
import {useDispatch, useSelector} from "react-redux";
import {addUser} from "../../../../redux/actions/user";
import { Oval } from "react-loader-spinner";
import Accordion from "../SurveySettings/Accordion";
import {Tooltip as ReactTooltip} from "react-tooltip";

import practiceLogo from "../../../../../src/hubLogo.png";

export const Integrations = ({ app, i18n, showGlobalSnackbar }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();


  const [apiKeyFormData, setApiKeyFormData] = useState({
    apiKey: user?.user?.clinic?.practiceHubSettings?.apiKey ?? '',
    accountDomain: user?.user?.clinic?.practiceHubSettings?.accountDomain ?? '',
    accountRegion: user?.user?.clinic?.practiceHubSettings?.accountRegion ?? '',
  });
  const [apiKeyErrorData, setApiKeyErrorData] = useState();
  const [apiKeyStatus, setApiKeyStatus] = useState(false)

  async function handleSetApiKey() {
    setApiKeyStatus(true);
    let response = await practiceHubSetApiKey(apiKeyFormData, user);
    if (response?.status === 200) {
      dispatch(addUser(response.data.data));
      setApiKeyStatus(false);
      showGlobalSnackbar(i18n.t("saved"));
    } else {
      setApiKeyStatus(false);
      setApiKeyErrorData(response?.response?.data?.messages);
    }
  }

  let sendingOptions = [
    {id: 1, name: "Before (pending)", value: "before",},
    {id: 2, name: "After (processed)", value: "after",},
  ];
  let numOfDaysOptions = [
    {id: 0, name: "Same day", value: 0},
    {id: 1, name: "1 Day", value: 1},
    {id: 2, name: "2 Days", value: 2},
    {id: 3, name: "3 Days", value: 3},
    {id: 4, name: "4 Days", value: 4},
    {id: 5, name: "5 Days", value: 5},
    {id: 6, name: "6 Days", value: 6},
    {id: 7, name: "7 Days", value: 7},
  ];

  const [sendingInfoFormData, setSendingInfoFormData] = useState({
    defaultSurveyType: user?.user?.clinic?.practiceHubSettings?.defaultSurveyType ?? null,
    defaultSurveyLanguage: user?.user?.clinic?.practiceHubSettings?.defaultSurveyLanguage ?? null,

    //Survey 1
    emailBody: user?.user?.clinic?.practiceHubSettings?.emailBody ?? null,
    emailSubject: user?.user?.clinic?.practiceHubSettings?.emailSubject ?? null,
    appointmentTypeTrigger: user?.user?.clinic?.practiceHubSettings?.appointmentTypeTrigger ?? null,
    sendingOption: user?.user?.clinic?.practiceHubSettings?.sendingOption ? sendingOptions.find(obj => obj.value === user?.user?.clinic?.practiceHubSettings?.sendingOption) : null,
    numOfDaysSending: numOfDaysOptions.find(obj => obj.value === (user?.user?.clinic?.practiceHubSettings?.numOfDaysSending ?? null)),

    //Survey 2-6
    emailBodyNext: user?.user?.clinic?.practiceHubSettings?.emailBodyNext ?? null,
    emailSubjectNext: user?.user?.clinic?.practiceHubSettings?.emailSubjectNext ?? null,
    nextAppointmentTypeTrigger: user?.user?.clinic?.practiceHubSettings?.nextAppointmentTypeTrigger ?? null,
    nextSendingOption: user?.user?.clinic?.practiceHubSettings?.nextSendingOption ? sendingOptions.find(obj => obj.value === user?.user?.clinic?.practiceHubSettings?.nextSendingOption) : null,
    nextNumOfDaysSending: numOfDaysOptions.find(obj => obj.value === user?.user?.clinic?.practiceHubSettings?.nextNumOfDaysSending ?? null),
  });
  const [sendingInfoErrorData, setSendingInfoErrorData] = useState();
  const [sendingInfoStatus, setSendingInfoStatus] = useState(false)
  async function handleSendingInfo() {
    setSendingInfoStatus(true);
    let response = await practiceHubUpdateSettings({
      ...sendingInfoFormData,
      sendingOption: sendingInfoFormData?.sendingOption?.value,
      numOfDaysSending: sendingInfoFormData?.numOfDaysSending?.value,

      nextSendingOption: sendingInfoFormData?.nextSendingOption?.value,
      nextNumOfDaysSending: sendingInfoFormData?.nextNumOfDaysSending?.value,
    }, user)
    if (response?.status === 200) {
      dispatch(addUser(response.data.data));
      showGlobalSnackbar(i18n.t("saveAndSyncMessage"));
      setSendingInfoErrorData(null);
    } else {
      setSendingInfoErrorData(response?.response?.data?.messages);
    }
    setSendingInfoStatus(false);
  }


  const [syncUsersErrorData, setSyncUsersErrorData] = useState();
  const [syncUsersStatus, setSyncUsersStatus] = useState(false)
  async function handleSyncUsers() {
    setSyncUsersStatus(true);
    let response = await practiceHubSyncUsers(user);
    if (response?.status === 200) {
      dispatch(addUser(response.data.data));
      showGlobalSnackbar(i18n.t("syncSuccessMessage"));
      setSyncUsersErrorData(null);
    } else {
      setSyncUsersErrorData(response?.response?.data?.messages);
    }
    setSyncUsersStatus(false);
  }


  function surveyHasErrors(){
    return sendingInfoErrorData?.["appointmentTypeTrigger.id"] ||
      sendingInfoErrorData?.["sendingOption"] ||
      sendingInfoErrorData?.["numOfDaysSending"] ||
        sendingInfoErrorData?.["emailBody"] ||
        sendingInfoErrorData?.["emailSubject"]
        ;
  }
  function surveyNextHasErrors(){
    return sendingInfoErrorData?.["nextAppointmentTypeTrigger.id"] ||
      sendingInfoErrorData?.["nextSendingOption"] ||
      sendingInfoErrorData?.["nextNumOfDaysSending"] ||
        sendingInfoErrorData?.["emailBodyNext"] ||
        sendingInfoErrorData?.["emailSubjectNext"]
        ;
  }


  const [showFailedSyncUsers, setShowFailedSyncUsers] = useState(false);


  return (
    <div className="integrations">
      <Box className={"mainProfile"}>
        {/* <Headline text={i18n.t("practiceHub")} type={"Headline-medium"} /> */}
        <img
          className="practiceLogo"
          src={practiceLogo}
          alt={practiceLogo}
        />

        <Box className={"sectionChange"}>
          <Box className={"textInputContainer"}>
            <TextInput
              type={"password"}
              setValue={(value) =>
                setApiKeyFormData({ ...apiKeyFormData, apiKey: value })
              }
              label={i18n.t("APIKey")}
              value={apiKeyFormData?.apiKey}
              mode={"grey"}
              explanation={i18n.t("keyDesc")}
              errorMessage={apiKeyErrorData?.apiKey}
            />
            <TextInput
              type={"text"}
              setValue={(value) =>
                setApiKeyFormData({ ...apiKeyFormData, accountDomain: value })
              }
              label={i18n.t("accountDomain")}
              value={apiKeyFormData?.accountDomain}
              mode={"grey"}
              explanation={i18n.t("accDesc")}
              errorMessage={apiKeyErrorData?.accountDomain}
            />
            <TextInput
              type={"text"}
              setValue={(value) =>
                setApiKeyFormData({ ...apiKeyFormData, accountRegion: value })
              }
              label={i18n.t("acccountRegion")}
              value={apiKeyFormData?.accountRegion}
              mode={"grey"}
              explanation={i18n.t("accRegionDesc")}
              errorMessage={apiKeyErrorData?.accountRegion}
            />
            <Box
              style={{ marginTop: "0px" }}
              className={"buttonContainer"}
              onClick={() => handleSetApiKey()}
            >
              <Button
                text={
                  apiKeyStatus ? (
                    <Oval
                      height={20}
                      width={20}
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#fff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    i18n.t("confirmSurveyNotes")
                  )
                }
                mode={"small"}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {user?.user?.clinic?.hasPracticeHubIntegrated && (
        <>
          <Box className={"mainProfile"}>
            <div style={{ marginBottom: "30px" }}>
              <Headline
                text={i18n.t("automateProcess")}
                type={"Headline-medium"}
              />
            </div>

            <Box
              style={{ marginBottom: "30px" }}
              className={"integrationDropdownWrapper"}
            >
              <div className={"integrationDropdown"}>
                <span className="dropdownLable">
                  {i18n.t("defaultSurveyType")}
                </span>
                <Dropdown
                  type={"statistics"}
                  setSelectedOption={(option) =>
                    setSendingInfoFormData({
                      ...sendingInfoFormData,
                      defaultSurveyType: option,
                    })
                  }
                  selectedOption={sendingInfoFormData?.defaultSurveyType}
                  options={[
                    { id: 1, name: "ChiroForm" },
                    { id: 2, name: "ChiroForm +" },
                    { id: 3, name: "ChiroForm (CP)" },
                  ]}
                  app={app}
                  placeholder="Select"
                />
                {sendingInfoErrorData?.["defaultSurveyType.id"] &&
                  sendingInfoErrorData?.["defaultSurveyType.id"]?.map(
                    (item, index) => {
                      return (
                        <div key={index} className={"errorMessage"}>
                          {item}
                        </div>
                      );
                    }
                  )}
              </div>
              <div className={"integrationDropdown"}>
                <span className="dropdownLable">
                  {i18n.t("defaultSurveyLanguage")}
                </span>
                <Dropdown
                  type={"statistics"}
                  setSelectedOption={(option) =>
                    setSendingInfoFormData({
                      ...sendingInfoFormData,
                      defaultSurveyLanguage: option,
                    })
                  }
                  selectedOption={sendingInfoFormData?.defaultSurveyLanguage}
                  options={user?.user?.clinic?.languages}
                  app={app}
                  placeholder="Select"
                />
                {sendingInfoErrorData?.["defaultSurveyLanguage.id"] &&
                  sendingInfoErrorData?.["defaultSurveyLanguage.id"]?.map(
                    (item, index) => {
                      return (
                        <div key={index} className={"errorMessage"}>
                          {item}
                        </div>
                      );
                    }
                  )}
              </div>
            </Box>

            <Accordion
              title={"Survey 1"}
              showErrorUnderline={surveyHasErrors()}
            >
              <Box className={"integrationDropdownWrapper"}>
                <div className={"integrationDropdown"}>
                  <span className="dropdownLable">
                    {i18n.t("appointmentTypeTrigger")}
                  </span>
                  <Dropdown
                    type={"statistics"}
                    setSelectedOption={(option) =>
                      setSendingInfoFormData({
                        ...sendingInfoFormData,
                        appointmentTypeTrigger: option,
                      })
                    }
                    selectedOption={sendingInfoFormData?.appointmentTypeTrigger}
                    options={
                      user?.user?.clinic?.practiceHubSettings?.appointmentTypes
                    }
                    app={app}
                    placeholder="Select"
                  />
                  {sendingInfoErrorData?.["appointmentTypeTrigger.id"] &&
                    sendingInfoErrorData?.["appointmentTypeTrigger.id"]?.map(
                      (item, index) => {
                        return (
                          <div key={index} className={"errorMessage"}>
                            {item}
                          </div>
                        );
                      }
                    )}
                </div>
                <div className={"integrationDropdown"}>
                  <span className="dropdownLable">
                    {i18n.t("sendSurveyAppointment")}
                  </span>
                  <div className="dropdownGridWrap">
                    <div>
                      <Dropdown
                        type={"statistics"}
                        setSelectedOption={(option) =>
                          setSendingInfoFormData({
                            ...sendingInfoFormData,
                            sendingOption: option,
                          })
                        }
                        selectedOption={sendingInfoFormData?.sendingOption}
                        options={sendingOptions}
                        app={app}
                        placeholder="Select"
                      />
                      {sendingInfoErrorData?.["sendingOption"] &&
                        sendingInfoErrorData?.["sendingOption"]?.map(
                          (item, index) => {
                            return (
                              <div key={index} className={"errorMessage"}>
                                {item}
                              </div>
                            );
                          }
                        )}
                    </div>

                    <div>
                      <Dropdown
                        type={"statistics"}
                        setSelectedOption={(option) =>
                          setSendingInfoFormData({
                            ...sendingInfoFormData,
                            numOfDaysSending: option,
                          })
                        }
                        selectedOption={sendingInfoFormData?.numOfDaysSending}
                        options={numOfDaysOptions}
                        app={app}
                        placeholder="Select"
                      />
                      {sendingInfoErrorData?.["numOfDaysSending"] &&
                        sendingInfoErrorData?.["numOfDaysSending"]?.map(
                          (item, index) => {
                            return (
                              <div key={index} className={"errorMessage"}>
                                {item}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>

                <div className={"integrationDropdown"}>
                  <TextInput
                    maxlength={500}
                    type={"text"}
                    setValue={(value) =>
                      setSendingInfoFormData({
                        ...sendingInfoFormData,
                        emailSubject: value,
                      })
                    }
                    label={i18n.t("emailSubject")}
                    value={sendingInfoFormData?.emailSubject}
                    mode={"grey"}
                    errorMessage={sendingInfoErrorData?.emailSubject}
                  />
                </div>

                <div className={"integrationDropdown"}>
                  <TextInput
                    maxlength={500}
                    type={"textarea"}
                    setValue={(value) =>
                      setSendingInfoFormData({
                        ...sendingInfoFormData,
                        emailBody: value,
                      })
                    }
                    label={i18n.t("emailBody")}
                    value={sendingInfoFormData?.emailBody}
                    mode={"grey"}
                    errorMessage={sendingInfoErrorData?.emailBody}
                  />
                </div>
              </Box>
            </Accordion>

            <Accordion
              title={"Survey 2-6"}
              showErrorUnderline={surveyNextHasErrors()}
            >
              <Box className={"integrationDropdownWrapper"}>
                <div className={"integrationDropdown"}>
                  <span className="dropdownLable">
                    {i18n.t("appointmentTypeTrigger")}
                  </span>
                  <Dropdown
                    type={"statistics"}
                    setSelectedOption={(option) =>
                      setSendingInfoFormData({
                        ...sendingInfoFormData,
                        nextAppointmentTypeTrigger: option,
                      })
                    }
                    selectedOption={
                      sendingInfoFormData?.nextAppointmentTypeTrigger
                    }
                    options={
                      user?.user?.clinic?.practiceHubSettings?.appointmentTypes
                    }
                    app={app}
                    placeholder="Select"
                  />
                  {sendingInfoErrorData?.["nextAppointmentTypeTrigger.id"] &&
                    sendingInfoErrorData?.[
                      "nextAppointmentTypeTrigger.id"
                    ]?.map((item, index) => {
                      return (
                        <div key={index} className={"errorMessage"}>
                          {item}
                        </div>
                      );
                    })}
                </div>
                <div className={"integrationDropdown"}>
                  <span className="dropdownLable">
                    {i18n.t("sendSurveyAppointment")}
                  </span>
                  <div className="dropdownGridWrap">
                    <div>
                      <Dropdown
                        type={"statistics"}
                        setSelectedOption={(option) =>
                          setSendingInfoFormData({
                            ...sendingInfoFormData,
                            nextSendingOption: option,
                          })
                        }
                        selectedOption={sendingInfoFormData?.nextSendingOption}
                        options={sendingOptions}
                        app={app}
                        placeholder="Select"
                      />
                      {sendingInfoErrorData?.["nextSendingOption"] &&
                        sendingInfoErrorData?.["nextSendingOption"]?.map(
                          (item, index) => {
                            return (
                              <div key={index} className={"errorMessage"}>
                                {item}
                              </div>
                            );
                          }
                        )}
                    </div>

                    <div>
                      <Dropdown
                        type={"statistics"}
                        setSelectedOption={(option) =>
                          setSendingInfoFormData({
                            ...sendingInfoFormData,
                            nextNumOfDaysSending: option,
                          })
                        }
                        selectedOption={
                          sendingInfoFormData?.nextNumOfDaysSending
                        }
                        options={numOfDaysOptions}
                        app={app}
                        placeholder="Select"
                      />
                      {sendingInfoErrorData?.["nextNumOfDaysSending"] &&
                        sendingInfoErrorData?.["nextNumOfDaysSending"]?.map(
                          (item, index) => {
                            return (
                              <div key={index} className={"errorMessage"}>
                                {item}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>

                <div className={"integrationDropdown"}>
                  <TextInput
                    maxlength={500}
                    type={"text"}
                    setValue={(value) =>
                      setSendingInfoFormData({
                        ...sendingInfoFormData,
                        emailSubjectNext: value,
                      })
                    }
                    label={i18n.t("emailSubject")}
                    value={sendingInfoFormData?.emailSubjectNext}
                    mode={"grey"}
                    errorMessage={sendingInfoErrorData?.emailSubjectNext}
                  />
                </div>

                <div className={"integrationDropdown"}>
                  <TextInput
                    maxlength={500}
                    type={"textarea"}
                    setValue={(value) =>
                      setSendingInfoFormData({
                        ...sendingInfoFormData,
                        emailBodyNext: value,
                      })
                    }
                    label={i18n.t("emailBody")}
                    value={sendingInfoFormData?.emailBodyNext}
                    mode={"grey"}
                    errorMessage={sendingInfoErrorData?.emailBodyNext}
                  />
                </div>
              </Box>
            </Accordion>

            <Box
              style={{ marginTop: "24px" }}
              className={"buttonContainer"}
              onClick={() => handleSendingInfo()}
            >
              <Button
                text={
                  sendingInfoStatus ? (
                    <Oval
                      height={20}
                      width={20}
                      color="#fff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#fff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    i18n.t("saveSync")
                  )
                }
                mode={"small"}
              />
            </Box>
          </Box>

          {/* MANUAL SYNC */}
          {user?.user?.clinic?.practiceHubSettings?.canFetchUsers && (
            <Box className={"mainProfile"}>
              <Headline
                text={i18n.t("manualSyncOptions")}
                type={"Headline-medium"}
              />
              <p style={{ marginTop: "42px", maxWidth: "580px" }}>
                {i18n.t("manualDesc")}
              </p>
              <Box
                style={{ marginTop: "10px", marginBottom: "40px" }}
                className={"buttonContainer"}
                onClick={() => handleSyncUsers()}
              >
                <Button
                  text={
                    syncUsersStatus ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#fff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#fff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      i18n.t("syncNow")
                    )
                  }
                  mode={"small"}
                />
              </Box>
            </Box>
          )}
          {/* END MANUAL SYNC */}

          {/* FAILED TO SYNC USERS */}
          {user?.user?.clinic?.practiceHubSettings?.failedToSyncUsersData
            ?.length !== 0 && (
            <>
              <Box className={"mainProfile"}>
                <Headline
                  text={i18n.t("failedToSyncTitle")}
                  type={"Headline-medium"}
                />
                <p style={{ marginTop: "42px", maxWidth: "580px" }}>
                  {i18n.t("failedToSyncDesc")}
                </p>

                <Box
                  style={{ marginTop: "10px", marginBottom: "40px" }}
                  className={"buttonContainer"}
                  onClick={() => setShowFailedSyncUsers(true)}
                >
                  <Button text={i18n.t("failedToSyncButton")} mode={"small"} />
                </Box>
              </Box>

              {showFailedSyncUsers && (
                <div className="ScreenOverlay">
                  <div className="ScreenContent">
                    <h2>Patients Not Fully Synced</h2>

                    <div
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        maxHeight: "50vh",
                        overflowY: "scroll",
                      }}
                    >
                      {user?.user?.clinic?.practiceHubSettings?.failedToSyncUsersData?.map(
                        (item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                id={`tooltip_${item.id}`}
                                style={{ cursor: "pointer" }}
                              >
                                <strong>
                                  {item?.firstName} {item?.lastName}:
                                </strong>{" "}
                                <span style={{ color: "red" }}>
                                  {item?.reason}
                                </span>
                                <ReactTooltip
                                  anchorId={`tooltip_${item.id}`}
                                  place="right"
                                  content={`PracticeHub ID: ${item?.id}`}
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>

                    <Box
                      style={{ marginTop: "10px", marginBottom: "40px" }}
                      className={"buttonContainer"}
                      onClick={() => setShowFailedSyncUsers(false)}
                    >
                      <Button text={i18n.t("Close")} mode={"small"} />
                    </Box>
                  </div>
                </div>
              )}
            </>
          )}
          {/* END FAILED TO SYNC USERS */}
        </>
      )}
    </div>
  );
};
