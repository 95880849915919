import React, { useEffect, useState } from "react";
import { I18nContext } from "react-i18next";
import Headline from "../../../components/Comon/Headline/Headline";
import "./PractitionersProfile.scss";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Accordion from "../SurveySettings/Accordion";
import { getPractitionerStatistics } from "../../../../core/axios/services";
import StarRating from "./StarRating";
import NumberRating from "./NumberRating";

const Statistics = ({ user, practitioner, showGlobalSnackbar }) => {
  const { t, i18n } = useTranslation();
  const [bottomShadow, setBottomShadow] = useState("/assets/shadowBottom.png");
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    try {
      getPractitionerStatistics(user, practitioner.id).then((res) => {
        console.log(res.data, "STAT INFO");
        setStatistics(res.data);
      });
    } catch (err) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  }, []);

  const rating = statistics?.serviceRate;
  const serviceRate = statistics?.recommendRate;

  return (
    <>
      <div className="stastContent">
        <Headline
          sx={{
            marginBottom: "60px",
            textAlign: "left !important;",
          }}
          text={i18n.t("practitionerStatisticInformations")}
          type={"Headline-big-left"}
        />
        <Box className={"sectionChange"}>
          <Headline text={i18n.t("howWouldYouRate")} type={"Headline-medium"} />
          <div className="content">
            <div className="rateSection">
              <StarRating rating={rating} />
              <div className="rateStat">
                <span>{rating}</span> average rating
              </div>
            </div>
          </div>
        </Box>
        <Box className={"sectionChange"}>
          <Headline
            text={i18n.t("notFiveStarsHeadline")}
            type={"Headline-medium"}
          />
          <div className="wrapper">
            <Accordion title={i18n.t("answers")}>
              <div className="dropdownList">
                {statistics?.allRecommendations.map((item, index) => {
                  return (
                    <div className="accordionContent" key={index}>
                      <div className="accordionTop">
                        <h2>{item.name}</h2>
                        <StarRating rating={item.rate} />
                      </div>
                      <div className="accordionBottom">
                        <p>{item.comment}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {statistics?.allRecommendations.length == 7 && (
                <img
                  className="shadowBottom"
                  src={bottomShadow}
                  alt={bottomShadow}
                />
              )}
            </Accordion>
          </div>
        </Box>
        <Box className={"sectionChange"}>
          <Headline
            // text={i18n.t("recommendQuiropractica")}
            text={`How likely are you to recommend ${user.user.clinic.name} to a friend or a family member?`}
            type={"Headline-medium"}
          />
          <div className="content">
            <div className="numberStats">
              <div className="numberStatsContent">
                <div className="typeStatMob">
                  <div className="severenessText">1 → Unlikely</div>
                  <div className="severenessText">10 → Very likely</div>
                </div>
                <NumberRating rating={serviceRate} />
                <div className="typeStat">
                  <p>Unlikely</p>
                  <p>Very likely</p>
                </div>
              </div>
              <div className="rateStat">
                <span>{serviceRate}</span> average rating
              </div>
            </div>
          </div>
        </Box>
        <Box className={"sectionChange"}>
          <Headline text={i18n.t("referredClinic")} type={"Headline-medium"} />
          <div className="content">
            <div className="statisticsPeople">
              <h3>{statistics?.recommendCount}</h3>
              <span>people</span>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default Statistics;
