import React, {useEffect, useState} from 'react';
import './CustomCheckbox.scss';
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from "@mui/material";
import {useSelector} from "react-redux";
import getThemes from "../../../../constants/colors/getTheme";

const CustomCheckbox = ({text, value, setValue}) => {
    const app = useSelector((state) => state.app);
    const [themeColor, setThemeColor] = useState('');

    useEffect(() => {
        setThemeColor(getThemes('mainColor', app.appTheme));
    }, [app]);


    return (
      <div className="Checkbox">
          <FormControlLabel
              className="checkboxLabel"
              control={
                  <Checkbox onChange={() => setValue(!value)} checked={value}
                            sx={{
                                color: themeColor,
                                '&.Mui-checked': {
                                    color: themeColor,
                                }
                            }}
                  />
              }
              label={text}
          />
      </div>
  );
};

export default CustomCheckbox;
