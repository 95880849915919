import React, { useEffect, useState } from "react";
import "./PatientProfileSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {
  updateSurveyPage,
  updateSurveyPatient,
  updateSurveySetting,
} from "../../../../../redux/actions/survey";
import { useDispatch } from "react-redux";
import {
  checkIfPatientExists,
  getSettingsFromCode,
} from "../../../../../core/axios/services";

const PatientProfileSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
  isEditable,
}) => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [themeColor, setThemeColor] = useState("");
  const dispatch = useDispatch();
  const [name, setName] = useState(
    survey.patient?.name ? survey.patient.name : ""
  );
  const [lastName, setLastName] = useState(
    survey.patient?.lastName ? survey.patient.lastName : ""
  );
  const [email, setEmail] = useState(
    survey.patient?.email ? survey.patient.email : ""
  );

  console.log(survey.patient?.name, "PATIENT NAME");
  console.log(survey.patient?.lastName, "PATIENT LAST NAME");

  useEffect(() => {
    dispatch(updateSurveyPage(1));
    window.scrollTo(0, 0);
  }, []);

  const submit = (data) => {
    if (isEditable !== true) {
      checkPatientEmail();
    }
  };

  const checkPatientEmail = async () => {
    try {
      return await checkIfPatientExists(email).then((res) => {
        if (res.data.data.taken === true) {
          showGlobalSnackbar(i18n.t("emailTaken"));
        } else {
          dispatch(
            updateSurveyPatient({
              name: name,
              lastName: lastName,
              email: email,
            })
          );

          if (survey.settings.type === 1 || survey.settings.type === 3) {
            //basic
            dispatch(updateSurveyPage(3));
            navigate("/survey/" + code + "/health-concerns");
          } else if (survey.settings.type === 2) {
            //advanced
            dispatch(updateSurveyPage(2));
            navigate("/survey/" + code + "/health-stresses");
          }
        }
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  return (
    <div className="SecondScreenSurvey">
      <SurveyHero
        // step={i18n.t("patientsProfile")}
        headline={survey.settings.patientHeadline}
        text={survey.settings.introText}
        buttonText={i18n.t("submit")}
        clickedButton={(data) => submit(data)}
        app={app}
        isForm={true}
        surveyType={survey.settings.type}
        i18n={i18n}
        email={email}
        setEmail={setEmail}
        name={name}
        setName={setName}
        lastName={lastName}
        setLastName={setLastName}
        isEditable={isEditable}
      />
    </div>
  );
};

export default PatientProfileSurvey;
