import * as types from "../types/actionTypes";

export const updateSurveySetting = (settings) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SURVEY_SETTINGS,
            payload: settings,
        });
    };
};

export const updateSurveyPatient = (patient) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SURVEY_PATIENT,
            payload: patient,
        });
    };
};

export const updateSurveyPatientId = (patientId) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SURVEY_PATIENT_ID,
            payload: patientId,
        });
    };
};

export const updateSurveyData = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SURVEY_DATA,
            payload: data,
        });
    };
};

export const updateSurveyPage = (page) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SURVEY_PAGE,
            payload: page,
        });
    };
};

export const deleteSurveyData = () => {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_SURVEY_DATA
        });
    };
};
export const updatePreviousSurveyData = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_PREVIOUS_SURVEY_DATA,
            payload: data,
        });
    };
};

export const deletePreviousSurveyData = () => {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_PREVIOUS_SURVEY_DATA
        });
    };
};
