import React, { useEffect, useState } from "react";
import "./SideNavigation.scss";
import Logo from "../../Comon/Logo/Logo";
import SideNavigationElement from "../SideNavigationElement/SideNavigationElement";
import i18n from "i18next";
import { useNavigate, useMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../redux/actions/user";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";

const SideNavigation = ({ app, user, onClick }) => {
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [defLogo, setDefLogo] = useState("/assets/logotm.png");
  const [activePage, setActivePage] = useState("myAccount");
  const navigate = useNavigate();
  const isPatients = useMatch("dashboard");
  const isPatientsPaid = useMatch("dashboard-paid");
  const isPatient = useMatch("/dashboard/patient/*");
  const isUsers = useMatch("/dashboard/users");
  const isPractitioners = useMatch("/dashboard/practitioners");
  const isPractitioner = useMatch("/dashboard/practitioner");
  const isPractitionerProfile = useMatch("/dashboard/practitioner/*");
  const isUser = useMatch("/dashboard/user/*");
  const isStatistics = useMatch("/dashboard/statistics");
  const isAccount = useMatch("/dashboard/account");
  const isSurvey = useMatch("/dashboard/settings");
  const isHelp = useMatch("/dashboard/help");
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    // setLogo(app.app_data?.logo);
    getActivePage();
  }, []);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "logout":
          logout();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [app]);

  useEffect(() => {
    if (user.user && user.user.clinic && user.user.clinic.logo) {
      setLogo(user.user.clinic.logo);
    }
  }, [user]);

  const getActivePage = () => {
    if (isPatients || isPatient || isPatientsPaid) {
      setActivePage("patients");
    } else if (isUsers || isUser) {
      setActivePage("users");
    } else if (isPractitioners || isPractitioner || isPractitionerProfile) {
      setActivePage("practitioners");
    } else if (isStatistics) {
      setActivePage("statistics");
    } else if (isAccount) {
      setActivePage("myAccount");
    } else if (isSurvey) {
      setActivePage("surveySettings");
    } else if (isHelp) {
      setActivePage("help");
    }
  };

  const setPage = (page) => {
    if (page === "logOut") {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("areYouSureYouWantToLogout"),
          confirmButton: i18n.t("confirmLogout"),
          denyButton: i18n.t("denyLogout"),
          type: "logout",
        },
      };
      dispatch(showPopup(data));
      return;
    }

    setActivePage(page);
    let fixedPage = "/dashboard/" + page;
    if (page === "patients") {
      fixedPage = "/dashboard";
    } else if (page === "myAccount") {
      fixedPage = "/dashboard/account";
    } else if (page === "surveySettings") {
      fixedPage = "/dashboard/settings";
    } else if (page === "helpCenter") {
      fixedPage = "/dashboard/help";
    }

    navigate(fixedPage);
  };

  const logout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <div className="SideNavigation">
      <div className={"logoContainer"}>
        {logo === "https://api.chiropracticoutcomes.com/storage/logotm.png" ? (
          <img src={defLogo} className="defaultLogo" />
        ) : (
          <Logo src={logo} style={{ width: 100 }} />
        )}
        {/* <Logo src={logo} style={{ width: 100 }} /> */}
      </div>

      <div className={"NavigationElements"} onClick={onClick}>
        <div className={"topElements"}>
          <div
            onMouseEnter={() => setHovered("patients")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_Patients"}
              app={app}
              title={"patients"}
              setActivePage={setPage}
              active={activePage === "patients" || hovered === "patients"}
            />
          </div>
          <div
            onMouseEnter={() => setHovered("users")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_Users"}
              app={app}
              title={"users"}
              setActivePage={setPage}
              active={activePage === "users" || hovered === "users"}
            />
          </div>
           <div
            onMouseEnter={() => setHovered("practitioners")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_My-Account"}
              app={app}
              title={"practitioners"}
              setActivePage={setPage}
              active={
                activePage === "practitioners" || hovered === "practitioners"
              }
            />
          </div>
          <div
            onMouseEnter={() => setHovered("statistics")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_Statistics"}
              app={app}
              title={"statistics"}
              setActivePage={setPage}
              active={activePage === "statistics" || hovered === "statistics"}
            />
          </div>
        </div>

        <div className={"bottomElements"}>
          <div
            onMouseEnter={() => setHovered("myAccount")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_My-Account"}
              app={app}
              title={"myAccount"}
              setActivePage={setPage}
              active={activePage === "myAccount" || hovered === "myAccount"}
            />
          </div>
          <div
            onMouseEnter={() => setHovered("surveySettings")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_Survey-Settings"}
              app={app}
              title={"surveySettings"}
              setActivePage={setPage}
              active={
                activePage === "surveySettings" || hovered === "surveySettings"
              }
            />
          </div>
          <div
            onMouseEnter={() => setHovered("help")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_Help"}
              app={app}
              title={"helpCenter"}
              setActivePage={setPage}
              active={activePage === "help" || hovered === "help"}
            />
          </div>
          <div
            onMouseEnter={() => setHovered("logOut")}
            onMouseLeave={() => setHovered(null)}
          >
            <SideNavigationElement
              icon={"Icon_Logout"}
              app={app}
              title={"logOut"}
              setActivePage={setPage}
              active={activePage === "logOut" || hovered === "logOut"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
