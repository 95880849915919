import React from 'react';
import './Headline.scss';

const Headline = ({text, type}) => {


  return (
      <div className={type}>
        {text}
      </div>
  );
};

export default Headline;
