import React, { useEffect, useState } from "react";
import "./Update.scss";
import { useTranslation } from "react-i18next";
import {
  getPopupStatus,
  getPopupCloseStatus,
} from "../../../core/axios/services";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Update = () => {
  const { t, i18n } = useTranslation();
  const [check, setCheck] = useState("/assets/tick.svg");
  const [close, setClose] = useState("/assets/closeIcon.svg");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [popupContent, setPopupContent] = useState("");

  const updates = [
    {
      id: 1,
      title: "More powerful-featured 1",
      desc: "Some text that further explains this item that has been changed.",
    },
    {
      id: 2,
      title: "More powerful-featured 2",
      desc: "Some text that further explains this item that has been changed.",
    },
    {
      id: 3,
      title: "More powerful-featured 3",
      desc: "Some text that further explains this item that has been changed.",
    },
  ];

  const getPopup = async () => {
    try {
      return await getPopupStatus(user).then((res) => {
        setPopupContent(res.data.popup);

        if (res.data.popup !== null) {
          setIsOpen(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async (id) => {
    console.log(id);
    console.log(user);
    try {
      return await getPopupCloseStatus(id, user).then((res) => {
        setIsOpen(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPopup();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <>
      {isOpen && (
        <div className="updateWrapper">
          <div className="updateContent">
            <img
              className="closeUpdateContent"
              src={close}
              alt={close}
              onClick={() => handleClose(popupContent.id)}
            />
            <h1>{popupContent?.title}</h1>
            <h5>{formatDate(popupContent?.start)}</h5>
            {/* <h2>{i18n.t("newVersionUpdate")}</h2> */}
            <div className="updateListContent">
              <div className="updateList">
                {/* <div className="content">
                  <p>{popupContent.text}</p>
                </div> */}
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: popupContent?.text }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Update;
