import React from "react";
import "./ColorScheme.scss";
import { Box } from "@mui/material";

const ColorScheme = ({ app, selected, setSelected }) => {
  return (
    <Box className="ColorScheme">
      {app.app_data?.themes.map((theme, index) => {
        let className = "themePicker";
        let iconClass = "invisibleIcon";
        if (selected === theme.id) {
          className = "themePicker, selectedPicker";
          iconClass = "visibleIcon";
        }
        return (
          <div className={"themeContainer"} key={index}>
            <div
              className={className}
              style={{ backgroundColor: theme.color }}
              onClick={() => setSelected(theme.id)}
            >
              <img
                className={iconClass}
                src={"/assets/Icon_Checkmark2.svg"}
                alt="iconClass"
                width="17px"
                height="12px"
              />
            </div>
          </div>
        );
      })}
    </Box>
  );
};

export default ColorScheme;
