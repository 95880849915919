import React, { useEffect, useState } from "react";
import "./ConfirmPopUp.scss";
import getThemes from "../../../../constants/colors/getTheme";
import Button from "../../Comon/Button/Button";
import { Box } from "@mui/material";
import {
  confirmPopup,
  denyPopup,
  showPopup,
} from "../../../../redux/actions/app";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../Comon/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import Dropdown from "../Dropdown/Dropdown";
import { getMyUser } from "../../../../core/axios/services";

import "../../../pages/Admin Panel/SurveySettings/SurveySettings.scss";

const ConfirmPopUp = ({ app }) => {
  const [backdropStyle, setBackdropStyle] = useState({});
  const [generalStyle, setGeneralStyle] = useState({});
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const initialSettings = {
    introHeadline: "",
    introText: "",
    introVideo: "",
    patientHeadline: "",
    patientText: "",
    patientVideo: "",
    stressesHeadline: "",
    stressesText: "",
    stressesVideo: "",
    topThreeHeadline: "",
    topThreeText: "",
    topThreeVideo: "",
    globalHeadline: "",
    globalText: "",
    globalVideo: "",
    thankHeadline: "",
    thankText: "",
    thankVideo: "",
    nextIntroHeadline: "",
    nextIntroText: "",
    nextIntroVideo: "",
    nextTopThreeHeadline: "",
    nextTopThreeText: "",
    nextTopThreeVideo: "",
    nextGlobalHeadline: "",
    nextGlobalText: "",
    nextGlobalVideo: "",
    nextThankHeadline: "",
    nextThankText: "",
    nextThankVideo: "",
    language: app.app_data?.languages[0],
    // type: user.user.clinic?.businessPlan.id,
    // clinicId: user.user.clinic?.id,
    openEndedQuestions: null,
    surveyLink: null,
    isEmpty: true,
    openEnded1Title: null,
    openEnded2Title: null,
    openEnded3Title: null,
    openEnded4Title: null,
    openEnded1Description: null,
    openEnded2Description: null,
    openEnded3Description: null,
    openEnded4Description: null,
  };
  let [settings, setSettings] = useState(initialSettings);

  const [languageId, setLanguageId] = useState("");

  useEffect(() => {
    if (app.showPopup) {
      if (app.popUpData?.type === "surveyNotes") {
        setNotes("");
        if (app.popUpData?.data) {
          setNotes(app.popUpData.data);
        }
      }

      const scrollHeight = window.pageYOffset;
      document
        .querySelectorAll(".mainBox")
        .forEach((el) => el.classList.add("blurredMainBox"));
      document.body.classList.add("noScroll");

      document
        .querySelectorAll(".survey-box")
        .forEach((el) => el.classList.add("blurredMainBox"));
      document.body.classList.add("noScroll");

      setBackdropStyle({
        backgroundColor: getThemes("mainColor", app.appTheme),
      });

      setGeneralStyle({
        display: "block",
        top: scrollHeight,
      });
    } else {
      document
        .querySelectorAll(".mainBox")
        .forEach((el) => el.classList.remove("blurredMainBox"));
      document
        .querySelectorAll(".survey-box")
        .forEach((el) => el.classList.remove("blurredMainBox"));

      document.body.classList.remove("noScroll");
      setBackdropStyle({
        backgroundColor: getThemes("mainColor", app.appTheme),
        display: "none",
      });

      setGeneralStyle({
        display: "none",
      });
    }
  }, [app]);

  const confirmButton = () => {
    if (app.popUpData.type === "surveyNotes") {
      dispatch(confirmPopup(notes));
    } else {
      dispatch(confirmPopup());
    }
  };

  const denyButton = () => {
    dispatch(denyPopup());
  };

  const [settingFetch, setSettingFetch] = useState("");
  const [settingFetchId, setSettingFetchId] = useState("");
  const [showType, setShowType] = useState("");

  const getMeData = async () => {
    return getMyUser(user)
      .then((res) => {
        setSettingFetch(res.data.data.clinic.surveySettings);
        setSettingFetchId(res.data.data.clinic.businessPlan.id);
        setShowType(res.data.data.type);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getMeData();
  }, [user]);

  const updateLanguage = (language) => {
    const correctSettings = settingFetch.find((s) => {
      return s.language.id === language.id && s.type === settingFetchId;
    });

    if (correctSettings) {
      setSettings(correctSettings);
      localStorage.setItem("language", correctSettings.language.id);
    } else {
      settings = initialSettings;
      settings.language = language;
      settings.id = null;
      setSettings({ ...settings });
    }
  };

  return (
    <div className={"ConfirmPopUp"} style={generalStyle}>
      <div className="ConfirmPopUpBackdrop" style={backdropStyle} />
      <div className={"ConfirmPopUpBox"}>
        <div className={"ConfirmPopUpContent"}>
          {app.popUpData && app.popUpData.subtitle ? (
            <div className={"ConfirmPopUpSubtitle"}>
              {" "}
              {app.popUpData.subtitle}{" "}
            </div>
          ) : null}

          {app.popUpData && app.popUpData.title ? (
            <div
              dangerouslySetInnerHTML={{ __html: app.popUpData.title }}
              className={"ConfirmPopUpTitle"}
            />
          ) : null}

          {app.popUpData && app.popUpData.description ? (
            <div
              dangerouslySetInnerHTML={{ __html: app.popUpData.description }}
              className={"ConfirmPopUpDescription"}
            />
          ) : null}

          {app.popUpData && app.popUpData.topSecondTitle ? (
            <div
              dangerouslySetInnerHTML={{
                __html: app.popUpData.topSecondTitle,
              }}
              className={"ConfirmPopUptopSecondTitle"}
            />
          ) : null}

          {app.popUpData?.type === "surveyNotes" ? (
            <Box className={"textContainer"}>
              <TextInput
                rows={7}
                mode={"white"}
                placeholder={i18n.t("surveyNotesTitle")}
                type={"textarea"}
                setValue={(val) => setNotes(val)}
                value={notes}
              />
            </Box>
          ) : null}

          {app.popUpData?.type === "welcomePopup" ? (
            <div className={"DropdownStyle"}>
              <Dropdown
                type={"language"}
                setSelectedOption={(opt) => updateLanguage(opt)}
                disabled={showType === 3}
                selectedOption={settings?.language}
                options={app.app_data?.languages}
                app={app}
                placeholder={i18n.t("surveyLanguage")}
              />
            </div>
          ) : null}

          {app.popUpData && app.popUpData.bottomDescription ? (
            <div
              dangerouslySetInnerHTML={{
                __html: app.popUpData.bottomDescription,
              }}
              className={"ConfirmPopUpBottomDescription"}
            />
          ) : null}

          {app.popUpData && app.popUpData.basicDescription ? (
            <div
              dangerouslySetInnerHTML={{
                __html: app.popUpData.basicDescription,
              }}
              className={"ConfirmPopUpBasicDescription"}
            />
          ) : null}

          <div
            className={"ConfirmPopUpButtonContainer"}
            onClick={() => confirmButton()}
          >
            <Button text={app.popUpData?.confirmButton} mode={"small"} />
          </div>
          <div className={"denyPopupButton"} onClick={() => denyButton()}>
            {app.popUpData?.denyButton}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopUp;
