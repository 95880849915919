import * as types from "../types/actionTypes";

const initialState = {
  isLoading: true,
  isLoadingAPI: false,
  language: null,
  notification: null,
  camera: false,
  appTheme: "defaultTheme",
  app_data: null,
  locale: "se",
  loggedIn: false,
  token: "",
  rememberMe: false,
  showPopup: false,
  popUpData: {
    title: "",
    confirmButton: "",
    denyButton: "",
    type: "",
  },
  popupStatus: null,
  showSnackbar: false,
  snackbarMessage: "",
  popupData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.ADD_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        // loggedIn: true,
        rememberMe: action.payload.rememberMe,
      };
    case types.LOADING_API:
      return {
        ...state,
        isLoadingAPI: action.payload,
      };
    case types.LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case types.NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case types.SHOW_CAMERA:
      return {
        ...state,
        camera: action.payload,
      };
    case types.CHANGE_THEME:
      return {
        ...state,
        appTheme: action.payload,
      };

    case types.GET_APP_DATA:
      return {
        ...state,
        app_data: action.payload,
      };

    case types.SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload.showPopup,
        popUpData: action.payload.data,
      };

    case types.CONFIRM_POPUP:
      return {
        ...state,
        showPopup: false,
        popupStatus: true,
        popUpConfirmData: action.payload,
      };

    case types.DENY_POPUP:
      return {
        ...state,
        showPopup: false,
        popupStatus: false,
      };

    case types.RESET_POPUP:
      return {
        ...state,
        showPopup: false,
        popupStatus: null,
        popUpData: null,
        popUpConfirmData: null,
      };

    case types.SHOW_SNACKBAR:
      return {
        ...state,
        showSnackbar: action.payload.showSnackbar,
        snackbarMessage: action.payload.snackbarMessage,
      };
    default:
      return state;
  }
};

export default reducer;
