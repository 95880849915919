import React, { useEffect, useState } from "react";
import "./XYDiagram.scss";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

const XYDiagram = ({ values, property, id, surveyOptions }) => {
  const { i18n } = useTranslation();
  const [statistics, setStatistics] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    values = values.filter((v) => getValue(v) > 0);
    setStatistics(values);
    console.log("Statistics from comp:  ", values);
  }, [values]);

  const calculateBottom = (index) => {
    if (isOdd(index)) {
      return "calc(175px - (" + (index + 1) * 15 + "px + 32px) )";
    } else {
      return "calc(175px - 32px )";
    }
  };

  const isOdd = (num) => {
    return num % 2;
  };

  const calculateHeight = (index) => {
    return "calc(" + (index + 1) * 15 + "px + 32px)";
  };

  const calculateWidth = (index, value) => {
    return "calc(" + getValue(value) + "% - 2px)";
  };

  const getValue = (value) => {
    let correctValue = null;
    if (id === 1) {
      correctValue = value.neck;
    } else if (id === 2) {
      correctValue = value.midBack;
    } else if (id === 3) {
      correctValue = value.energyLevels;
    } else if (id === 4) {
      correctValue = value.abilityStress;
    } else if (id === 5) {
      correctValue = value.sleepQuality;
    } else if (id === 6) {
      correctValue = value.immuneSystem;
    } else if (id === 7) {
      correctValue = value.mentalState;
    } else if (id === 8) {
      correctValue = value.lowerBack;
    } else if (id === 9) {
      correctValue = value.posture;
    } else if (id === 10) {
      correctValue = value.emotionalState;
    }

    return correctValue;
  };

  const getIndexValue = (index) => {
    const filteredStatistics = statistics.filter((s) => s.visible !== false);

    let correctValue = null;
    if (filteredStatistics[index]) {
      if (id === 1) {
        correctValue = filteredStatistics[index].neck;
      } else if (id === 2) {
        correctValue = filteredStatistics[index].midBack;
      } else if (id === 3) {
        correctValue = filteredStatistics[index].energyLevels;
      } else if (id === 4) {
        correctValue = filteredStatistics[index].abilityStress;
      } else if (id === 5) {
        correctValue = filteredStatistics[index].sleepQuality;
      } else if (id === 6) {
        correctValue = filteredStatistics[index].immuneSystem;
      } else if (id === 7) {
        correctValue = filteredStatistics[index].mentalState;
      } else if (id === 8) {
        correctValue = filteredStatistics[index].lowerBack;
      } else if (id === 9) {
        correctValue = filteredStatistics[index].posture;
      } else if (id === 10) {
        correctValue = filteredStatistics[index].emotionalState;
      }
    }

    return parseFloat(correctValue);
  };

  const getImprovement = (index) => {
    const filteredStatistics = statistics.filter((s) => s.visible !== false);

    if (
      filteredStatistics &&
      filteredStatistics[index] &&
      filteredStatistics[index - 1]
    ) {
      let firstValue = getIndexValue(0);
      let secondValue = getIndexValue(index);

      let calculation = 0;
      //is decrease
      if (secondValue < firstValue) {
        calculation = ((secondValue - firstValue) / firstValue) * 100;
      } else {
        calculation = ((secondValue - firstValue) / (100 - firstValue)) * 100;
      }

      if (isNaN(calculation)) {
        return 0;
      }
      return Math.round(calculation);
    } else {
      return 0;
    }
  };

  const getOverallImprovement = () => {
    if (!statistics) {
      return 0;
    }

    let total = 0;

    if (statistics) {
      statistics.forEach((v, index) => {
        if (v.visible !== false) {
          let firstValue = getIndexValue(0);
          let secondValue = getIndexValue(index);

          //is decrease
          if (secondValue < firstValue) {
            total = ((secondValue - firstValue) / firstValue) * 100;
          } else {
            total = ((secondValue - firstValue) / (100 - firstValue)) * 100;
          }
        }
      });
    }
    if (isNaN(total)) {
      total = 0;
    }
    return Math.round(total);
  };

  const getTitle = () => {
    return property;
  };

  const onCheckboxClick = (index) => {
    surveyOptions[index].selected = !surveyOptions[index].selected;
    updateStatisticsAccordingToOptions();
  };

  const updateStatisticsAccordingToOptions = () => {
    surveyOptions.forEach((o) => {
      const statisticIndex = statistics.findIndex((s) => {
        return s.surveyOrder === o.id;
      });
      if (o.selected === false) {
        if (statisticIndex !== -1) {
          statistics[statisticIndex].visible = false;
        }
      } else {
        if (statisticIndex !== -1) {
          statistics[statisticIndex].visible = true;
        }
      }
    });
    setStatistics([...statistics]);
  };

  const calculateDotLeft = (statistic) => {
    if (statistic.surveyOrder === 1) {
      return "58px";
    } else {
      return (statistic.surveyOrder - 1) * 129 + 58 + "px";
    }
  };

  const calculateInfoLeft = (statistic) => {
    //61 is half of the info container
    if (statistic.surveyOrder === 1) {
      return 58 - 51 + "px";
    } else {
      return (statistic.surveyOrder - 1) * 129 + 58 - 51 + "px";
    }
  };

  const calculateDotBottom = (statistic) => {
    return (520 / 100) * Math.round(getValue(statistic)) - 29 + "px";
  };

  const calculateInfoBottom = (statistic) => {
    const dotBottom = (520 / 100) * Math.round(getValue(statistic)) - 29;

    if (dotBottom < 387) {
      // bottom part of the graph
      return dotBottom + 32; // 67 is the height of info
    } else {
      // top part of the graph
      return dotBottom - 67 - 10; // 67 is the height of info
    }
  };

  const calculateYStep = (firstBottom, secondBottom, difference) => {
    if (firstBottom < secondBottom) {
      return (secondBottom - firstBottom) / (129 * difference);
    } else {
      return (firstBottom - secondBottom) / (129 * difference);
    }
  };

  const calculateLineBottom = (statisticIndex, index2) => {
    const filteredStatistics = statistics.filter((s) => s.visible !== false);

    if (statisticIndex < filteredStatistics.length - 1) {
      try {
        let firstBottom =
          (520 / 100) *
            Math.round(getValue(filteredStatistics[statisticIndex])) -
          19;
        let secondBottom =
          (520 / 100) *
            Math.round(getValue(filteredStatistics[statisticIndex + 1])) -
          19;

        const difference =
          filteredStatistics[statisticIndex + 1].surveyOrder -
          filteredStatistics[statisticIndex].surveyOrder;
        const yStep = calculateYStep(firstBottom, secondBottom, difference);

        if (firstBottom <= secondBottom) {
          const bottom = firstBottom + yStep * index2;
          if (bottom <= secondBottom) {
            return bottom + "px";
          }
        } else {
          const bottom = firstBottom - yStep * index2;
          if (bottom <= firstBottom) {
            return bottom + "px";
          }
        }
      } catch (e) {
        //
      }
    }
  };

  const calculateLineLeft = (statisticIndex, index2) => {
    const filteredStatistics = statistics.filter((s) => s.visible !== false);

    if (statisticIndex < filteredStatistics.length - 1) {
      try {
        let firstLeft = 68;
        if (statisticIndex > 0) {
          firstLeft =
            (filteredStatistics[statisticIndex].surveyOrder - 1) * 129 + 68;
        }

        if (statisticIndex == 0) {
          firstLeft =
            (filteredStatistics[statisticIndex].surveyOrder - 1) * 129 + 68;
        }
        const secondLeft =
          (filteredStatistics[statisticIndex + 1].surveyOrder - 1) * 129 + 68;

        if (firstLeft <= secondLeft) {
          const left = firstLeft + index2;
          if (left <= secondLeft) {
            return left + "px";
          }
        } else {
          const left = secondLeft + index2;
          if (left <= firstLeft) {
            return left + "px";
          }
        }
      } catch (e) {}
    }
  };

  return (
    <div className={"globalBarChart"}>
      <div className={"BarChartTitle"}>{getTitle()}</div>
      <div className={"autoSelectContainer"}>
        <div className={"autoSelectTitle"}>{i18n.t("chooseStatistics")}</div>
        <div className={"autoSelectBox"}>
          {surveyOptions.map((s, index) => {
            return (
              <div className={"checkboxContainerBox"} key={index}>
                <CustomCheckbox
                  value={s.selected}
                  setValue={() => onCheckboxClick(index)}
                />
                <div
                  className={"checkboxCustomLabel"}
                  onClick={() => onCheckboxClick(index)}
                >
                  {" "}
                  {i18n.t("survey")} {s.id}{" "}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="XYDiagram">
        <div className={"XAxis"} style={{ marginTop: "5px" }}>
          {[...Array(10)].map((r, index) => {
            return (
              <div className={"XAxisItem"} key={index}>
                {" "}
                {100 - index * 10}{" "}
              </div>
            );
          })}
        </div>
        <div className={"XYDiagramInner"}>
          <div className={"mainXYDiagram"}>
            <div
              className={"XYDiagramBackground"}
              style={{ marginTop: "30px" }}
            >
              {[...Array(60)].map((r, index) => {
                return (
                  <div className={"XYDiagramBackgroundItem"} key={index} />
                );
              })}
            </div>
            {statistics
              ?.filter((s) => s.visible !== false)
              .map((s, index) => {
                return (
                  <div key={index}>
                    <div
                      className={"resultDot"}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      style={{
                        left: calculateDotLeft(s),
                        bottom: calculateDotBottom(s),
                      }}
                    />
                    {hoveredIndex === index ? (
                      <div
                        className={"resultInfo"}
                        style={{
                          left: calculateInfoLeft(s),
                          bottom: calculateInfoBottom(s),
                        }}
                      >
                        <div className={"percentageOnly"}>
                          {" "}
                          {Math.round(getValue(s))}{" "}
                        </div>
                        <div className={"valueTitle"}>
                          {" "}
                          {i18n.t("survey")} {s.surveyOrder}
                        </div>
                        {index > 0 ? (
                          <div className={"valueSubtitle"}>
                            {" "}
                            {getImprovement(index)}% {i18n.t("improvement")}{" "}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {[...Array(1000)].map((r, index2) => {
                      if (
                        calculateLineBottom(index, index2) &&
                        calculateLineLeft(index, index2)
                      ) {
                        return (
                          <div
                            className={"resultLineDot"}
                            key={index2}
                            style={{
                              bottom: calculateLineBottom(index, index2),
                              left: calculateLineLeft(index, index2),
                            }}
                          />
                        );
                      }
                    })}
                  </div>
                );
              })}
          </div>
          <div className={"YAxis"}>
            {surveyOptions.map((s, index) => {
              return (
                <div className={"YAxisItem"} key={index}>
                  {" "}
                  {i18n.t("survey")} {s.id}{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className={"overallImprovementXY"}>
          <div className={"overallImprovementNumber"}>
            {getOverallImprovement()}%
          </div>
          <div className={"overallImprovementText"}>
            {i18n.t("overallImprovement")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default XYDiagram;
