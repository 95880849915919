import React, { useState } from "react";
import "./NewLanding.scss";
import { sendInquiry } from "../../../core/axios/services";
import { useDispatch } from "react-redux";
import TextInput from "../../components/Comon/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ContactUs = ({ showGlobalSnackbar, landingData }) => {
  const { i18n } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const app = useSelector((state) => state.app);

  const send = () => {
    i18n.changeLanguage("en");

    if (!name) {
      showGlobalSnackbar(i18n.t("nameRequired"));
      return false;
    }

    if (!email) {
      showGlobalSnackbar(i18n.t("emailRequired"));
      return false;
    }

    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      showGlobalSnackbar(i18n.t("emailFormat"));
      return false;
    }

    if (!message) {
      showGlobalSnackbar(i18n.t("messageRequired"));
      return false;
    }

    sendInquiry({
      name,
      email,
      message,
    });
    showGlobalSnackbar(i18n.t("inquirySent"));
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="contactWrap contactContainerWrap">
      <div className="contactWidth">
        <div className="leftContent">
          <p className="landing-thin-font landing-section-eight-left-title">
            {app?.app_data?.landingData?.contact_title}
          </p>

          <p className="landing-section-eight-left-body landing-basic-font">
            {app?.app_data?.landingData?.contact_text}{" "}
            <a
              href="mailto:support@chiropracticoutcomes.com"
              style={{ color: "#5e93ba" }}
            >
              support@chiropracticoutcomes.com
            </a>
          </p>
        </div>
        {/* <div className="rightContent">
          <div className="landing-section-eight-form">
            <div className="landing-section-eight-form-field">
              <TextInput
                type="text"
                label={"Name and surname"}
                value={name}
                setValue={setName}
                isLanding={true}
                placeholder="Type here…"
                className="form-control landing-section-eight-field landing-field-one"
                id="name"
                aria-describedby="name"
              />
            </div>

            <div className="landing-section-eight-form-field">
              <TextInput
                type="text"
                label={"Email address"}
                value={email}
                setValue={setEmail}
                isLanding={true}
                placeholder="Type here…"
                className="form-control landing-section-eight-field landing-field-two"
                id="email"
                aria-describedby="emailHelp"
              />
            </div>

            <div className="landing-section-eight-form-field text-area-style">
              <TextInput
                name=""
                cols="30"
                rows="10"
                type={"textarea"}
                label={"Type your message here"}
                placeholder="Ask more about Chiropractic Outcomes here!"
                value={message}
                isLanding={true}
                setValue={setMessage}
                id="msg"
                className="form-control landing-section-eight-field landing-field-three"
              ></TextInput>
            </div>

            <button
              className="btn bg-primary landing-bolder-font landing-section-eight-button landing-section-eight-button-margin"
              onClick={() => send()}
            >
              {app?.app_data?.landingData?.contact_cta}
            </button>
            <button
              id="inquiry"
              className="btn bg-primary landing-bolder-font landing-section-eight-button landing-section-eight-button-margin d-none"
              onClick={() => send()}
            >
              SEND INQUIRY
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContactUs;
