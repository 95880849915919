import React, {useEffect, useState} from 'react';
import './EditNextThankYouSurvey.scss';
import {useNavigate, useParams} from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {deletePreviousSurveyData, deleteSurveyData, updateSurveyPage} from "../../../../../redux/actions/survey";
import {useDispatch} from "react-redux";


const EditNextThankYouSurvey = ({showGlobalSnackbar, survey, i18n, app}) => {

    const navigate = useNavigate();
    const {code} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateSurveyPage(5));
        dispatch(deleteSurveyData());
        dispatch(deletePreviousSurveyData());
    }, []);


    const nextPage = () => {
      //
    }


    return (
        <div className="FirstScreenSurvey">

            <SurveyHero
                step={i18n.t("bye")}
                headline={survey?.settings?.nextThankHeadline}
                text={survey?.settings?.nextThankText}
                videoLink={survey?.settings?.nextThankVideo}
                buttonText={""}
                clickedButton={() => nextPage()}
                app={app}
                surveyType={survey?.settings?.type}
            />


        </div>
    );
};

export default EditNextThankYouSurvey;
