import React, { useEffect, useState } from "react";
import "./Patients.scss";
import { useSelector } from "react-redux";
import {
  bulkAssignPractitioner,
  getAllPractitioners,
  getMyUser,
  getPatients,
  assignPractitioner,
  updatePatientsSort,
} from "../../../../core/axios/services";
import Headline from "../../../components/Comon/Headline/Headline";
import Table from "../../../components/Admin Panel/Table/Table";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addUser } from "../../../../redux/actions/user";
import { json, useNavigate } from "react-router-dom";
import Dropdown from "../../../components/Admin Panel/Dropdown/Dropdown";
import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Patients = ({ showGlobalSnackbar }) => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [patients, setPatients] = useState([]);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [perPage, setPerPage] = useState(0);
  const { i18n } = useTranslation();
  const [loadedUser, setLoadedUser] = useState(false);
  const [practitioners, setPractitioners] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedPatientsMobile, setSelectedPatientsMobile] = useState([]);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const navigate = useNavigate();

  useEffect(() => {
    if (loadedUser) {
      // console.log("zovemo patients");
      // console.log("sort: ", sort, " dir:  ", sortDirection);
      getData();
    }
  }, [loadedUser, sort, sortDirection, page]);

  const setSelectedData = (data) => {
    if (data) {
      setSelectedPatients([...data]);
    }
  };

  const setSelectedDataMobile = (data) => {
    if (data) {
      setSelectedPatientsMobile([...data]);
    }
  };

  useEffect(() => {
    getMyUser(user).then((res) => {
      const myUser = res.data.data;
      addUser(myUser);
      setSort(myUser.patientsSort);
      setSortDirection(myUser.patientsDir);
      setLoadedUser(true);
    });

    getAllPractitioners(user).then((res) => {
      let allPractitioners = res.data;
      allPractitioners.unshift({ id: 0, name: i18n.t("noPractitioner") });
      setPractitioners(allPractitioners);
    });
  }, []);

  // useEffect(() => {
  //   console.log("OVO JE SORT PATIENTS", sort);
  // }, [sort]);

  const getData = async () => {
    try {
      return await getPatients(
        user?.user?.clinic?.id,
        page,
        showAll,
        sortDirection,
        sort,
        user
      ).then((res) => {
        setPatients(res.data.data.data);
        setTotal(res.data.data.total);
        setPerPage(parseInt(res.data.data.perPage));
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const bulkAssign = (practitioner) => {
    const data = {
      patientIds: selectedPatients,
      practitionerId: practitioner.id,
    };
    try {
      bulkAssignPractitioner(user, data).then(() => {
        showGlobalSnackbar(i18n.t("saved"));

        patients.map((p) => {
          if (selectedPatients.includes(p.id)) {
            p.practitioner = practitioner;
          }
        });

        setPatients([...patients]);
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  //   All for mobile

  const bulkAssignMobile = (practitioner) => {
    const data = {
      patientIds: selectedPatientsMobile,
      practitionerId: practitioner.id,
    };
    try {
      bulkAssignPractitioner(user, data).then(() => {
        showGlobalSnackbar(i18n.t("saved"));

        patients.map((p) => {
          if (selectedPatientsMobile.includes(p.id)) {
            p.practitioner = practitioner;
          }
        });

        setPatients([...patients]);
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const toggleSelected = (id) => {
    const index = selectedPatientsMobile.findIndex((s) => s == id);

    if (index == -1) {
      selectedPatientsMobile.push(id);
    } else {
      selectedPatientsMobile.splice(index, 1);
    }

    setSelectedDataMobile(selectedPatientsMobile);
  };

  const selectPractitioner = (patient, practitioner) => {
    patient.practitioner = practitioner;
    const index = patients.findIndex((p) => p.id == practitioner.id);
    if (index != -1) {
      patients[index] = patient;
    }
    setPatients(patients);
    const postData = {
      patientId: patient.id,
      practitionerId: practitioner.id,
    };

    try {
      assignPractitioner(user, postData).then(() => {
        showGlobalSnackbar(i18n.t("saved"));
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const clickMore = (item) => {
    navigate("/dashboard/patient/" + item.id);
  };

  const toggleSort = async (sortType) => {
    setSort(sortType);
    user.user.patientSort = sortType;
    if (sortDirection === "asc") {
      setSortDirection("desc");
      updatePatientsSort(sortType, "desc", user);
      user.user.patientSortDir = "desc";
    } else {
      setSortDirection("asc");
      updatePatientsSort(sortType, "asc", user);
      user.user.patientSortDir = "asc";
    }
    dispatch(addUser(user.user));
  };

  const [pages, setPages] = useState([0]);
  const [currentPage, setCurrentPage] = useState("");

  const goToPage = (selectedPage) => {
    if (typeof selectedPage === "number") {
      if (selectedPage < pages[0] || selectedPage > pages[pages.length - 1]) {
        return;
      }
      setPage(selectedPage);

      if (selectedPage >= pages[pages.length - 2]) {
        // setCurrentPage(1);
      } else if (selectedPage == 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage(selectedPage);
      }
    }
  };

  useEffect(() => {
    const lastPage = Math.ceil(total / perPage);

    if (lastPage > 0) {
      const pages = Array.from(Array(lastPage).keys());
      if (pages.length <= 5) {
        setPages(pages);
      } else {
        // console.log(currentPage,'currentPage');
        let pagesFirstPart = [
          pages[currentPage - 1],
          pages[currentPage],
          pages[currentPage + 1],
          "...",
        ];
        let pagesLastPart = [pages[pages.length - 2], pages[pages.length - 1]];

        if (currentPage == 0) {
          setCurrentPage(1);
        } else if (currentPage >= pages[pages.length - 3]) {
          setCurrentPage(currentPage);
          pagesFirstPart = [
            pages[currentPage - 2],
            pages[currentPage - 1],
            pages[currentPage],
            "...",
          ];
        }

        const pagesItems = [...pagesFirstPart, ...pagesLastPart];

        setPages(pagesItems);
      }
    }
  }, [patients]);

  return (
    <div className="Patients">
      <Headline text={i18n.t("patients")} type={"Headline-medium"} />
      <div
        className={"dataTableContainer"}
        style={{ marginTop: `${isMobile && "20px"}` }}
      >
        {isMobile ? (
          <div
            className={"patientsAction"}
            style={{
              display: `${
                selectedPatientsMobile.length > 0 ? "block" : "none"
              }`,
              padding: "0",
            }}
          >
            <Dropdown
              options={practitioners}
              setSelectedOption={(selected) => bulkAssignMobile(selected)}
              label={""}
              type={"practitionerBulk"}
              selectedOption={null}
              placeholder={i18n.t("selectPractitioner")}
            />
          </div>
        ) : (
          <div className={"patientsAction"}>
            {selectedPatients.length > 0 ? (
              <Dropdown
                options={practitioners}
                setSelectedOption={(selected) => bulkAssign(selected)}
                label={""}
                type={"practitionerBulk"}
                selectedOption={null}
                placeholder={i18n.t("selectPractitioner")}
              />
            ) : null}
          </div>
        )}

        <div className="patientTable">
          {isMobile ? (
            <div className="mobileTable">
              <div className="topSortMobile">
                <div className="sort" onClick={() => toggleSort("name")}>
                  {sort === "name" ? (
                    <img
                      className={"sortIcon"}
                      alt="sortIcon"
                      src={"/assets/Icon_Sorting-Arrows.svg"}
                      width="14px"
                      height="16px"
                    />
                  ) : (
                    <img
                      className={"sortIcon"}
                      alt="sortIcon"
                      src={"/assets/Icon_Sorting-ArrowsDisable.svg"}
                      width="14px"
                      height="16px"
                    />
                  )}
                  <p> Patient name</p>
                </div>
                <div className="sort" onClick={() => toggleSort("date")}>
                  {sort === "date" ? (
                    <img
                      className={"sortIcon"}
                      alt="sortIcon"
                      src={"/assets/Icon_Sorting-Arrows.svg"}
                      width="14px"
                      height="16px"
                    />
                  ) : (
                    <img
                      className={"sortIcon"}
                      alt="sortIcon"
                      src={"/assets/Icon_Sorting-ArrowsDisable.svg"}
                      width="14px"
                      height="16px"
                    />
                  )}
                  <p>Last Survey Date</p>
                </div>
              </div>

              {patients.map((item, index) => {
                return (
                  <div className="mobileWrapper" key={index}>
                    <div className="patientName">
                      <h3>
                        {item.name} {item.lastName}
                      </h3>
                      <input
                        type="checkbox"
                        onChange={() => toggleSelected(item.id)}
                      />
                    </div>

                    <div className="choosePractitioner">
                      <h4>Practitioner</h4>
                      <div>
                        <Dropdown
                          options={practitioners}
                          setSelectedOption={(selected) =>
                            selectPractitioner(item, selected)
                          }
                          selectedOption={item.practitioner}
                          label={i18n.t("selectPractitioner")}
                          type={"practitioner"}
                          placeholder={i18n.t("noPractitioner")}
                        />
                      </div>
                    </div>
                    <div className="mobileDate">
                      {" "}
                      <h4>Last Survey Date</h4>
                      <p> {item.lastSurveyDate}</p>
                    </div>
                    <div className="seeMoreMob" onClick={() => clickMore(item)}>
                      {" "}
                      {i18n.t("seePatientProfile")}
                      <img
                        className={"rightArrowIcon"}
                        alt="rightArrowIcon"
                        src={"/assets/Icon_Arrow-Right.svg"}
                        width="9px"
                        height="100%"
                      />
                    </div>
                  </div>
                );
              })}

              <div className={"tableFooter"} style={{ marginTop: "20px" }}>
                <div className={"tableFooterLeft"}>
                  <div className={"resultsInfo"}>
                    {i18n.t("showingOutOf", {
                      count: patients?.length,
                      total,
                    })}
                  </div>
                  {!showAll ? (
                    <div
                      className={"viewAllResults"}
                      onClick={() => setShowAll(true)}
                    >
                      {i18n.t("viewAllResults")}
                    </div>
                  ) : null}
                </div>
                <div className={"tableFooterRight"}>
                  <img
                    className={"iconLeftPaginate"}
                    src={"/assets/Arrow_Left.svg"}
                    alt="iconLeftPaginate"
                    onClick={() => goToPage(page - 1)}
                    width="7px"
                    height="100%"
                  />
                  {pages.map((pageOption, index) => {
                    let pageClass = "page";
                    if (pageOption === page) {
                      pageClass += " activePage";
                    }
                    let pageNumber = pageOption;
                    if (typeof pageOption === "number") {
                      pageNumber += 1;
                    }
                    return (
                      <div
                        className={pageClass}
                        onClick={() => goToPage(pageOption)}
                        key={index}
                      >
                        {" "}
                        {pageNumber}{" "}
                      </div>
                    );
                  })}
                  <img
                    className={"iconRightPaginate"}
                    onClick={() => goToPage(page + 1)}
                    src={"/assets/Arrow_Right.svg"}
                    alt="iconRightPaginate"
                    width="8px"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          ) : (
            <Table
              app={app}
              perPage={perPage}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              type={"patients"}
              i18n={i18n}
              total={total}
              sort={sort}
              setSort={setSort}
              setPage={setPage}
              page={page}
              data={patients}
              setPatients={setPatients}
              showAll={showAll}
              setShowAll={setShowAll}
              practitioners={practitioners}
              showGlobalSnackbar={showGlobalSnackbar}
              selectedData={selectedPatients}
              setSelectedData={(data) => setSelectedData(data)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Patients;
