import React, { useState, useEffect } from "react";
import "./ResetPassword.scss";
import { appData, authResetPassword } from "../../../../core/axios/services";

// Components
import TextInputLabel from "../../../components/Comon/TextInput/TextInput";
import Logo from "../../../components/Comon/Logo/Logo";
import { Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPassword = ({ showGlobalSnackbar }) => {
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [checkSuccess, setCheckSuccess] = useState(false);
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  useEffect(() => {
    collectAppData().then((data) => {
      // setLogo(data.logo);
    });

    setToken(window.location.pathname.split("/").pop());
  }, [token]);

  console.log(token);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const handleSubmit = async () => {
    if (password === "" || passwordRepeat === "") {
      const message = i18n.t("completeAllFields");
      showGlobalSnackbar(message);
      return;
    }
    if (passwordRepeat !== password) {
      const message = i18n.t("pleaseInsertConfirmMessage");
      showGlobalSnackbar(message);
      return;
    }

    try {
      return await authResetPassword(token, password).then((res) => {
        setCheckSuccess(true);
      });
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      showGlobalSnackbar(message);
    }
  };

  const handleCancel = () => {
    navigate("/login");
  };

  return (
    <div className="resetPasswordWrapper">
      <Logo className="logo" src={logo} />

      <div className="otpWrapper">
        <div className="otpContent">
          {checkSuccess ? (
            <div className="changeComplete">
              <h2>{t("passwordChangeComplete")}</h2>
              <p>{t("passwordSuccessChange")}</p>
              <button className="logInAccBtn" onClick={handleCancel}>
                {t("logInToAcc")}
              </button>
            </div>
          ) : (
            <div>
              <h2 className="resetPasswordTitle">{t("resetYourPassword")}</h2>
            </div>
          )}

          {checkSuccess ? (
            ""
          ) : (
            <div className="fieldsWrapper">
              <TextInputLabel
                type={"password"}
                label={i18n.t("password")}
                value={password}
                setValue={setPassword}
                placeholder={t("enterYourPassword")}
              />
              <div className="passwordRequire">
                <p>{t("passwordConsistOf")}</p>
                <ul>
                  <li>{t("passwordMinimum")}</li>
                  <li>{t("upperlLower")}</li>
                  <li>{t("passowrdNumbers")}</li>
                </ul>
              </div>
              <div className="passwordRequireMob">
                <p>{t("mobilePasswordMust")}</p>
              </div>
              <TextInputLabel
                type={"password"}
                label={i18n.t("confirmPassword")}
                value={passwordRepeat}
                setValue={setPasswordRepeat}
                placeholder={t("enterYourPasswordConfirm")}
                required
              />
              <div className="verifyButton">
                <button className="verifySubmit" onClick={handleSubmit}>
                  {t("confirmPasswordChangeBtn")}
                </button>
                <button className="cancelForm" onClick={handleCancel}>
                  {t("denySurveyDeleteButton")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        message={snackMessage}
      />
    </div>
  );
};

export default ResetPassword;
