import * as types from "../types/actionTypes";

export const addUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.LOGIN,
      payload: {
        user: data,
      },
    });
  };
};

export const addUserToken = (data, rememberMe) => {
  return (dispatch) => {
    dispatch({
      type: types.ADD_TOKEN,
      payload: {
        token: data.accessToken,
        rememberMe,
      },
    });
  };
};

export const setUserInfoAction = (data) => {
  console.log("user   ", data);
  return (dispatch) => {
    dispatch({ type: types.SET_USER_INFO, payload: data });
  };
};

export const runSecretLoginAction = () => {
  return (dispatch) => {
    dispatch({ type: types.SECRET_LOGIN, payload: null });
  };
};

export const setUserTokenAction = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_USER_TOKEN, payload: data });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch({ type: types.LOGOUT });
  };
};

export const updateClinicSurveySettings = (data) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CLINIC_SURVEY_SETTINGS, payload: data });
  };
};

export const refreshUserToken = (data) => {
  return (dispatch) => {
    dispatch({ type: types.RESTORE_TOKEN, payload: data.jwt.access_token });
  };
};
