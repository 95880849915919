import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../redux/actions/app";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../Admin Panel/Main/Main";
import Patients from "../Admin Panel/Patients/Patients";
import Patient from "../Admin Panel/Patient/Patient";
import Users from "../Admin Panel/Users/Users";
import Statistics from "../Admin Panel/Statistics/Statistics";
import MyAccount from "../Admin Panel/MyAccount/MyAccount";
import SurveySettings from "../Admin Panel/SurveySettings/SurveySettings";
import Login from "../Admin Panel/Login/Login";
import { Snackbar } from "@mui/material";
import User from "../Admin Panel/User/User";
import SurveyMain from "../Survey/Global/SurveyMain/SurveyMain";
import ErrorPage from "../Survey/Global/ErrorPage/ErrorPage";
import IntroSurvey from "../Survey/FirstSurvey/IntroSurvey/IntroSurvey";
import PatientProfileSurvey from "../Survey/FirstSurvey/PatientProfileSurvey/PatientProfileSurvey";
import { useTranslation } from "react-i18next";
import HealthConcernsSurvey from "../Survey/FirstSurvey/HealthConcernsSurvey/HealthConcernsSurvey";
import GlobalImprovementsSurvey from "../Survey/FirstSurvey/GlobalImprovementsSurvey/GlobalImprovementsSurvey";
import ThankYouSurvey from "../Survey/FirstSurvey/ThankYouSurvey/ThankYouSurvey";
import HealthStressesSurvey from "../Survey/FirstSurvey/HealthStressesSurvey/HealthStressesSurvey";
import NextHealthConcernsSurvey from "../Survey/NextSurvey/NextHealthConcernsSurvey/NextHealthConcernsSurvey";
import NextGlobalImprovementsSurvey from "../Survey/NextSurvey/NextGlobalImprovementsSurvey/NextGlobalImprovementsSurvey";
import NextLifestyleChangesSurvey from "../Survey/NextSurvey/NextLifestyleChangesSurvey/NextLifestyleChangesSurvey";
import NextIntroSurvey from "../Survey/NextSurvey/NextIntroSurvey/NextIntroSurvey";
import NextThankYouSurvey from "../Survey/NextSurvey/NextThankYouSurvey/NextThankYouSurvey";
import EditableIntroSurvey from "../Survey/EditableFirstSurvey/EditableIntroSurvey/EditableIntroSurvey";
import EditablePatientProfileSurvey from "../Survey/EditableFirstSurvey/EditablePatientProfileSurvey/EditablePatientProfileSurvey";
import EditableHealthStressesSurvey from "../Survey/EditableFirstSurvey/EditableHealthStressesSurvey/EditableHealthStressesSurvey";
import EditableHealthConcernsSurvey from "../Survey/EditableFirstSurvey/EditableHealthConcernsSurvey/EditableHealthConcernsSurvey";
import EditableGlobalImprovementsSurvey from "../Survey/EditableFirstSurvey/EditableGlobalImprovementsSurvey/EditableGlobalImprovementsSurvey";
import EditableThankYouSurvey from "../Survey/EditableFirstSurvey/EditableThankYouSurvey/EditableThankYouSurvey";
import EditableNextIntroSurvey from "../Survey/EditableNextSurvey/EditableNextIntroSurvey/EditableNextIntroSurvey";
import EditableNextHealthConcernsSurvey from "../Survey/EditableNextSurvey/EditableNextHealthConcernsSurvey/EditableNextHealthConcernsSurvey";
import EditableNextGlobalImprovementsSurvey from "../Survey/EditableNextSurvey/EditableNextGlobalImprovementsSurvey/EditableNextGlobalImprovementsSurvey";
import EditNextThankYouSurvey from "../Survey/EditableNextSurvey/EditNextThankYouSurvey/EditNextThankYouSurvey";
import EditableNextLifestyleChangesSurvey from "../Survey/EditableNextSurvey/EditableNextLifestyleChangesSurvey/EditableNextLifestyleChangesSurvey";
import HelpCenter from "../Admin Panel/HelpCenter/HelpCenter";
import Landing from "../Landing/Landing";
import getThemes from "../../../constants/colors/getTheme";
import Privacy from "../Privacy/Privacy";
import TestPrivacy from "../TestPrivacy/TestPrivacy";
import Terms from "../Terms/Terms";
import SignUp from "../Admin Panel/SignUp/SignUp";
import Otp from "../Admin Panel/SignUp/Otp";
import Details from "../Admin Panel/SignUp/Details";
import Pricing from "../Admin Panel/SignUp/Pricing";
import ForgotPassword from "../Admin Panel/ForgotPassword/ForgotPassword";
import ResetPassword from "../Admin Panel/ResetPassword/ResetPassword";
import PricePlan from "../Admin Panel/PricePlans/PricePlan";
import ProductTour from "../Landing/ProductTour";
import NewLanding from "../NewLanding/NewLanding";
import LifestyleChangesSurvey from "../Survey/FirstSurvey/LifestyleChangesSurvey/LifestyleChangesSurvey";
import EditableLifestyleChangesSurvey from "../Survey/EditableFirstSurvey/EditableLifestyleChangesSurvey/EditableLifestyleChangesSurvey";
import TestTerms from "../TestTerms/TestTerms";
import NotFound from "../NotFound/NotFound";
import Practitioners from "../Admin Panel/Practitioners/Practitioners";
import Practitioner from "../Admin Panel/Practitioner/Practitioner";
import PractitionersProfile from "../Admin Panel/PractitionersProfile/PractitionersProfile";

const RouterComponent = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const survey = useSelector((state) => state.survey);
  const { i18n } = useTranslation();
  const themeColor = useMemo(() => {
    return getThemes("mainColor", app.appTheme);
  }, [app]);

  const showGlobalSnackbar = (message, timeoutTime = 2000) => {
    dispatch(
      showSnackbar({
        showSnackbar: true,
        snackbarMessage: message,
      })
    );

    setTimeout(() => {
      dispatch(
        showSnackbar({
          showSnackbar: false,
          snackbarMessage: "",
        })
      );
    }, timeoutTime);
  };
  return (
    <div className="RouterComponent">
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <NewLanding showGlobalSnackbar={showGlobalSnackbar} app={app} />
            }
          />

          <Route
            path="/new-landing"
            element={<NewLanding showGlobalSnackbar={showGlobalSnackbar} />}
          />

          <Route
            path="/product-tour"
            element={<ProductTour showGlobalSnackbar={showGlobalSnackbar} />}
          />

          <Route
            path="/dashboard-paid"
            element={
              <Main showGlobalSnackbar={showGlobalSnackbar} paid={true} />
            }
          >
            <Route
              path="/dashboard-paid"
              element={<Patients showGlobalSnackbar={showGlobalSnackbar} />}
            />
          </Route>

          <Route
            path="/dashboard"
            element={<Main showGlobalSnackbar={showGlobalSnackbar} />}
          >
            <Route
              path="/dashboard"
              element={<Patients showGlobalSnackbar={showGlobalSnackbar} />}
            />
            <Route
              path="/dashboard/patient/:id"
              element={<Patient showGlobalSnackbar={showGlobalSnackbar} />}
            />
            <Route
              path="/dashboard/users"
              element={<Users showGlobalSnackbar={showGlobalSnackbar} />}
            />
            <Route
              path="/dashboard/practitioners"
              element={
                <Practitioners showGlobalSnackbar={showGlobalSnackbar} />
              }
            />

            <Route
              path="/dashboard/practitioner"
              element={<Practitioner showGlobalSnackbar={showGlobalSnackbar} />}
            />

            <Route
              path="/dashboard/practitioner/:id"
              element={
                <PractitionersProfile showGlobalSnackbar={showGlobalSnackbar} />
              }
            />

            <Route
              path="/dashboard/user/:id"
              element={<User showGlobalSnackbar={showGlobalSnackbar} />}
            />
            <Route
              path="/dashboard/statistics"
              element={<Statistics showGlobalSnackbar={showGlobalSnackbar} />}
            />
            <Route
              path="/dashboard/account"
              element={<MyAccount showGlobalSnackbar={showGlobalSnackbar} />}
            />
            <Route
              path="/dashboard/price-plans"
              element={<PricePlan showGlobalSnackbar={showGlobalSnackbar} />}
            />
            <Route
              path="/dashboard/settings"
              element={
                <SurveySettings showGlobalSnackbar={showGlobalSnackbar} />
              }
            />
            <Route
              path="/dashboard/help"
              element={<HelpCenter showGlobalSnackbar={showGlobalSnackbar} />}
            />
          </Route>
          <Route
            path="/survey/:code"
            element={
              <SurveyMain
                themeColor={themeColor}
                isNext={false}
                app={app}
                showGlobalSnackbar={showGlobalSnackbar}
              />
            }
          >
            <Route
              path="/survey/:code"
              element={
                <IntroSurvey
                  themeColor={themeColor}
                  isNext={false}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey/:code/patient-profile"
              element={
                <PatientProfileSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey/:code/health-concerns"
              element={
                <HealthConcernsSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey/:code/health-stresses"
              element={
                <HealthStressesSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey/:code/global-improvements"
              element={
                <GlobalImprovementsSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey/:code/lifestyle-changes"
              element={
                <LifestyleChangesSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey/:code/thank-you"
              element={
                <ThankYouSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
          </Route>

          <Route
            path="/survey-edit/:code/:surveyId"
            element={
              <SurveyMain
                themeColor={themeColor}
                isEditable={true}
                isNext={false}
                app={app}
                showGlobalSnackbar={showGlobalSnackbar}
              />
            }
          >
            <Route
              path="/survey-edit/:code/:surveyId"
              element={
                <EditableIntroSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  isNext={false}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-edit/:code/:surveyId/patient-profile"
              element={
                <EditablePatientProfileSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-edit/:code/:surveyId/health-concerns"
              element={
                <EditableHealthConcernsSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-edit/:code/:surveyId/health-stresses"
              element={
                <EditableHealthStressesSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-edit/:code/:surveyId/global-improvements"
              element={
                <EditableGlobalImprovementsSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-edit/:code/:surveyId/lifestyle-changes"
              element={
                <EditableLifestyleChangesSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-edit/:code/:surveyId/thank-you"
              element={
                <EditableThankYouSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
          </Route>

          <Route
            path="/survey-next/:code/:previousId"
            element={
              <SurveyMain
                themeColor={themeColor}
                isNext={true}
                app={app}
                showGlobalSnackbar={showGlobalSnackbar}
              />
            }
          >
            <Route
              path="/survey-next/:code/:previousId"
              element={
                <NextIntroSurvey
                  themeColor={themeColor}
                  isNext={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next/:code/:previousId/health-concerns"
              element={
                <NextHealthConcernsSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next/:code/:previousId/global-improvements"
              element={
                <NextGlobalImprovementsSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next/:code/:previousId/health-improvements"
              element={
                <NextLifestyleChangesSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next/:code/:previousId/thank-you"
              element={
                <NextThankYouSurvey
                  themeColor={themeColor}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
          </Route>

          <Route
            path="/survey-next-edit/:code/:surveyId/:previousId"
            element={
              <SurveyMain
                themeColor={themeColor}
                isEditable={true}
                isNext={true}
                app={app}
                showGlobalSnackbar={showGlobalSnackbar}
              />
            }
          >
            <Route
              path="/survey-next-edit/:code/:surveyId/:previousId"
              element={
                <EditableNextIntroSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  isNext={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next-edit/:code/:surveyId/:previousId/health-concerns"
              element={
                <EditableNextHealthConcernsSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next-edit/:code/:surveyId/:previousId/global-improvements"
              element={
                <EditableNextGlobalImprovementsSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next-edit/:code/:surveyId/:previousId/health-improvements"
              element={
                <EditableNextLifestyleChangesSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
            <Route
              path="/survey-next-edit/:code/:surveyId/:previousId/thank-you"
              element={
                <EditNextThankYouSurvey
                  themeColor={themeColor}
                  isEditable={true}
                  app={app}
                  survey={survey}
                  i18n={i18n}
                  showGlobalSnackbar={showGlobalSnackbar}
                />
              }
            />
          </Route>

          <Route
            path="/login"
            element={<Login showGlobalSnackbar={showGlobalSnackbar} />}
          />

          <Route
            path="/forgot-password"
            element={<ForgotPassword showGlobalSnackbar={showGlobalSnackbar} />}
          />

          <Route
            path="/reset-password/:token"
            element={<ResetPassword showGlobalSnackbar={showGlobalSnackbar} />}
          />

          <Route
            path="/signup"
            element={<SignUp showGlobalSnackbar={showGlobalSnackbar} />}
          />
          <Route
            path="/otp"
            element={
              <Otp sendAgain={false} showGlobalSnackbar={showGlobalSnackbar} />
            }
          />
          <Route
            path="/otp/again"
            element={
              <Otp sendAgain={true} showGlobalSnackbar={showGlobalSnackbar} />
            }
          />
          <Route
            path="/details"
            element={<Details showGlobalSnackbar={showGlobalSnackbar} />}
          />

          <Route
            path="/details-popup"
            element={
              <Details showGlobalSnackbar={showGlobalSnackbar} popup={true} />
            }
          />

          <Route
            path="/pricing-error"
            element={
              <Pricing
                app={app}
                i18n={i18n}
                showGlobalSnackbar={showGlobalSnackbar}
                paid={false}
              />
            }
          />

          <Route
            path="/pricing"
            element={
              <Pricing
                app={app}
                i18n={i18n}
                showGlobalSnackbar={showGlobalSnackbar}
                paid={true}
              />
            }
          />

          {/* <Route path="/privacy" element={<Privacy />} /> */}
          <Route path="/privacy" element={<TestPrivacy />} />
          <Route path="/terms" element={<TestTerms />} />
          {/* <Route path="/terms" element={<Terms />} /> */}
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>

      <Snackbar
        open={app.showSnackbar}
        autoHideDuration={2000}
        message={app.snackbarMessage}
      />
    </div>
  );
};

export default RouterComponent;
