import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TextInput from "./Comon/TextInput/TextInput";

const QuestionPropertiesForm = ({ questionNumber, formData, setFormData }) => {
  const user = useSelector((state) => state.user);
  const { i18n } = useTranslation();

  const propertiesSectionOne = [
    {
      name: "Subtitle",
      type: "text",
    },
    {
      name: "Title",
      type: "text",
    },
    {
      name: "Description",
      type: "textarea",
    },
  ];

  const propertiesSectionTwo = [
    {
      name: "LowScale",
      type: "text",
    },
    {
      name: "HighScale",
      type: "text",
    },
  ];

  const renderInput = (property, index) => {
    return (
      <Box className={"textContainer"} key={index}>
        <TextInput
          rows={7}
          label={`${i18n.t("Question")} ${questionNumber} ${i18n.t(
            property.name
          )}`}
          mode={"white"}
          placeholder={i18n.t("typeOutYourSupportTextHere")}
          type={property?.type}
          disabled={user.user.type === 3}
          setValue={(val) =>
            setFormData({
              ...formData,
              [`question${questionNumber}${property.name}`]: val,
            })
          }
          maxlength="250"
          errorMessage={
            formData?.[`question${questionNumber}${property.name}`]?.length >=
            250
              ? "250 " + i18n.t("characterLimitations")
              : null
          }
          value={formData?.[`question${questionNumber}${property.name}`]}
        />
      </Box>
    );
  };

  return (
    <>
      <h2>{`${i18n.t("Question")} ${questionNumber}`}</h2>
      {propertiesSectionOne.map((property, index) => {
        return renderInput(property, index);
      })}
      <div className="WrapInput">
        {propertiesSectionTwo.map((property, index) => {
          return (
            <div className="InputSize">{renderInput(property, index)}</div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionPropertiesForm;
