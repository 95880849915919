import React, { useEffect, useState } from "react";
import "./BusinessPlan.scss";
import { Box } from "@mui/material";
import Button from "../../Comon/Button/Button";
import { useSelector } from "react-redux";

const BusinessPlan = ({
  app,
  i18n,
  businessPlan,
  mode,
  isUpgradable,
  changePlan,
  isSelected,
  isSignup,
}) => {
  const [className, setClassName] = useState("");
  const [headerStyle, setHeaderStyle] = useState({});
  const [color, setColor] = useState("");
  const [buttonText, setButtonText] = useState(i18n.t("getStarted"));
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (mode === "other") {
      setHeaderStyle({
        borderBottom: "0",
      });
    }

    let selectedClass = "";
    if (isSelected) {
      selectedClass = "business-plan-selected";
    }

    switch (businessPlan.id) {
      case 1:
        setClassName("BusinessPlan BasicPlan " + selectedClass);
        setColor("#61849D");
        break;
      case 2:
        setClassName("BusinessPlan AdvancedPlan " + selectedClass);
        setColor("#86B49C");
        break;
      case 3:
        setClassName("BusinessPlan EnterprisePlan " + selectedClass);
        setColor("#EBC263");
        break;
    }
  }, [app]);

  useEffect(() => {
    if (!isSignup) {
      if (businessPlan.id > user?.user?.clinic?.businessPlan?.id) {
        setButtonText(i18n.t("upgradePlan"));
      } else {
        setButtonText(i18n.t("downgrade"));
      }
    }
  }, [user]);

  if (businessPlan) {
    return (
      <div className={className}>
        <Box className={"BusinessPlanHeader"} style={headerStyle}>
          <Box className={"BusinessPlanName"}>
            <div className={"BusinessPlanHeaderMini headerPlan"}>
              {isSelected ? <span>{i18n.t("yourPlan")} </span> : null}
            </div>
            <div className={"BusinessPlanHeaderMaxi"}>{businessPlan.name}</div>
          </Box>
          <Box className={"BusinessPlanName"}>
            <div className={"BusinessPlanHeaderMini"}>{i18n.t("perMonth")}</div>
            <div className={"BusinessPlanHeaderMaxi"}>
              {businessPlan.price} {businessPlan.currency}
            </div>
          </Box>
        </Box>
        <Box className={"BusinessPlanBody"}>
          {JSON.parse(businessPlan.description).map((description, item) => {
            return (
              <Box className={"businessPlanItem"} key={item}>
                <Box className={"businessPlanItemTickBox"}>
                  <img
                    className={"businessPlanItemTickBoxImg"}
                    src={"/assets/Icon_Checkmark2.svg"}
                    alt="businessPlanItemTickBoxImg"
                    width="17px"
                    height="12px"
                  />
                </Box>
                <Box className={"businessPlanItemText"}>{description} </Box>
              </Box>
            );
          })}
        </Box>

        {businessPlan.id === 3 ? (
          <Box
            className={"upgradeButtonContainer"}
            onClick={() => changePlan(businessPlan)}
          >
            <Button text={i18n.t("contactUs")} color={color} mode={"small"} />
          </Box>
        ) : null}

        {isUpgradable && businessPlan.id !== 3 ? (
          <Box
            className={"upgradeButtonContainer"}
            onClick={() => changePlan(businessPlan)}
          >
            <Button text={buttonText} color={color} mode={"small"} />
          </Box>
        ) : null}

        {isSelected && businessPlan.id !== 3 ? (
          <Box
            className={"upgradeButtonContainer"}
            onClick={() => changePlan(businessPlan)}
          >
            <Button
              text={i18n.t("manageSubscription")}
              color={"transparent"}
              mode={"small"}
            />
          </Box>
        ) : null}
      </div>
    );
  }
};

export default BusinessPlan;
