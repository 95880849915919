import React, { useEffect, useState } from "react";
import "./healthMetricsQuestions.scss";
import "react-tooltip/dist/react-tooltip.css";
import { useTranslation } from "react-i18next";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";
import { useDispatch, useSelector } from "react-redux";
import {
  bodyMetricsDefauls,
  getMyUser,
  updateBodyMetricsUpdate,
} from "../../../../core/axios/services";
import { useNavigate } from "react-router-dom";
import close from "../../../../../src/Ic_close.svg";
import infoIcon from "../../../../../src/infoIcon.svg";

const Index = ({
  showGlobalSnackbar,
  items,
  answersLang,
  businessPlan,
  userType,
  showSet,
  allSet,
  surveySettingId,
  surveySettings,
  updateBodyMetrics,
  updateUser,
}) => {
  const app = useSelector((state) => state.app);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [healthData, setHealthData] = useState("");
  const navigate = useNavigate();
  const [showStepEditPopup, setShowStepEditPopup] = useState(false);
  const [revertPopup, setRevertPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [editedDataArray, setEditedDataArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (field, value, languageIndex) => {
    setEditedDataArray((prevDataArray) => {
      const newDataArray = [...prevDataArray];
      const editedData = newDataArray[languageIndex] || {};
      newDataArray[languageIndex] = { ...editedData, [field]: value };

      return newDataArray;
    });
  };

  // Eliminiši duplicirane postavke na osnovu jezika
  const uniqueSurveySettings = [];
  const languageSet = new Set();

  // Prikazi trenutni jezik (showSet)
  const showSetSettings = user?.user?.clinic?.surveySettings.filter(
    (setting) =>
      setting?.language?.name === surveySettings?.language?.name &&
      setting?.type === surveySettings?.type
  );

  showSetSettings.forEach((setting) => {
    if (!languageSet.has(setting.language.name)) {
      languageSet.add(setting.language.name);
      uniqueSurveySettings.push(setting);
    }
  });

  const otherSettings = user?.user?.clinic?.surveySettings.filter(
    (setting) =>
      setting?.language?.name !== surveySettings?.language?.name &&
      setting?.type === surveySettings?.type
  );

  otherSettings.forEach((setting) => {
    if (!languageSet.has(setting.language.name)) {
      languageSet.add(setting.language.name);
      uniqueSurveySettings.push(setting);
    }
  });

  const handleBodyMetricsUpdate = (updatedBodyMetrics) => {
    // Call the updateBodyMetrics function passed from the parent component
    updateBodyMetrics(updatedBodyMetrics);
  };

  const updateMetric = async () => {
    const updatedSettings = uniqueSurveySettings.map((item, index) => {
      const currentBodyMetric = item.bodyMetrics[selectedItem];

      const surveySettingsId = currentBodyMetric?.surveySettingsId;

      return {
        id: surveySettingsId,
        ...currentBodyMetric,
        ...editedDataArray[index],
      };
    });

    showGlobalSnackbar("Saving...");

    try {
      const response = await updateBodyMetricsUpdate(updatedSettings, user);
      handleBodyMetricsUpdate(updatedSettings);
      getMyUser(user).then((res) => {
        updateUser(res.data.data);
      });
      showGlobalSnackbar("Saved");
    } catch (error) {
      console.error(error);
    }
  };

  const [revertInfo, setRevertInfo] = useState("");
  const [choosenIndex, setChoosenIndex] = useState("");

  const [loadingData, setLoadingData] = useState(false);

  const revertButton = async (index, editingBodyMetric) => {
    const editingBodyMetricOrder = editingBodyMetric.order;
    setChoosenIndex(editingBodyMetric.order);
    setRevertPopup(true);

    setLoadingData(true);

    try {
      await bodyMetricsDefauls(user).then((res) => {
        setLoadingData(false);

        //language from body metric being edited
        let editingBodyMetricLanguageId = editingBodyMetric.language_id;

        //from default data find the one with same language
        let defaultLanguageData = res.data?.find((item, index) => {
          return item?.id === editingBodyMetricLanguageId;
        });

        console.log(defaultLanguageData, "RESDATA");

        //from the default language found -> find body metric with order from editing one
        let editingBodyMetricDefaultData =
          defaultLanguageData?.bodyMetrics?.find((item, index) => {
            return item?.order === editingBodyMetricOrder;
          });

        if (editingBodyMetricDefaultData) {
          // Ako je pronađen objekt, možete s njim manipulirati ovdje
          setRevertInfo(editingBodyMetricDefaultData);
          console.log(editingBodyMetricDefaultData);
          // Ovdje možete nastaviti s manipulacijom podacima
        } else {
          console.log("Nema podataka s indeksom:", editingBodyMetricOrder);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    i18n.cloneInstance({ lng: "en" });
    i18n.cloneInstance({ lng: "es" });
    i18n.cloneInstance({ lng: answersLang });
  }, []);

  useEffect(() => {
    console.log("items   ", items);
  }, [items]);

  useEffect(() => {
    console.log("answersLang   ", answersLang);
    i18n.cloneInstance({ lng: answersLang });
  }, [answersLang]);

  const handleEditPopup = (index, item) => {
    setSelectedItem(index);
    setHealthData(item);

    if (businessPlan === 1) {
      upgradePackage();
    } else {
      confirmEdit();
    }
  };

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "confirmEditLink":
          handleLinkEdit();
          dispatch(resetPopUp());
          break;
        case "upgradePackageLink":
          handleLinkUpdate();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [app]);

  const handleLinkUpdate = () => {
    navigate("/dashboard/price-plans");
  };

  const handleLinkEdit = () => {
    setShowStepEditPopup(true);
  };

  const upgradePackage = () => {
    const data = {
      showPopup: true,
      data: {
        title: "This feature is available in the Advanced subscription",
        confirmButton: "Upgrade price plan",
        denyButton: "Cancel",
        type: "upgradePackageLink",
      },
    };
    dispatch(showPopup(data));
  };

  const confirmEdit = () => {
    const data = {
      showPopup: true,
      data: {
        title:
          "Are you sure you want to edit this Health Metric? Doing so will archive the current metric, and start a new statistic.",
        // basicDescription:
        //   "If you edit these questions, they won’t be used in General Statistics.",
        confirmButton: "Yes",
        denyButton: "Cancel",
        type: "confirmEditLink",
      },
    };
    dispatch(showPopup(data));
  };

  let [indexShow, setIndexShow] = useState(0);

  const languageWithIndexExist = (index) => {
    //Iz logovanog usera klinike uzmemo language koje ima i
    let clinicLanguages = user.user.clinic.languages;

    //da li postoji jezik sa tim indexom
    return clinicLanguages?.[index];
  };

  const isLastLanguage = (index) => {
    //Iz logovanog usera klinike uzmemo language koje ima i
    let clinicLanguages = user.user.clinic.languages;

    //da li postoji jezik sa tim indexom
    return clinicLanguages?.length === index + 1;
  };

  const handleSwitch = () => {
    if (isLastLanguage(indexShow)) {
      updateMetric();
      setIndexShow(0);
      setEditedDataArray([]);
      setShowStepEditPopup(false);
    }

    let languageExist = languageWithIndexExist(indexShow + 1);

    if (!languageExist) {
      return;
    }

    //ako postoji setIndexShow ako ne nista
    setIndexShow(indexShow + 1);

    // if (indexShow === 1) {
    //   setShowStepEditPopup(false);
    //   setIndexShow(0);
    //   updateMetric();
    //   setEditedDataArray([]);
    // }
  };

  const handleSwitchGoBack = () => {
    setIndexShow(indexShow - 1);
  };

  const handleSwitchSingle = () => {
    setShowStepEditPopup(false);
    setIndexShow(0);
    updateMetric();
    setEditedDataArray([]);
  };

  const handleClosePopup = () => {
    setShowStepEditPopup(false);
    setIndexShow(0);
    // updateMetric();
    setEditedDataArray([]);
  };

  const isAllBodyMetricsFull = uniqueSurveySettings.every(
    (item) => item.bodyMetrics.length > 0
  );

  const handleDefault = async () => {
    try {
      const res = await bodyMetricsDefauls(user);

      let defaultBodyMetricsData = res?.data;

      //revertInfo -> editing_body_metric_default_data
      let orderOfEditingBodyMetric = revertInfo?.order;

      //get only body metrics default data for the editing one
      let onlyEditingBodyMetricData = [];
      defaultBodyMetricsData?.map((language, index) => {
        onlyEditingBodyMetricData.push(
          language?.bodyMetrics?.find((languageDefaultBodyMetric, index) => {
            return (
              languageDefaultBodyMetric?.order === orderOfEditingBodyMetric
            );
          })
        );
      });

      //switch id and survey settings id from default to user ones
      onlyEditingBodyMetricData = onlyEditingBodyMetricData?.map(
        (defaultBodyMetric, index) => {
          let clinicBodyMetric = {};
          uniqueSurveySettings?.map((item, index) => {
            if (item.language.id === defaultBodyMetric?.language_id) {
              clinicBodyMetric = item.bodyMetrics.find((data, dataIndex) => {
                return data.order === orderOfEditingBodyMetric;
              });
            }
          });

          let newItem = defaultBodyMetric;
          newItem.id = clinicBodyMetric?.id;
          newItem.surveySettingsId = clinicBodyMetric?.surveySettingsId;
          newItem.isRestoringToDefault = true;

          return newItem;
        }
      );

      setLoading(true);

      try {
        await updateBodyMetricsUpdate(onlyEditingBodyMetricData, user);
        handleBodyMetricsUpdate(onlyEditingBodyMetricData);
        getMyUser(user).then((res) => {
          updateUser(res.data.data);
        });
        setLoading(false);
        setRevertPopup(false);
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="healthMetricsWrapp">
        <div className="healthWrapper">
          {items &&
            items.map((item, index) => (
              <div className="healthSection" key={index}>
                <div className="container">
                  <div className="spacing">
                    <h5>Title</h5>
                    <p>{item.title}</p>
                  </div>
                  <div className="questionField">
                    <h5>Question</h5>
                    <p>{item.question}</p>
                  </div>
                  <div className="questionField pdfField">
                    <h5>PDF name</h5>
                    <p>{item.pdfName}</p>
                  </div>
                  <div className="scaleWording">
                    <h5>Scale wording</h5>
                    <div className="scaleWordingContent">
                      <p>{item.lowScaleName}</p>
                      <div className="scaleRates">
                        <div className="rateField">1</div>
                        <div className="rateField">2</div>
                        <div className="rateField">3</div>
                        <div className="rateField">4</div>
                        <div className="rateField">5</div>
                        <div className="rateField">6</div>
                        <div className="rateField">7</div>
                        <div className="rateField">8</div>
                        <div className="rateField">9</div>
                        <div className="rateField">10</div>
                      </div>
                      <p>{item.highScaleName}</p>
                    </div>

                    <div className="scaleWordingContentMobile">
                      <div className="upperContent">
                        <p>Rigid</p>
                        <p>Flexible</p>
                      </div>
                      <div className="scaleRates">
                        <div className="rateField">1</div>
                        <div className="rateField">2</div>
                        <div className="rateField">3</div>
                        <div className="rateField">4</div>
                        <div className="rateField">5</div>
                        <div className="rateField">6</div>
                        <div className="rateField">7</div>
                        <div className="rateField">8</div>
                        <div className="rateField">9</div>
                        <div className="rateField">10</div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="editButton"
                    onClick={() => handleEditPopup(index, item)}
                  >
                    {i18n.t("editHealtmetric")}
                  </button>

                  {!item.isDefault && (
                    <button
                      className="revertButton"
                      onClick={() => revertButton(index, item)}
                    >
                      {i18n.t("restoreButton")}
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      {showStepEditPopup && (
        <>
          <div className="steperBackdrop">
            <div className="popupStyle">
              <h2>{i18n.t("makeChanges")}</h2>
              {uniqueSurveySettings
                .filter((languageSet, langIndex) => langIndex === indexShow)
                .map((lng, lngIndex) => {
                  return (
                    <>
                      <h4 key={lngIndex}>({lng.language.name})</h4>
                      {/* <span className="stepInfo">
                        <img src={infoIcon} alt={infoIcon} />
                        {indexShow > 0 ? "Second step." : "First step."}{" "}
                        {i18n.t("makeChangesIn")} {lng.language.name}{" "}
                        {i18n.t("language")}.
                      </span> */}
                    </>
                  );
                })}

              {uniqueSurveySettings.map((item, languageIndex) => (
                <div
                  key={languageIndex}
                  className={languageIndex === indexShow ? "show" : "hide"}
                >
                  {item.bodyMetrics
                    .filter((body, bodyIndex) => bodyIndex === selectedItem)
                    .map((body, bodyIndex) => (
                      <div key={bodyIndex}>
                        <div className="inputWrap">
                          <label>Title</label>
                          <input
                            // value={
                            //   editedDataArray[languageIndex]?.title ||
                            //   body.title
                            // }
                            value={
                              editedDataArray[languageIndex]?.title !==
                              undefined
                                ? editedDataArray[languageIndex]?.title
                                : body.title
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "title",
                                e.target.value,
                                languageIndex
                              )
                            }
                          ></input>
                        </div>

                        <div className="inputWrap">
                          <label>Question</label>
                          <textarea
                            // value={
                            //   editedDataArray[languageIndex]?.question ||
                            //   body.question
                            // }
                            value={
                              editedDataArray[languageIndex]?.question !==
                              undefined
                                ? editedDataArray[languageIndex]?.question
                                : body.question
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "question",
                                e.target.value,
                                languageIndex
                              )
                            }
                          ></textarea>
                        </div>

                        <div className="inputWrap">
                          <label>PDF name</label>
                          <input
                            // value={
                            //   editedDataArray[languageIndex]?.pdfName ||
                            //   body.pdfName
                            // }
                            value={
                              editedDataArray[languageIndex]?.pdfName !==
                              undefined
                                ? editedDataArray[languageIndex]?.pdfName
                                : body.pdfName
                            }
                            maxLength="24"
                            onChange={(e) =>
                              handleInputChange(
                                "pdfName",
                                e.target.value,
                                languageIndex
                              )
                            }
                          ></input>
                          <span className="inputLengthCounter">
                            {editedDataArray[languageIndex]?.pdfName?.length ||
                              body.pdfName.length}{" "}
                            / 24
                          </span>
                        </div>

                        <div className="scoreWrapper">
                          <div className="inputWrap">
                            <label>
                              The name of the lowest score <strong>(1)</strong>
                            </label>
                            <input
                              // value={
                              //   editedDataArray[languageIndex]?.lowScaleName ||
                              //   body.lowScaleName
                              // }
                              value={
                                editedDataArray[languageIndex]?.lowScaleName !==
                                undefined
                                  ? editedDataArray[languageIndex]?.lowScaleName
                                  : body.lowScaleName
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "lowScaleName",
                                  e.target.value,
                                  languageIndex
                                )
                              }
                            ></input>
                          </div>
                          <div className="inputWrap">
                            <label>
                              The name of the highest score{" "}
                              <strong>(10)</strong>
                            </label>
                            <input
                              // value={
                              //   editedDataArray[languageIndex]?.highScaleName ||
                              //   body.highScaleName
                              // }

                              value={
                                editedDataArray[languageIndex]
                                  ?.highScaleName !== undefined
                                  ? editedDataArray[languageIndex]
                                      ?.highScaleName
                                  : body.highScaleName
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "highScaleName",
                                  e.target.value,
                                  languageIndex
                                )
                              }
                            ></input>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}

              {isAllBodyMetricsFull === true ? (
                <div className="popupButtons">
                  {" "}
                  <button className="submitForm" onClick={handleSwitch}>
                    {isLastLanguage(indexShow) ? "Save changes" : "Next step"}
                  </button>
                  {indexShow > 0 && (
                    <button
                      className="submitFormBack"
                      onClick={handleSwitchGoBack}
                    >
                      Go back
                    </button>
                  )}
                </div>
              ) : (
                <button className="submitForm" onClick={handleSwitchSingle}>
                  Save changes
                </button>
              )}

              <img
                src={close}
                alt={close}
                className="closePopup"
                onClick={handleClosePopup}
              />
            </div>
          </div>
        </>
      )}

      {revertPopup && (
        <div className="revertPopup">
          <div className="steperBackdrop">
            <div className="popupStyle">
              <h2 style={{ marginBottom: "40px" }}>
                {i18n.t("restoreMainTitle")}
              </h2>
              <span className="stepInfo" style={{ marginBottom: "20px" }}>
                <img src={infoIcon} alt={infoIcon} />
                {i18n.t("restoreDesc")}
              </span>
              <div className="revertContent">
                <div className="fields">
                  <h3>Title</h3>
                  <h5>{revertInfo.title}</h5>
                </div>
                <div className="fields">
                  <h3>Question</h3>
                  <h5>{revertInfo.question}</h5>
                </div>
                <div className="fields">
                  <h3>PDF name </h3>
                  <h5>{revertInfo.pdfName}</h5>
                </div>
                <div className="fields">
                  <h3>
                    The name of the lowest score <strong>(1)</strong>
                  </h3>
                  <h5>{revertInfo.lowScaleName}</h5>
                </div>
                <div className="fields">
                  <h3>
                    The name of the highest score <strong>(10)</strong>
                  </h3>
                  <h5>{revertInfo.highScaleName}</h5>
                </div>
              </div>
              <div className="confirmMessage">
                <h2>{i18n.t("restoreMessage")}</h2>
              </div>

              {loadingData ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  loading...
                </div>
              ) : (
                <div className="revertButtons">
                  <button
                    className="apply"
                    onClick={() => handleDefault(choosenIndex)}
                    disabled={loading ? true : false}
                  >
                    {loading ? "Saving..." : "Yes, apply"}
                  </button>

                  <button
                    className="cancel"
                    onClick={() => setRevertPopup(!revertPopup)}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
