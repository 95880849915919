import React, {useEffect, useState} from 'react';
import './Tab.scss';

const Tab = ({text, isActive, page, setPage}) => {
    const [style, setStyle] = useState({});

    useEffect(() => {
        setStyle({
            color: "#1F1F1F"
        })
    }, []);


  return (
      <div className={"Tab"} onClick={() => setPage(page)}  style={isActive ? style : null}>
        {text}
      </div>
  );
};

export default Tab;
