import React, { useMemo, useState } from "react";
import "./VideoPlayer.scss";
import PlayIcon from "../PlayIcon/PlayIcon";
import ReactPlayer from "react-player";

const VideoPlayer = ({
  themeColor,
  url,
  videoSize,
  landing = false,
  controls = false,
  className,
  onPlay,
}) => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const containerClass = useMemo(() => {
    if (landing === true) {
      return "videoContainerLanding";
    } else {
      return "videoContainer";
    }
  }, [landing]);
  const toStopVideo = () => {
    if (videoPlaying) {
      setVideoPlaying(false);
    }
  };

  const playVideo = () => {
    setVideoPlaying(true);
  };

  return (
    <div className={containerClass} onClick={() => toStopVideo()}>
      <div
        className={"playContainer"}
        style={videoPlaying ? { opacity: 0 } : null}
        onClick={playVideo}
      >
        <PlayIcon themeColor={themeColor} />
      </div>

      <ReactPlayer
        playing={videoPlaying}
        url={url}
        width={videoSize.width}
        controls={controls}
        height={videoSize.height}
        playIcon={<PlayIcon themeColor={themeColor} />}
        className={className}
        onPlay={onPlay}
      />
    </div>
  );
};

export default VideoPlayer;
