export const defaultTheme = {
  mainColor: "#61849D",
  secondaryColor: "#EEFBFF",
  thirdColor: "#F2FCFF",
  opacityColor: "rgba(97, 132, 157, 0.15)"
};

export const blue = {
  mainColor: "#61849D",
  secondaryColor: "#EEFBFF",
  opacityColor: "rgba(97, 132, 157, 0.15)"
};

export const green = {
  mainColor: "#86B49C",
};

export const orange = {
  mainColor: "#E77B2C",
};

export const black = {
  mainColor: "#1F1F1F",
};

export const turquoise = {
  mainColor: "#00BBB4",
};
