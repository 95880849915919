import {useDispatch, useSelector} from "react-redux";
import SplashScreen from "./SplashScreen/SplashScreen";
import RouterComponent from "./RouterComponent/RouterComponent";
import {useEffect} from "react";
import {setLoadingAction} from "../../redux/actions/app";

const StructureComponent = () => {
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
    }, [app.isLoading]);


    return (
        <div>
            <RouterComponent />
        </div>
    )


}

export default StructureComponent;