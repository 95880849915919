import './App.scss';
import {Provider, useSelector} from "react-redux";
import reduxStore from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import StructureComponent from "./elements/pages/StructureComponent";
import {useEffect} from "react";
import {getVersion} from "./core/axios/services";
import packageInfo from "../package.json"

function App() {
    const {store, persistor} = reduxStore();

    useEffect(() => {
        getVersion().then(res => {
            console.log("Loaded: " + res.data.version, "Required: " + packageInfo.version);

            if (Number(res.data.version) > Number(packageInfo.version)) {
                handleHardReload(window.location.href);
            }
        })
    }, []);

    const urlWithRndQueryParam = (url, paramName) => {
        const ulrArr = url.split('#');
        const urlQry = ulrArr[0].split('?');
        const usp = new URLSearchParams(urlQry[1] || '');
        usp.set(paramName || '_z', `${Date.now()}`);
        urlQry[1] = usp.toString();
        ulrArr[0] = urlQry.join('?');
        return ulrArr.join('#');
    }

    const handleHardReload = (url)  => {
        window.location.href = urlWithRndQueryParam(url);
        // This is to ensure reload with url's having '#'
        window.location.reload();
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null}  persistor={persistor}>
                <StructureComponent />
            </PersistGate>
        </Provider>
    );
}

export default App;
