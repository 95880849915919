import React, { useEffect, useState } from "react";
import "./AccountSettings.scss";
import { Box } from "@mui/material";
import Headline from "../../../components/Comon/Headline/Headline";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Comon/Button/Button";
import UploadFile from "../../../components/Admin Panel/UploadField/UploadFile";
import { FileUploader } from "react-drag-drop-files";
import ColorScheme from "../../../components/Admin Panel/ColorScheme/ColorScheme";
import BusinessPlan from "../../../components/Admin Panel/BusinessPlan/BusinessPlan";
import ConfirmPopUp from "../../../components/Admin Panel/ConfirmPopUp/ConfirmPopUp";
import {
  getAppData,
  resetPopUp,
  setAppTheme,
  showPopup,
  showSnackbar,
  deleteUserLogo,
} from "../../../../redux/actions/app";
import axios from "axios";
import { API } from "../../../../constants/services/base";
import {
  addUser,
  addUserToken,
  logoutUser,
  setUserInfoAction,
} from "../../../../redux/actions/user";
import {
  putUser,
  updateReviewLink,
  refreshTokenApi,
  resetPassword, updateDataProtection,
} from "../../../../core/axios/services";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";

const AccountSettings = ({ app, i18n, showGlobalSnackbar }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [newEmail, setNewEmail] = useState("");
  const [newEmailError, setNewEmailError] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [name, setName] = useState("");
  const [desc, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [reviewLink, setReviewLink] = useState("");
  const [reviewLinkBasic, setReviewLinkBasic] = useState("");
  const [account, setAccount] = useState(null);
  const [file, setFile] = useState(null);
  const [theme, setTheme] = useState(0);
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };
  const fileTypes = ["JPG", "PNG"];

  const [logo, setLogo] = useState("/assets/logotm.png");

  useEffect(() => {
    setLogo("");
  }, []);

  useEffect(() => {
    if (user.user && user.user.clinic && user.user.clinic.logo) {
      setLogo(user.user.clinic.logo);
    }
  }, [user]);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "dataProtection":
          uploadDataProtection();
          dispatch(resetPopUp());
          break;
        case "name":
          account.clinicName = name;
          updateUser(app.popUpData?.type);
          dispatch(resetPopUp());
          break;
        case "desc":
          account.clinicDesc = desc;
          updateUser(app.popUpData?.type);
          dispatch(resetPopUp());
          break;
        case "address":
          account.address = address;
          updateUser(app.popUpData?.type);
          dispatch(resetPopUp());
          break;
        case "linkReview":
          // account.link = reviewLink;
          // updateUser(app.popUpData?.type);
          handleLinkUpdate();
          dispatch(resetPopUp());
          break;
        case "linkReviewBasic":
          handleBasicPlan();
          dispatch(resetPopUp());
          break;
        case "linkReviewBasic":
          handleBasicPlan();
          dispatch(resetPopUp());
          break;
        case "email":
          account.email = newEmail;
          updateUser(app.popUpData?.type);
          dispatch(resetPopUp());
          break;
        case "theme":
          account.theme = theme;
          updateUser(app.popUpData?.type);
          dispatch(resetPopUp());
          break;
        case "logo":
          uploadImage();
          dispatch(resetPopUp());
          break;
        // case "deleteLogo":
        //   changeDeleteLogo();
        //   dispatch(resetPopUp());
        //   break;
        case "businessPlan":
          account.plan = selectedPlan.id;
          updateUser(app.popUpData?.type);
          dispatch(resetPopUp());
          break;
        case "password":
          updatePassword();
          dispatch(resetPopUp());
          break;
        case "changePricePlan":
          navigate("/dashboard/price-plans");
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [app]);

  useEffect(() => {
    setAccount(user.user);
    if (name === "") setName(user.user.clinic.name);
    if (desc === "") setDescription(user.user.clinic.desc);
    if (address === "") setAddress(user.user.clinic.address);
    if (reviewLink === "") setReviewLink(user.user.clinic.googleLink);
    if (reviewLinkBasic === "") setReviewLinkBasic("");
    if (currentEmail === "") setCurrentEmail(user.user.email);
    if (theme === 0) {
      setTheme(user.user.clinic.theme.id);
    }
  }, [user]);

  const checkIfPossibleToEdit = () => {
    let possibleToEdit = false;
    if (user?.user?.clinic?.businessPlan?.id != 1) {
      possibleToEdit = true;
    }

    if (!possibleToEdit) {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("changePricePlan"),
          confirmButton: i18n.t("changePricePlanButton"),
          denyButton: i18n.t("cancel"),
          type: "changePricePlan",
        },
      };
      if (user?.user?.type == 2) {
        dispatch(showPopup(data));
      }
    }
  };

  const handleChange = (file) => {
    let possibleToEdit = false;
    if (user?.user?.clinic?.businessPlan?.id != 1) {
      possibleToEdit = true;
    }

    if (!possibleToEdit) {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("changePricePlan"),
          confirmButton: i18n.t("changePricePlanButton"),
          denyButton: i18n.t("cancel"),
          type: "changePricePlan",
        },
      };
      dispatch(showPopup(data));
    } else {
      setFile(file);
      changeLogo();
    }
  };

  function handleChangeDataProtection(file){
    setFile(file);
    changeDataProtection();
  }


  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", file);
    // const data = {
    //   image: result,
    // };
    await axios.post(`${API}/file`, formData, axiosConfig).then((res) => {
      account.logo = res.data.data.path;
      updateUser(app.popUpData?.type);
    });
  };

  const uploadDataProtection = async () => {
    const formData = new FormData();
    formData.append("file", file);

    await axios.post(`${API}/file`, formData, axiosConfig).then((res) => {
      account.dataProtectionUrl = res.data.data.path;
      updateUserDataProtection(res.data.data.path);
    });
  };

  const getUser = async () => {
    await axios.get(`${API}/user`, axiosConfig).then((res) => {
      dispatch(addUser(res.data.data));
    });
  };

  const updateUser = async (type) => {
    try {
      setAccount({ ...account });
      return putUser(account, user)
        .then((res) => {
          console.log(res.data, "RES");
          let message = i18n.t("saved");
          showGlobalSnackbar(message);
          getUser();
          // handleLinkUpdate();
          switch (type) {
            case "email":
              setCurrentEmail(res.data.data.email);
              setNewEmail("");
              break;
          }
        })
        .catch((error) => {
          showGlobalSnackbar(error.response.data.errorMessage);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        });
    } catch {
      showGlobalSnackbar(i18n.t("showGlobalSnackbar"));
    }
  };

  const updateUserDataProtection = async (path) => {
    try {
      setAccount({ ...account });
      return updateDataProtection({path: path}, user)
          .then((res) => {
            let message = i18n.t("saved");
            showGlobalSnackbar(message);
            getUser();
          })
          .catch((error) => {
            showGlobalSnackbar(error.response.data.errorMessage);
            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          });
    } catch {
      showGlobalSnackbar(i18n.t("showGlobalSnackbar"));
    }
  };

  const handleLinkUpdate = async () => {
    try {
      return await updateReviewLink(reviewLink, user).then((res) => {
        account.link = res.data.googleLink;
        updateUser(app.popUpData?.type);
        //  updateUser(app.popUpData.type);
        // dispatch(addUser(res.data.data));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBasicPlan = () => {
    navigate("/dashboard/price-plans");
  };

  const updatePassword = async () => {
    try {
      return resetPassword(currentEmail, currentPassword, newPassword, user)
        .then((res) => {
          let message = i18n.t("passwordChanged");
          showGlobalSnackbar(message);
          setCurrentPassword("");
          setConfirmPassword("");
          setNewPassword("");
          dispatch(addUserToken(res.data.data), true);
        })
        .catch((error) => {
          // console.log(error.response.data.errorMessage);
          showGlobalSnackbar(error.response.data.errorMessage);
        });
    } catch {
      showGlobalSnackbar(i18n.t("showGlobalSnackbar"));
    }
  };

  const changeName = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmNameChange"),
        confirmButton: i18n.t("confirmNameButton"),
        denyButton: i18n.t("denyNameButton"),
        type: "name",
      },
    };
    dispatch(showPopup(data));
  };

  const changeDescription = () => {
    const data = {
      showPopup: true,
      data: {
        title: "Are you sure you want to change description?",
        confirmButton: "Yes",
        denyButton: i18n.t("denyNameButton"),
        type: "desc",
      },
    };
    dispatch(showPopup(data));
  };

  const changeDataProtection = () => {
    const data = {
      showPopup: true,
      data: {
        title: "Are you sure you want to change your Data Processing terms?",
        confirmButton: "Yes",
        denyButton: i18n.t("denyNameButton"),
        type: "dataProtection",
      },
    };
    dispatch(showPopup(data));
  };

  const changeAddress = () => {
    const data = {
      showPopup: true,
      data: {
        title: "Are you sure you want to change your address?",
        confirmButton: "Yes, change my address",
        denyButton: i18n.t("denyNameButton"),
        type: "address",
      },
    };
    dispatch(showPopup(data));
  };

  const changeReviewLink = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmlinkChange"),
        confirmButton: i18n.t("confirmLinkButton"),
        denyButton: i18n.t("denyNameButton"),
        type: "linkReview",
      },
    };
    dispatch(showPopup(data));
  };

  const changeReviewLinkBasic = () => {
    const data = {
      showPopup: true,
      data: {
        title: "This feature is available in the Advanced subscription",
        confirmButton: "Upgrade price plan",
        denyButton: "Cancel",
        type: "linkReviewBasic",
      },
    };
    dispatch(showPopup(data));
  };

  const updateUserTheme = (theme) => {
    setTheme(theme);

    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmThemeChange"),
        confirmButton: i18n.t("confirmThemeButton"),
        denyButton: i18n.t("denyThemeButton"),
        type: "theme",
      },
    };
    dispatch(showPopup(data));
  };

  const updateBusinessPlan = (plan) => {
    setSelectedPlan(plan);
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmUpgradePlan"),
        confirmButton: i18n.t("confirmUpgradePlanButton"),
        denyButton: i18n.t("denyUpgradePlanButton"),
        type: "businessPlan",
      },
    };
    dispatch(showPopup(data));
  };

  const changeEmail = () => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(newEmail)) {
      setNewEmailError(i18n.t("emailFormat"));
      return;
    } else {
      setNewEmailError(null);
    }
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmEmailChange"),
        confirmButton: i18n.t("confirmEmailButton"),
        denyButton: i18n.t("denyEmailButton"),
        type: "email",
      },
    };
    dispatch(showPopup(data));
  };

  const changePassword = () => {
    if (currentPassword === "") {
      setCurrentPasswordError(i18n.t("insertPassword"));
      return;
    } else {
      setCurrentPasswordError(null);
    }

    if (confirmPassword !== newPassword) {
      setPasswordError(i18n.t("passwordsMustBeTheSame"));
      return;
    } else if (newPassword === "") {
      setPasswordError(i18n.t("insertPassword"));
      return;
    } else if (newPassword.length < 6) {
      setPasswordError(i18n.t("passwordRule"));
      return;
    } else {
      setPasswordError(null);
    }

    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmPasswordChange"),
        confirmButton: i18n.t("confirmPasswordButton"),
        denyButton: i18n.t("denyPasswordButton"),
        type: "password",
      },
    };
    dispatch(showPopup(data));
  };

  const changeLogo = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmLogoChange"),
        confirmButton: i18n.t("confirmLogoButton"),
        denyButton: i18n.t("denyLogoButton"),
        type: "logo",
      },
    };
    dispatch(showPopup(data));
  };

  const [srcImg, setSrcImg] = useState(null);
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });
  const [result, setResult] = useState(null);

  const handleImage = (file) => {
    setSrcImg(URL.createObjectURL(file));
  };

  const getCroppedImg = () => {
    const image = document.createElement("img");
    image.src = srcImg;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      setResult(base64Image);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(result);
  };

  const handleDeleteLogo = () => {
    let tempUser = user.user;
    tempUser.clinic.logo = "";
    dispatch(setUserInfoAction(tempUser));
    setLogo("");
    tempUser.logo = "empty";
    putUser(tempUser, user);
    // changeDeleteLogo();
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  };

  // const changeDeleteLogo = () => {
  //   const data = {
  //     showPopup: true,
  //     data: {
  //       title: "Are you sure you want to delete",
  //       confirmButton: i18n.t("confirmLogoButton"),
  //       denyButton: i18n.t("denyLogoButton"),
  //       type: "deleteLogo",
  //     },
  //   };
  //   dispatch(showPopup(data));
  // };

  return (
    <div className="AccountSettings">
      <Box className={"mainAccount"}>
        <Headline
          text={i18n.t("accountInformation")}
          type={"Headline-medium"}
        />

        {user.user.type === 2 ? (
          <Box className={"sectionChange"}>
            <Headline
              text={i18n.t("accountNameChange")}
              type={"Headline-small"}
            />
            <Box className={"textInputContainer"}>
              <TextInput
                type={"text"}
                setValue={setName}
                label={i18n.t("accountName")}
                value={name}
                mode={"grey"}
              />
              <Box className={"buttonContainer"} onClick={() => changeName()}>
                <Button text={i18n.t("editAccountName")} mode={"small"} />
              </Box>
            </Box>
          </Box>
        ) : null}

        {user.user.type === 2 ? (
          <Box className={"sectionChange"}>
            <Headline
              text={i18n.t("accountDescriptionChange")}
              type={"Headline-small"}
            />
            <Box className={"textInputContainer"}>
              <TextInput
                type={"textarea"}
                setValue={setDescription}
                label={i18n.t("Description")}
                value={desc}
                mode={"grey"}
                maxlength="300"
                errorMessage={
                  desc?.length >= 300
                    ? "300 " + i18n.t("characterLimitations")
                    : null
                }
              />
              <Box
                className={"buttonContainer"}
                onClick={() => changeDescription()}
              >
                <Button text={i18n.t("editDescription")} mode={"small"} />
              </Box>
            </Box>
          </Box>
        ) : null}

        {/* DATA PROTECTION PDF */}
        {user.user.type === 2 ? (
            <Box className={"sectionChange"}>
              <Headline
                  text={i18n.t("dataProcessingTitle")}
                  type={"Headline-small"}
              />
              <p style={{marginTop:'42px', maxWidth:'580px'}}>
                {i18n.t('dataProcessingDesc')}
                <a href={user?.user?.clinic?.defaultDataProtectionUrl} target={"_blank"}>{i18n.t('dataProcessingName')}</a>
                {i18n.t('dataProcessingDesc2')}
              </p>
              <p style={{marginTop:'18px', maxWidth:'580px'}}>{i18n.t('supportedPdf')}</p>

              {
                user?.user?.clinic?.dataProtectionUrl && <>
                  <a
                      target={'_blank'}
                      style={{
                        textDecoration: 'underline',
                        color: 'rgba(73, 73, 73, 1)',
                        fontSize: '14px',
                        fontWeight: '400',
                        letterSpacing: '0px',
                        textAlign: 'left',
                      }}
                      href={user?.user?.clinic?.dataProtectionUrl}
                  >{i18n.t("showUploadedDataProtection")}</a>

                    <Box className={"uploadButtonContainer"}
                         onClick={() => updateUserDataProtection("")}
                    >
                      <Button
                          text={i18n.t("dataProcessingButtonDelete")}
                          mode={"small"}
                      />
                    </Box>
                </>
              }

              {
                !user?.user?.clinic?.dataProtectionUrl && <FileUploader
                      handleChange={handleChangeDataProtection}
                      all
                      name="file"
                      type="file"
                      types={['pdf']}
                  >
                    <Box className={"uploadButtonContainer"}>
                      <Button
                          text={i18n.t("dataProcessingButton")}
                          mode={"small"}
                      />
                    </Box>
                  </FileUploader>
              }

            </Box>
        ) : null}
        {/* DATA PROTECTION PDF END */}

        {user.user.type === 2 ? (
          <Box className={"sectionChange"}>
            <Headline text="Address" type={"Headline-small"} />
            <Box className={"textInputContainer"}>
              <TextInput
                type={"text"}
                setValue={setAddress}
                label="Address"
                value={address}
                mode={"grey"}
              />
              <Box
                className={"buttonContainer"}
                onClick={() => changeAddress()}
              >
                <Button text="Edit address" mode={"small"} />
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box className={"sectionChange"}>
          <Headline text={i18n.t("emailChange")} type={"Headline-small"} />
          <Box className={"textInputContainer"}>
            <TextInput
              type={"text"}
              label={i18n.t("currentEmail")}
              setValue={setCurrentEmail}
              value={currentEmail}
              disabled={true}
              mode={"grey"}
            />
            <Box className={"divider"} />
            <TextInput
              type={"text"}
              placeholder={i18n.t("typeOutNewEmail")}
              label={i18n.t("newEmail")}
              value={newEmail}
              setValue={setNewEmail}
              mode={"grey"}
              errorMessage={newEmailError}
            />
            <Box className={"buttonContainer"} onClick={() => changeEmail()}>
              <Button text={i18n.t("editEmail")} mode={"small"} />
            </Box>
          </Box>
        </Box>

        <Box className={"sectionChange"}>
          <Headline text={i18n.t("passwordChange")} type={"Headline-small"} />
          <Box className={"textInputContainer"}>
            <TextInput
              type={"password"}
              placeholder={i18n.t("typeOutOldPassword")}
              label={i18n.t("currentPassword")}
              value={currentPassword}
              setValue={setCurrentPassword}
              mode={"grey"}
              errorMessage={currentPasswordError}
            />
            <Box className={"divider"} />
            <TextInput
              type={"password"}
              placeholder={i18n.t("typeOutNewPassword")}
              label={i18n.t("newPassword")}
              value={newPassword}
              setValue={setNewPassword}
              mode={"grey"}
              errorMessage={passwordError}
            />
            <Box className={"divider"} />
            <TextInput
              type={"password"}
              placeholder={i18n.t("confirmPassword")}
              label={i18n.t("confirmPassword")}
              value={confirmPassword}
              setValue={setConfirmPassword}
              mode={"grey"}
              errorMessage={passwordError}
            />
            <Box className={"buttonContainer"} onClick={() => changePassword()}>
              <Button text={i18n.t("editPassword")} mode={"small"} />
            </Box>
          </Box>
        </Box>

        <Box className={"sectionChange"}>
          <Headline text={i18n.t("accountLink")} type={"Headline-small"} />
          <Box className={"textInputContainer"}>
            <TextInput
              type={"text"}
              setValue={
                user?.user?.clinic?.businessPlan?.name === "Basic"
                  ? setReviewLinkBasic
                  : setReviewLink
              }
              label={i18n.t("link")}
              value={
                user?.user?.clinic?.businessPlan?.name === "Basic"
                  ? reviewLinkBasic
                  : reviewLink
              }
              mode={"grey"}
              placeholder={
                user?.user?.clinic?.businessPlan?.name
                  ? "Please upgrade business plan"
                  : ""
              }
            />

            {user?.user?.clinic?.businessPlan?.name === "Basic" ? (
              <Box
                className={"buttonContainer"}
                onClick={() => changeReviewLinkBasic()}
              >
                <Button text={i18n.t("saveAccountLink")} mode={"small"} />
              </Box>
            ) : (
              <Box
                className={"buttonContainer"}
                onClick={() => changeReviewLink()}
              >
                <Button text={i18n.t("saveAccountLink")} mode={"small"} />
              </Box>
            )}
          </Box>
        </Box>

        {user.user.type === 2 ? (
          <Box className={"sectionChange"}>
            <Headline
              text={i18n.t("accountWhiteLabeling")}
              type={"Headline-medium"}
            />

            {logo ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                }}
              >
                Choose another logo:
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    marginTop: 20,
                    width: 200,
                    height: 200,
                    objectFit: "contain",
                  }}
                />
                <button
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    width: 192,
                    height: 32,
                    fontSize: 14,
                    border: "none",
                    borderWidth: 1,
                    borderRadius: 23,
                    boxShadow: "0px 2px 4px rgb(217, 217, 217)",
                    backgroundColor: "rgb(97, 132, 157)",
                    color: "#fff",
                  }}
                  onClick={handleDeleteLogo}
                >
                  Delete logo
                </button>
              </div>
            ) : (
              <div>
                <Box className={"sectionChange"}>
                  <Headline
                    text={i18n.t("uploadLogo")}
                    type={"Headline-small"}
                  />
                </Box>
                <Box className={"uploadContainer"}>
                  {user?.user?.clinic?.businessPlan?.id != 1 ? (
                    <FileUploader
                      // handleChange={handleImage}
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    >
                      <UploadFile app={app} i18n={i18n} />
                    </FileUploader>
                  ) : (
                    <div onClick={() => checkIfPossibleToEdit()}>
                      <UploadFile app={app} i18n={i18n} />
                    </div>
                  )}
                  <Box className={"uploadText"}>
                    {i18n.t("uploadDescription")}
                    <br />
                    {user?.user?.clinic?.businessPlan?.id != 1 ? (
                      <FileUploader
                        // handleChange={handleImage}
                        handleChange={handleChange}
                        all
                        name="file"
                        type="file"
                        accept="image/*"
                      >
                        <Box className={"uploadButtonContainer"}>
                          <Button
                            text={i18n.t("chooseYourFile")}
                            mode={"small"}
                          />
                        </Box>
                      </FileUploader>
                    ) : (
                      <Box
                        className={"uploadButtonContainer"}
                        onClick={() => checkIfPossibleToEdit()}
                      >
                        <Button
                          text={i18n.t("chooseYourFile")}
                          mode={"small"}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </div>
            )}
          </Box>
        ) : null}

        {/* <div>
          <input type="file" accept="image/*" onChange={handleImage} />
        </div> */}
        {/* <div style={{ marginTop: 30 }}>
          {srcImg && (
            <div>
              Crop your image:
              <br /> <br />
              <ReactCrop
                src={srcImg}
                onComplete={(crop) => setCrop(crop)}
                crop={crop}
                onChange={(crop) => setCrop(crop)}
                aspect={1}
                minWidth={200}
                minHeight={200}
                maxWidth={200}
                maxHeight={200}
              >
                <img src={srcImg} />
              </ReactCrop>
              <br />
              <br />
              <button
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  width: 100,
                  border: "none",
                  borderRadius: 23,
                  boxShadow: "0px 2px 4px rgb(217, 217, 217)",
                  backgroundColor: "rgb(97, 132, 157)",
                  color: "#fff",
                }}
                onClick={getCroppedImg}
              >
                Crop
              </button>
              {result && (
                <div>
                  <p>Cropped image:</p>
                  <img src={result} alt="cropped image" />
                  <br />
                  <button
                    onClick={changeLogo}
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      width: 100,
                      border: "none",
                      borderRadius: 23,
                      boxShadow: "0px 2px 4px rgb(217, 217, 217)",
                      backgroundColor: "rgb(97, 132, 157)",
                      color: "#fff",
                    }}
                  >
                    Save crop
                  </button>
                </div>
              )}
            </div>
          )}
        </div> */}

        {user.user.type === 2 ? (
          <Box className={"sectionChange"}>
            <Headline
              text={i18n.t("accountColorScheme")}
              type={"Headline-small"}
            />
            <div className={"colorSchemeContainer"}>
              <ColorScheme
                app={app}
                selected={theme}
                setSelected={(e) => updateUserTheme(e)}
              />
            </div>
          </Box>
        ) : null}

        {user.user.type === 2 ? (
          <Box className={"sectionChange"}>
            <Headline
              text={i18n.t("yourActivePlan")}
              type={"Headline-medium"}
            />
            <Box className={"forMyBusinessPlan"}>
              <div className={"businessPlanContainer"}>
                {user?.user?.clinic?.businessPlan.name === "Advanced" && (
                  <div className="mostPopular">Most popular</div>
                )}

                <BusinessPlan
                  isSignup={false}
                  app={app}
                  isSelected={false}
                  i18n={i18n}
                  businessPlan={user.user?.clinic?.businessPlan}
                />
              </div>
              {user.user.type === 2 ? (
                <Box className={"additionalPlanTextContainer"}>
                  <Box className={"additionalPlanText"}>
                    <img
                      className={"additionalPlanIcon"}
                      src={"/assets/Icon_Info.svg"}
                      alt="additionalPlanIcon"
                    />
                    <Box className={"additionalPlanTextOnly"}>
                      {i18n.t("planAdditionalInfo")}
                    </Box>
                  </Box>
                  <Box
                    className={
                      "additionalPlanButtonContainer additionalPlanButtonContainerFirst"
                    }
                    onClick={() => navigate("/dashboard/price-plans")}
                  >
                    <Button
                      text={i18n.t("chooseYourPricePlan")}
                      mode={"small"}
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export default AccountSettings;
