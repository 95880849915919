import Button from "./Comon/Button/Button";
import React, { useState } from "react";
import TextInput from "./Comon/TextInput/TextInput";
import { createPatient } from "../../core/axios/services";
import { useSelector } from "react-redux";
import i18n from "i18next";

export default function CreatePatientModal({ showGlobalSnackbar }) {
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState();
  const [errors, setErrors] = useState();

  async function handleCreatePatient() {
    let result = await createPatient(formData, user);
    if (result?.status === 200) {
      showGlobalSnackbar(i18n.t("successfullyCreatedPatient"));
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setErrors(result?.response?.data?.messages);
    }
  }

  return (
    <div>
      <div
        onClick={() => setShowModal(true)}
        className={"generateSurveyContainer"}
      >
        <Button text={"Create New Patient"} />
      </div>

      {showModal && (
        <div className="steperBackdrop ">
          <div className="popupStyle createPartner">
            <h2>Create New Patient</h2>

            <TextInput
              type={"text"}
              setValue={(value) => setFormData({ ...formData, name: value })}
              label={"Name"}
              value={formData?.name}
              errorMessage={errors?.name?.[0]}
            />

            <TextInput
              type={"text"}
              setValue={(value) =>
                setFormData({ ...formData, lastname: value })
              }
              label={"Last name"}
              value={formData?.lastname}
              errorMessage={errors?.lastname?.[0]}
            />

            <TextInput
              type={"text"}
              setValue={(value) => setFormData({ ...formData, email: value })}
              label={"Email"}
              value={formData?.email}
              errorMessage={errors?.email?.[0]}
            />

            <TextInput
              type={"text"}
              setValue={(value) => setFormData({ ...formData, phone: value })}
              label={"Phone"}
              value={formData?.phone}
              errorMessage={errors?.phone?.[0]}
            />

            <div className="popupButtons">
              <div
                onClick={() => handleCreatePatient()}
                className="createButton"
              >
                <Button text={"Create"} />
              </div>

              <div
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
