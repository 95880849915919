import React, { useEffect, useState } from "react";
import "./Users.scss";
import { useSelector } from "react-redux";
import { getUsers } from "../../../../core/axios/services";
import Headline from "../../../components/Comon/Headline/Headline";
import Table from "../../../components/Admin Panel/Table/Table";
import { useTranslation } from "react-i18next";

const Users = ({ showGlobalSnackbar }) => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortDirection, setSortDirection] = useState("asc");
  const [perPage, setPerPage] = useState(0);
  const { i18n } = useTranslation();

  useEffect(() => {
    getData();
  }, [page, showAll, sortDirection]);

  useEffect(() => {
    getData();
    setShowAll(false);
    setPage(0);
    setSortDirection("asc");
  }, [user]);

  const getData = async () => {
    try {
      return await getUsers(page, showAll, sortDirection, user).then((res) => {
        setUsers(res.data.data.data);
        setTotal(res.data.data.total);
        setPerPage(parseInt(res.data.data.perPage));
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };
  return (
    <div className="Users">
      <Headline text={i18n.t("users")} type={"Headline-medium"} />
      <div className={"dataTableContainer"}>
        <div className="patientInfo">
          <Table
            app={app}
            perPage={perPage}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            type={"users"}
            i18n={i18n}
            total={total}
            setPage={setPage}
            page={page}
            data={users}
            showAll={showAll}
            setShowAll={setShowAll}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
