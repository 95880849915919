import * as types from "../types/actionTypes";

const initialState = {
  token: "",
  user: null,
  rememberMe: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        rememberMe: action.payload.rememberMe,
      };

    case types.DELETE_USER_LOGO:
      console.log(action.payload, "payload");
      return {
        ...state,
        user: action.payload.user,
      };

    case types.LOGIN:
      return {
        ...state,
        user: action.payload.user,
      };

    case types.SECRET_LOGIN:
      return {
        ...state,
        // loggedIn: true,
      };
    case types.RESTORE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        // loggedIn: true,
      };
    case types.SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
        // loggedIn: true,
      };

    case types.SET_USER_TOKEN:
      return {
        ...state,
        token: action.payload,
        // loggedIn: true,
      };

    case types.LOGOUT:
      return {
        ...state,
        token: "",
        user: null,
        rememberMe: false,
      };

    default:
      return state;
  }
};

export default reducer;
