import React, { useState } from "react";
import "./NewLanding.scss";
import { useSelector } from "react-redux";

const Benefits = () => {
  const [openedPanel, setOpenedPanel] = useState(0);
  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };
  const app = useSelector((state) => state.app);

  const rotateArrow = (item) => {
    if (openedPanel === item) {
      setOpenedPanel(0);
    } else {
      setOpenedPanel(item);
    }
  };

  const getPanelClass = (item) => {
    let panelClass = "landing-panel-body collapse ";
    if (openedPanel === item) {
      panelClass = panelClass + "show";
    }
    return panelClass;
  };

  const getArrowClass = (item) => {
    let arrowClass = "fas fa-angle-down";
    if (openedPanel === item) {
      arrowClass = arrowClass + " rotated-arrow";
    }
    return arrowClass;
  };

  return (
    <div className="benefitsWrap benefitsContainerWrapper">
      <div className="benefitsWidth">
        <div className="topContent">
          <p className="m-0">
            <span className="landing-thin-font landing-section-three-top-letters">
              <span className="landing-benefits">
                {app?.app_data?.landingData.laptop_title_bold}{" "}
              </span>{" "}
              {app?.app_data?.landingData.laptop_title_regular}
            </span>
          </p>
          <p className="m-0">{app?.app_data?.landingData.laptop_text}</p>
          <button
            className="btn bg-primary landing-bolder-font landing-section-three-button"
            onClick={() => scroll()}
          >
            {app?.app_data?.landingData.cta}
          </button>
        </div>
        <div className="bottomContent">
          <div className="leftContent">
            <img
              src={"/assets/landing/Laptop2@2x.webp"}
              className="img-fluid"
              alt="laptop2"
            />
          </div>
          <div className="rightContent">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((number) => {
              return (
                <div
                  className="landing-panel"
                  key={number}
                  onClick={() => rotateArrow(number)}
                >
                  <div
                    className="landing-panel-head accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panel-body-1"
                    aria-expanded="true"
                    aria-controls="panel-body-1"
                  >
                    <p className="landing-panel-text landing-panel-text-head landing-panel-no-body">
                      {app?.app_data?.landingData?.panel_titles[number - 1]}
                    </p>

                    <img
                      src={"/assets/landing/arrow-down.svg"}
                      alt="arrow down"
                      className={getArrowClass(number)}
                    />
                  </div>
                  <div id="panel-body-1" className={getPanelClass(number)}>
                    <p className="landing-panel-body-text">
                      {app?.app_data?.landingData?.panel_texts[number - 1]}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
