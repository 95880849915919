import React, { useEffect, useState } from "react";
import "./EditableHealthConcernsSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSurveyData,
  updateSurveyPage,
} from "../../../../../redux/actions/survey";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import { Box } from "@mui/material";
import HealthConcernText from "../../../../components/Survey/HealthConcernText/HealthConcernText";
import getThemes from "../../../../../constants/colors/getTheme";
import Button from "../../../../components/Comon/Button/Button";
import LinkText from "../../../../components/Comon/LinkText/LinkText";

const EditableHealthConcernsSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
}) => {
  const [concerns, setConcerns] = useState([]);
  const [themeColor, setThemeColor] = useState([]);

  const navigate = useNavigate();
  const { code } = useParams();
  const { surveyId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(updateSurveyPage(3));
  }, []);

  const getHealtConcernsForCurrentLanguage = () => {
    return survey?.settings?.clinic?.healthConcerns.find(
      (item) => item.language.id === survey?.settings?.language?.id
    );
  };

  const getLifeImpactForCurrentLanguage = () => {
    return survey?.settings?.clinic?.lifeImpacts.find(
      (item) => item.language.id === survey?.settings?.language?.id
    );
  };

  useEffect(() => {
    setConcerns([
      {
        name: "firstHealthConcern",
        topText: getHealtConcernsForCurrentLanguage()?.question1Subtitle,
        headline: getHealtConcernsForCurrentLanguage()?.question1Title,
        text: getHealtConcernsForCurrentLanguage()?.question1Description,
        leftOption: getHealtConcernsForCurrentLanguage()?.question1LowScale,
        rightOption: getHealtConcernsForCurrentLanguage()?.question1HighScale,
        concern:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[0]?.concern
            ? survey.data.healthConcerns[0].concern
            : "",
        concernRate:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[0]?.concernRate
            ? survey.data.healthConcerns[0].concernRate
            : 0,
        mandatory: true,
        characterLimit: 120,
      },
      {
        name: "secondHealthConcern",
        topText: getHealtConcernsForCurrentLanguage()?.question2Subtitle,
        headline: getHealtConcernsForCurrentLanguage()?.question2Title,
        text: getHealtConcernsForCurrentLanguage()?.question2Description,
        leftOption: getHealtConcernsForCurrentLanguage()?.question2LowScale,
        rightOption: getHealtConcernsForCurrentLanguage()?.question2HighScale,
        concern:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[1]?.concern
            ? survey.data.healthConcerns[1].concern
            : "",
        concernRate:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[1]?.concernRate
            ? survey.data.healthConcerns[1].concernRate
            : 0,
        mandatory: false,
        characterLimit: 120,
      },
      {
        name: "thirdHealthConcern",
        topText: getHealtConcernsForCurrentLanguage()?.question3Subtitle,
        headline: getHealtConcernsForCurrentLanguage()?.question3Title,
        text: getHealtConcernsForCurrentLanguage()?.question3Description,
        leftOption: getHealtConcernsForCurrentLanguage()?.question3LowScale,
        rightOption: getHealtConcernsForCurrentLanguage()?.question3HighScale,
        concern:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[2]?.concern
            ? survey.data.healthConcerns[2].concern
            : "",
        concernRate:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[2]?.concernRate
            ? survey.data.healthConcerns[2].concernRate
            : 0,
        mandatory: false,
        characterLimit: 120,
      },
      {
        name: "fourthHealthConcern",
        topText: getLifeImpactForCurrentLanguage()?.question1Subtitle,
        headline: getLifeImpactForCurrentLanguage()?.question1Title,
        text: getLifeImpactForCurrentLanguage()?.question1Description,
        leftOption: getLifeImpactForCurrentLanguage()?.question1LowScale,
        rightOption: getLifeImpactForCurrentLanguage()?.question1HighScale,
        concern:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[3]?.concern
            ? survey.data.healthConcerns[3].concern
            : "",
        concernRate:
          survey.data &&
          survey.data.healthConcerns &&
          survey.data?.healthConcerns[3]?.concernRate
            ? survey.data.healthConcerns[3].concernRate
            : 0,
        mandatory: false,
        characterLimit: 120,
      },
    ]);
  }, [survey]);

  useEffect(() => {
    setThemeColor(getThemes("mainColor", app.appTheme));
  }, [app]);

  const nextPage = () => {
    if (concerns[0].concern.trim() === "" || concerns[0].concernRate === 0) {
      scrollTo();
      showGlobalSnackbar(i18n.t("pleaseCompleteFirstConcern"), 2000);

      return;
    }

    if (
      (concerns[1].concern.trim() !== "" && concerns[1].concernRate === 0) ||
      (concerns[1].concern.trim() === "" && concerns[1].concernRate !== 0)
    ) {
      scrollTo();
      showGlobalSnackbar(i18n.t("pleaseCompleteConcerns"), 2000);

      return;
    }

    if (
      (concerns[2].concern.trim() !== "" && concerns[2].concernRate === 0) ||
      (concerns[2].concern.trim() === "" && concerns[2].concernRate !== 0)
    ) {
      scrollTo();
      showGlobalSnackbar(i18n.t("pleaseCompleteConcerns"), 2000);

      return;
    }

    if (
      (concerns[3].concern.trim() !== "" && concerns[3].concernRate === 0) ||
      (concerns[3].concern.trim() === "" && concerns[3].concernRate !== 0)
    ) {
      scrollTo();
      showGlobalSnackbar(i18n.t("pleaseCompleteConcerns"), 2000);

      return;
    }

    dispatch(updateSurveyPage(4));
    navigate("/survey-edit/" + code + "/" + surveyId + "/global-improvements");
  };

  const previousPage = () => {
    if (survey.settings.type === 1 || survey.settings.type === 3) {
      //basic
      dispatch(updateSurveyPage(1));
      navigate("/survey-edit/" + code + "/" + surveyId + "/patient-profile");
    } else if (survey.settings.type === 2) {
      // advanced
      dispatch(updateSurveyPage(2));
      navigate("/survey-edit/" + code + "/" + surveyId + "/health-stresses");
    }
  };

  const updateConcern = (index, value, type) => {
    if (type === "text") {
      concerns[index].concern = value;
    } else {
      concerns[index].concernRate = value;
    }

    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { healthConcerns: concerns };
    } else {
      survey.data.healthConcerns = concerns;
    }
    dispatch(updateSurveyData(survey.data));
  };

  const scrollTo = () => {
    const element = document.getElementById(concerns[0].name);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="ThirdScreenSurvey">
      <SurveyHero
        // step={i18n.t("step") + " 2"}
        headline={getHealtConcernsForCurrentLanguage()?.title}
        text={getHealtConcernsForCurrentLanguage()?.desc}
        videoLink={getHealtConcernsForCurrentLanguage()?.videoUrl}
        buttonText={i18n.t("startSurvey")}
        clickedButton={() => scrollTo()}
        app={app}
        surveyType={survey?.settings?.type}
      />

      <div className={"concernsContainer"}>
        {concerns.map((concern, index) => {
          return (
            <div className={"concernContainer"} key={index}>
              <HealthConcernText
                setConcernText={(value) => updateConcern(index, value, "text")}
                setConcernRate={(value) => updateConcern(index, value, "rate")}
                mandatory={concern.mandatory}
                text={concern.text}
                concernRate={concern.concernRate}
                topText={concern.topText}
                headline={concern.headline}
                themeColor={themeColor}
                hasTextInput={true}
                leftOption={concern.leftOption}
                rightOption={concern.rightOption}
                i18n={i18n}
                id={concern.name}
                concernText={concern.concern}
                characterLimit={concern.characterLimit}
              />
            </div>
          );
        })}
      </div>
      <div className={"buttonsContainer"}>
        <div className={"nextContainer"} onClick={() => nextPage()}>
          <Button color={themeColor} mode={"small"} text={i18n.t("nextStep")} />
        </div>
        <div className={"previousContainer"} onClick={() => previousPage()}>
          <LinkText text={i18n.t("previousStep")} />
        </div>
      </div>
    </div>
  );
};

export default EditableHealthConcernsSurvey;
