import "./NewLanding.scss";
import { useSelector } from "react-redux";

const Grow = () => {
  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };
  const app = useSelector((state) => state.app);

  return (
    <div className="growWrapp growContainerWrapper">
      <div className="growContent">
        <div className="topContent">
          <p className="landing-thin-font landing-section-four-top-text">
            {app?.app_data?.landingData.s3_step_title_regular}
            <span className="landing-chiro-span">
              {" "}
              {app?.app_data?.landingData.s3_step_title_bold}
            </span>
          </p>
        </div>

        <div className="middleContent">
          <div>
            <p className="landing-section-four-circule">1</p>
            <p className="landing-section-four-title" onClick={() => scroll()}>
              {app?.app_data?.landingData.s3_step_1_title}
            </p>
            <p className="landing-section-four-body">
              {app?.app_data?.landingData.s3_step_1_text}
            </p>
          </div>
          <div>
            <p className="landing-section-four-circule">2</p>

            <p className="landing-section-four-title">
              {app?.app_data?.landingData.s3_step_2_title}
            </p>

            <p className="landing-section-four-body">
              {app?.app_data?.landingData.s3_step_2_text}
            </p>
          </div>
          <div>
            <p className="landing-section-four-circule">3</p>

            <p className="landing-section-four-title">
              {app?.app_data?.landingData.s3_step_3_title}
            </p>

            <p className="landing-section-four-body">
              {app?.app_data?.landingData.s3_step_3_text}
            </p>
          </div>
        </div>

        <div className="bottomContent">
          <button
            className="btn bg-primary landing-bolder-font landing-section-one-button"
            onClick={() => scroll()}
          >
            {app?.app_data?.landingData.cta}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Grow;
