import React, { useState, useEffect } from "react";
import "./Otp.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDigitInput from "react-digit-input";
import {
  appData,
  authOtp,
  authResendVerify,
} from "../../../../core/axios/services";
import { Snackbar } from "@mui/material";
import Logo from "../../../components/Comon/Logo/Logo";

const Otp = ({ showGlobalSnackbar, sendAgain }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const storedEmail = localStorage.getItem("signUpEmail");
  const [email, setEmail] = useState(
    storedEmail ? storedEmail : localStorage.getItem("loginEmail")
  );

  // const [email, setEmail] = useState(localStorage.getItem("signUpEmail"));
  const [value, onChange] = React.useState("");

  const [logo, setLogo] = useState("/assets/logotm.png");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 5,
    value,
    onChange,
  });

  useEffect(() => {
    collectAppData().then((data) => {
      // setLogo(data.logo);
    });

    if (sendAgain === true) {
      handleResend();
    }
  }, []);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const handleSubmit = async () => {
    try {
      return await authOtp(email, value).then((res) => {
        console.log(email, value);
        navigate("/details");
      });
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      showGlobalSnackbar(message);
    }
  };

  const handleResend = async () => {
    try {
      return await authResendVerify(email).then((res) => {
        showGlobalSnackbar("Code resent to email");
      });
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      showGlobalSnackbar(message);
    }
  };

  const handleCancel = () => {
    navigate("/signup");
  };

  return (
    <div className="detailsWrapper">
      <Logo className="logo" src={logo} />

      <div className="otpWrapper">
        <div className="otpContent">
          <h2>{t("pleaseCheckEmail")}</h2>

          <p className="paragraph">
            {t("codeSendTo")} <strong>{email}</strong>
          </p>

          <div className="otpFields">
            <input inputMode="decimal" autoFocus {...digits[0]} />
            <input inputMode="decimal" {...digits[1]} />
            <input inputMode="decimal" {...digits[2]} />
            <input inputMode="decimal" {...digits[3]} />
            <input inputMode="decimal" {...digits[4]} />
          </div>

          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            hidden
          />

          <div className="resendCode">
            {t("didntGetCode")}
            <button onClick={handleResend}>{t("resendClick")}</button>
          </div>

          <div className="verifyButton">
            <button className="verifySubmit" onClick={handleSubmit}>
              {t("verifyAcc")}
            </button>
            <button className="verifyCancel" onClick={handleCancel}>
              {t("denySurveyDeleteButton")}
            </button>
          </div>
        </div>
      </div>

      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        message={snackMessage}
      />
    </div>
  );
};

export default Otp;
