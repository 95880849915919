import { themes } from "..";
export default (property, theme = "default") => {
  try {
    let themeProperty = null;
    if (theme === "default" || theme === "defaultTheme") {
      themeProperty = themes["defaultTheme"][property];
    } else {
      themeProperty = themes["theme"+theme][property];
    }
    const fallbackProperty = themes.defaultTheme[property];
    return themeProperty || fallbackProperty;
  } catch (error) {
    console.log(error, "themeIs   " + theme, " propertyIs:   " + property);
  }
};
