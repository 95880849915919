import React, { useState, useEffect } from "react";
import "./ForgotPassword.scss";
import { appData, authForgotPassword } from "../../../../core/axios/services";

// Components
import TextInputLabel from "../../../components/Comon/TextInput/TextInput";
import Logo from "../../../components/Comon/Logo/Logo";
import { Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ showGlobalSnackbar }) => {
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailSendTo, setEmailSendTo] = useState("");

  useEffect(() => {
    collectAppData().then((data) => {
      // setLogo(data.logo);
    });
  }, []);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const handleSubmit = async () => {
    if (email === "") {
      const message = i18n.t("pleaseCheckEmail");
      showGlobalSnackbar(message);
      return;
    }
    try {
      return await authForgotPassword(email).then((res) => {
        setCheckEmail(true);
        setEmailSendTo(localStorage.setItem("email", email));
      });
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      showGlobalSnackbar(message);
    }
  };

  const handleCancel = () => {
    navigate("/login");
  };

  return (
    <div className="forgotPasswordWrapper">
      <Logo className="logo" src={logo} />

      <div className="otpWrapper">
        <div className="otpContent">
          {checkEmail ? (
            <div>
              <h2>{t("checkEmail")}</h2>
              <p>
                {t("passwordResetLink")} <strong>{email}</strong>
              </p>
            </div>
          ) : (
            <div>
              <h2>{t("passwordResetTitle")}</h2>
              <p>{t("resetInstructions")}</p>
            </div>
          )}

          {checkEmail ? (
            ""
          ) : (
            <div className="fieldsWrapper">
              <TextInputLabel
                type={"email"}
                label={i18n.t("emailAddress")}
                value={email}
                setValue={setEmail}
                placeholder={t("enterYourEmail")}
              />
              <div className="verifyButton">
                <button className="verifySubmit" onClick={handleSubmit}>
                  {t("sendReset")}
                </button>
                <button className="cancelForm" onClick={handleCancel}>
                  {t("cancelPasswordReset")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        message={snackMessage}
      />
    </div>
  );
};

export default ForgotPassword;
