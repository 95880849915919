import React from "react";

const NumberRating = ({ rating }) => {
  const maxRating = 10; // Change this based on your rating scale
  const roundedRating = Math.round(rating);

  const renderNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= maxRating; i++) {
      const isFilled = i <= roundedRating;
      const classNames = isFilled
        ? `${i === roundedRating ? "filled" : ""}`
        : "empty";
      numbers.push(
        <span key={i} className={classNames}>
          {i}
        </span>
      );
    }
    return numbers;
  };

  return <div className="ratingStats">{renderNumbers()}</div>;
};

export default NumberRating;
