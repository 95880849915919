import React from "react";
import "./Logo.scss";

const Logo = ({ src }) => {
  return (
    <div className="Logo">
      <img
        src={src}
        key={src}
        alt="companyLogo"
        className={src === "" ? "invisibleLogo" : "companyLogo"}
      />
    </div>
  );
};

export default Logo;
