import React, { useState } from "react";
import Headline from "../../../components/Comon/Headline/Headline";
import { useTranslation } from "react-i18next";
import "./Practitioner.scss";
import { Box } from "@mui/material";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Button from "../../../components/Comon/Button/Button";
import { newPractitioner } from "../../../../core/axios/services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Practitioner = ({ showGlobalSnackbar }) => {
  const { i18n } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const addPractitioner = async () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
    };

    try {
      await newPractitioner(user, data).then((res) => {
        navigate(`/dashboard/practitioner/${res.data.id}`);
      });
    } catch (error) {
      if (error.response.status == 310) {
        showGlobalSnackbar(error.response.data.data.message);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  return (
    <div className="practitionerWrapper">
      <Headline
        text={i18n.t("addNewPractitioners")}
        type={"Headline-big-left"}
      />
      <Box className={"sectionChange"}>
        <Headline
          text={i18n.t("practitionerOveriview")}
          type={"Headline-medium"}
        />

        <Box className={"textInputContainer"}>
          <Box className={"inputRow"}>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                label={i18n.t("firstName")}
                placeholder={i18n.t("enterPractitionerName")}
                setValue={setFirstName}
                value={firstName}
                mode={"grey"}
              />
            </Box>
            <Box className={"smallTextInput"}>
              <TextInput
                sx={{ marginLeft: "24px" }}
                type={"text"}
                label={i18n.t("lastName")}
                placeholder={i18n.t("enterPractitionerLastName")}
                setValue={setLastName}
                value={lastName}
                mode={"grey"}
              />
            </Box>
          </Box>
          <Box className={"inputRow"}>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                label={i18n.t("email")}
                mode={"grey"}
                setValue={setEmail}
                value={email}
                placeholder={i18n.t("enterPractitionerEmail")}
              />
            </Box>
            <Box className={"smallTextInput"}>
              <TextInput
                sx={{ marginLeft: "24px" }}
                type={"number"}
                label={i18n.t("phoneOptional")}
                placeholder={i18n.t("enterPractitionerPhone")}
                mode={"grey"}
                setValue={setPhone}
                value={phone}
              />
            </Box>
          </Box>

          <Box className={"buttonContainer"} onClick={addPractitioner}>
            <Button text={i18n.t("addPractitioner")} mode={"small"} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Practitioner;
