import React, { useEffect, useState } from "react";
import "./BarChart.scss";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

const BarChart = ({ values, property, id, surveyOptions }) => {
  const { i18n } = useTranslation();
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    values = values.filter((v) => getValue(v) > 0);
    setStatistics(values);
  }, [values]);

  const calculateBottom = (index) => {
    if (isOdd(index)) {
      return "calc(175px - (" + (index + 1) * 15 + "px + 32px) )";
    } else {
      return "calc(175px - 32px )";
    }
  };

  const isOdd = (num) => {
    return num % 2;
  };

  const calculateHeight = (index) => {
    return "calc(" + (index + 1) * 15 + "px + 32px)";
  };

  const calculateWidth = (index, value) => {
    return "calc(" + getValue(value) + "% - 2px)";
  };

  const getValue = (value) => {
    let correctValue = null;
    if (id === 1) {
      correctValue = value.neck;
    } else if (id === 2) {
      correctValue = value.midBack;
    } else if (id === 3) {
      correctValue = value.energyLevels;
    } else if (id === 4) {
      correctValue = value.abilityStress;
    } else if (id === 5) {
      correctValue = value.sleepQuality;
    } else if (id === 6) {
      correctValue = value.immuneSystem;
    } else if (id === 7) {
      correctValue = value.mentalState;
    } else if (id === 8) {
      correctValue = value.lowerBack;
    } else if (id === 9) {
      correctValue = value.posture;
    } else if (id === 10) {
      correctValue = value.emotionalState;
    }

    return correctValue;
  };

  const getIndexValue = (index) => {
    const filteredStatistics = statistics.filter((s) => s.visible !== false);

    let correctValue = null;
    if (filteredStatistics[index]) {
      if (id === 1) {
        correctValue = filteredStatistics[index].neck;
      } else if (id === 2) {
        correctValue = filteredStatistics[index].midBack;
      } else if (id === 3) {
        correctValue = filteredStatistics[index].energyLevels;
      } else if (id === 4) {
        correctValue = filteredStatistics[index].abilityStress;
      } else if (id === 5) {
        correctValue = filteredStatistics[index].sleepQuality;
      } else if (id === 6) {
        correctValue = filteredStatistics[index].immuneSystem;
      } else if (id === 7) {
        correctValue = filteredStatistics[index].mentalState;
      } else if (id === 8) {
        correctValue = filteredStatistics[index].lowerBack;
      } else if (id === 9) {
        correctValue = filteredStatistics[index].posture;
      } else if (id === 10) {
        correctValue = filteredStatistics[index].emotionalState;
      }
    }

    return correctValue;
  };

  const getImprovement = (index) => {
    const filteredStatistics = statistics.filter((s) => s.visible !== false);

    if (
      filteredStatistics &&
      filteredStatistics[index] &&
      filteredStatistics[index - 1]
    ) {
      let firstValue = getIndexValue(0);
      let secondValue = getIndexValue(index);

      let calculation = 0;
      //is decrease
      if (secondValue < firstValue) {
        calculation = ((secondValue - firstValue) / firstValue) * 100;
      } else {
        calculation = ((secondValue - firstValue) / (100 - firstValue)) * 100;
      }

      if (isNaN(calculation)) {
        return 0;
      }
      return Math.round(calculation);
    } else {
      return 0;
    }
  };

  const getOverallImprovement = () => {
    if (!statistics) {
      return 0;
    }

    let total = 0;

    if (statistics) {
      statistics.forEach((v, index) => {
        if (v.visible !== false) {
          let firstValue = getIndexValue(0);
          let secondValue = getIndexValue(index);

          //is decrease
          if (secondValue < firstValue) {
            total = ((secondValue - firstValue) / firstValue) * 100;
          } else {
            total = ((secondValue - firstValue) / (100 - firstValue)) * 100;
          }
        }
      });
    }
    if (isNaN(total)) {
      total = 0;
    }
    return Math.round(total);
  };

  const getTitle = () => {
    return id <= 2
      ? i18n.t("spinalMovement") + ": " + i18n.t(property)
      : i18n.t(property);
  };

  const onCheckboxClick = (index) => {
    surveyOptions[index].selected = !surveyOptions[index].selected;
    updateStatisticsAccordingToOptions();
  };

  const updateStatisticsAccordingToOptions = () => {
    surveyOptions.forEach((o) => {
      const statisticIndex = statistics.findIndex((s) => {
        return s.surveyOrder === o.id;
      });
      if (o.selected === false) {
        if (statisticIndex !== -1) {
          statistics[statisticIndex].visible = false;
        }
      } else {
        if (statisticIndex !== -1) {
          statistics[statisticIndex].visible = true;
        }
      }
    });
    setStatistics([...statistics]);
  };

  return (
    <div className={"globalBarChart"}>
      <div className={"BarChartTitle"}>{getTitle()}</div>
      <div className={"autoSelectContainer"}>
        <div className={"autoSelectTitle"}>{i18n.t("chooseStatistics")}</div>
        <div className={"autoSelectBox"}>
          {surveyOptions.map((s, index) => {
            return (
              <div className={"checkboxContainerBox"} key={index}>
                <CustomCheckbox
                  value={s.selected}
                  setValue={() => onCheckboxClick(index)}
                />
                <div
                  className={"checkboxCustomLabel"}
                  onClick={() => onCheckboxClick(index)}
                >
                  {" "}
                  {i18n.t("survey")} {s.id}{" "}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="BarChart">
        <div className={"BarChartLegend"}>
          {statistics?.map((value, index) => {
            return (
              <div
                key={index}
                className={"BarChartLegendItem"}
                style={
                  value.visible === false
                    ? {
                        visibility: "hidden",
                        width: calculateWidth(index, value),
                        height: calculateHeight(index),
                        bottom: calculateBottom(index),
                      }
                    : {
                        width: calculateWidth(index, value),
                        height: calculateHeight(index),
                        bottom: calculateBottom(index),
                      }
                }
              >
                <div className={"BarChartLegendValue"}>
                  <div className={"percentageOnly"}>
                    {" "}
                    {Math.round(getValue(value))}{" "}
                  </div>
                  <div className={"valueTitle"}>
                    {" "}
                    {i18n.t("survey")} {value.surveyOrder}
                  </div>
                  {index > 0 ? (
                    <div className={"valueSubtitle"}>
                      {" "}
                      {getImprovement(value.surveyOrder)}%{" "}
                      {i18n.t("improvement")}{" "}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        <div className={"BarChartContainer"}>
          {statistics?.map((value, index) => {
            return (
              <div
                key={index}
                className={"BarChartItem"}
                style={
                  value.visible === false
                    ? { width: getValue(value) + "%", visibility: "hidden" }
                    : { width: getValue(value) + "%" }
                }
              ></div>
            );
          })}
        </div>
        <div className={"overallImprovement"}>
          <div className={"overallImprovementNumber"}>
            {getOverallImprovement()}%
          </div>
          <div className={"overallImprovementText"}>
            {i18n.t("overallImprovement")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
