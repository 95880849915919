import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Login.scss";
import getThemes from "../../../../constants/colors/getTheme";
import Headline from "../../../components/Comon/Headline/Headline";
import TextInputLabel from "../../../components/Comon/TextInput/TextInput";
import CustomCheckbox from "../../../components/Admin Panel/CustomCheckbox/CustomCheckbox";
import { Box, Snackbar } from "@mui/material";
import LinkText from "../../../components/Comon/LinkText/LinkText";
import Button from "../../../components/Comon/Button/Button";
import axios from "axios";
import { API } from "../../../../constants/services/base";
import { getAppData } from "../../../../redux/actions/app";
import { addUserToken } from "../../../../redux/actions/user";
import Logo from "../../../components/Comon/Logo/Logo";
import * as Services from "../../../../core/axios/services";
import { appData, authLogin } from "../../../../core/axios/services";

const Login = ({ showGlobalSnackbar }) => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [authContainerStyle, setAuthContainerStyle] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    collectAppData().then((data) => {
      dispatch(getAppData(data));
      //   setLogo(data.logo);
    });
    setAuthContainerStyle({
      width: "832px",
      //   height: "558px",
      height: "620px",
      backgroundColor: getThemes("secondaryColor", app.appTheme),
      borderRadius: "27px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    });
    i18n.changeLanguage(app.locale);
    if (user.token !== "") {
      navigate("/dashboard");
    }
  }, []);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const login = async () => {
    // check if email is correct format
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      showGlobalSnackbar(i18n.t("emailFormat"));
      return;
    }

    if (password === null || password === "") {
      showGlobalSnackbar(i18n.t("insertPassword"));
      return;
    }

    try {
      return await authLogin(email, password, 2, user).then((res) => {
        dispatch(addUserToken(res.data.data, rememberMe));
        localStorage.setItem("loginEmail", email);

        if (
          res.data.data.user.clinic.paymentExcluded &&
          res.data.data.user.clinic.businessPlan.name === "Basic" &&
          res.data.data.user.clinic.languages.length > 1
        ) {
          navigate("/dashboard-paid");
        } else {
          navigate("/dashboard");
        }
      });
    } catch (error) {
      let message = i18n.t("thereWasAnError");
      if (error.response.status === 422 || error.response.status === 401) {
        message = error.response.data.errorMessage;
      }
      showGlobalSnackbar(message);
    }
  };

  return (
    <div className={"Login"}>
      <div className={"loginLogo"}>
        <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
          <Logo src={logo} />
        </div>
      </div>
      <div style={authContainerStyle} className={"authContainer"}>
        <div className={"innerAuthContainer"}>
          <div className={"headline-login"}>
            <Headline text={i18n.t("welcomeBack")} type={"Headline-big"} />
          </div>
          <TextInputLabel
            type={"email"}
            label={i18n.t("emailAddress")}
            value={email}
            setValue={setEmail}
          />
          <div className="divider" />
          <TextInputLabel
            type={"password"}
            label={i18n.t("password")}
            value={password}
            setValue={setPassword}
            onKeyDownEvent={() => login()}
          />
          <Box className="boxes">
            <CustomCheckbox
              text={i18n.t("keepMeLoggedIn")}
              value={rememberMe}
              setValue={setRememberMe}
            />
            <div className={"linkTextContainer"}>
              <a href={"/forgot-password"}>{i18n.t("forgotPassword")}</a>
            </div>
          </Box>
          {/* <Box className="privacyTerms">
            {i18n.t("readPolicy")}{" "}
            <a
              href="https://stage.chiropracticoutcomes.com/privacy"
              target="_blank"
            >
              {i18n.t("privacypolicy")}
            </a>{" "}
            {i18n.t("and")}{" "}
            <a href="https://stage.chiropracticoutcomes.com/terms">
              {" "}
              {i18n.t("terms")}
            </a>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div className={"loginButton"} onClick={login}>
              <Button text={i18n.t("login")} />
            </div>
            {/* <div className={"buttonContainer"} onClick={login}>
              <Button text={i18n.t("login")} />
            </div> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div className={"signUpTextContainer"}>
              <a href={"/signup"}>{i18n.t("dontHaveAccount")}</a>
            </div>
          </Box>
        </div>
      </div>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={2000}
        message={snackMessage}
      />
    </div>
  );
};

export default Login;
