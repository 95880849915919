import { defaultTheme, blue, black, green, orange, turquoise } from "./colors/Themes";
const themes = {
  defaultTheme: { ...defaultTheme },
  theme1: { ...blue },
  theme2: { ...green },
  theme3: { ...orange },
  theme4: {...black},
  theme5: {...turquoise}
};

export { themes };
