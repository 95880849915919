import "./NewLanding.scss";
import { useSelector } from "react-redux";

const Baner = () => {
  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };
  const app = useSelector((state) => state.app);

  return (
    <div className="row vh-100 p-0 landing-section landing-section-one banerWrapperSection">
      <div className="landing-section-one-left">
        <div className="">
          <h2 className="landing-thiner-font-tall landing-big-letters-section-one">
            <span className="landing-matters">
              {" "}
              {app?.app_data?.landingData.title_bold}{" "}
            </span>{" "}
            {app?.app_data?.landingData.title_regular}
            {/* {landingData?.title_regular} */}
          </h2>
        </div>
        <div className="">
          <p className="landing-small-letters-section-one landing-basic-font">
            {app?.app_data?.landingData.subtitle}
            {/* {landingData?.subtitle} */}
          </p>
        </div>

        <div className="">
          <button
            className="btn bg-primary landing-bolder-font landing-section-one-button"
            onClick={() => scroll()}
          >
            {app?.app_data?.landingData.cta}
            {/* {landingData?.cta} */}
          </button>
        </div>
      </div>
      <div className="col landing-section-one-right p-0">
        <img
          src={"/assets/landing/new_mockup1.webp"}
          className="img-fluid landing-herephoto"
          alt="herophoto"
        />
        <div className="glow" />
      </div>
    </div>
  );
};

export default Baner;
