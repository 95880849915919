import Button from "./Comon/Button/Button";
import React, { useState } from "react";
import TextInput from "./Comon/TextInput/TextInput";
import Headline from "./Comon/Headline/Headline";
import Dropdown from "./Admin Panel/Dropdown/Dropdown";
import { useSelector } from "react-redux";
import i18n from "i18next";
import {getSurveyLinkForLanguageAndType, setSurveyDefaultsForPracticeHubPatient} from "../../core/axios/services";

export default function SetSurveyDefaultsForPracticeHubPatientModal({
  disabled = false,
  patient,
  showGlobalSnackbar,
  setLink,
  data,
  setData,
}) {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    language:user.user.clinic.languages?.find(item => item?.id === patient?.practiceHubSettings?.survey_language),
    type:getSurveyTypeOptions()?.find(item => item?.id === patient?.practiceHubSettings?.survey_type),
  });



  const [errors, setErrors] = useState();

  async function setPracticeHubDefaults() {
    let result = await setSurveyDefaultsForPracticeHubPatient(formData, patient, user);


    if (result?.status === 200) {
      setErrors(null);
      let link = result?.data?.data?.surveyLink;
      navigator.clipboard.writeText(link);
      setLink(link);
      data[0].generated = true;
      setData([...data]);

      showGlobalSnackbar(i18n.t("linkCopied"));
      setShowModal(false);
    } else {
      setErrors(result?.response?.data?.messages);
    }
  }

  function getSurveyTypeOptions() {
    if (user.user.clinic.businessPlan.id === 1) {
      // basic
      return [{ id: 1, name: "ChiroForm" }];
    }

    return [
      { id: 1, name: "ChiroForm" },
      {
        id: 2,
        name: "ChiroForm +",
      },
      {
        id: 3,
        name: "ChiroForm (CP)",
      },
    ];
  }

  return (
    <div>
      <div
        className={"generateSurveyContainer"}
        onClick={() => (!disabled ? setShowModal(true) : null)}
      >
        <Button
          disabled={disabled}
          text={i18n.t("generateSurvey")}
          mode={"small"}
        />
      </div>

      {showModal && (
        <div className="steperBackdrop">
          <div className="popupStyle createPartner">
            <h2>
              Set survey defaults for <br />{" "}
              <strong>{patient?.name}</strong>
            </h2>
            <p style={{
              textAlign:'center',
              maxWidth:'400px',
            }}>
              These are the default settings for automated appointment survey emails for Practice Hub patients.
            </p>

            {user?.user?.clinic?.businessPlan?.id !== 1 ? (
              <div className={"settingSection"}>
                <Headline
                  text={i18n.t("surveyLanguage")}
                  type={"Headline-medium"}
                />
                <div className={"dropdownContainer"} style={{ margin: "0" }}>
                  <Dropdown
                    type={"language"}
                    setSelectedOption={(opt) =>
                      setFormData({ ...formData, language: opt })
                    }
                    selectedOption={formData?.language}
                    options={user.user.clinic.languages}
                    app={app}
                    placeholder={i18n.t("surveyLanguage")}
                  />
                  {(errors?.language?.[0] || errors?.["language.id"]?.[0]) && (
                    <div className={"errorMessage"}>
                      {" "}
                      {errors?.language?.[0] ??
                        errors?.["language.id"]?.[0] ??
                        null}{" "}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={"settingSection"}>
              <Headline
                text={i18n.t("typeOfSurvey")}
                type={"Headline-medium"}
              />
              <div className={"dropdownContainer"} style={{ margin: "0" }}>
                <Dropdown
                  type={"settingType"}
                  setSelectedOption={(opt) =>
                    setFormData({ ...formData, type: opt })
                  }
                  selectedOption={formData?.type?.id}
                  options={getSurveyTypeOptions()}
                  app={app}
                  placeholder={i18n.t("typeOfSurvey")}
                  style={{ margin: "0" }}
                />
                {(errors?.type?.[0] || errors?.["type.id"]?.[0]) && (
                  <div className={"errorMessage"}>
                    {" "}
                    {errors?.type?.[0] ?? errors?.["type.id"]?.[0] ?? null}{" "}
                  </div>
                )}
              </div>
            </div>

            <div className="popupButtons">
              <div onClick={() => setPracticeHubDefaults()} className="createButton">
                <Button text={"Save & Copy Link"} />
              </div>

              <div
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
