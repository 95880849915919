import React, {useEffect, useState} from "react";
import Profile from "./Profile.js";
import Statistics from "./Statistics.js";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box} from "@mui/material";
import TabLine from "../../../components/Admin Panel/TabLine/TabLine";
import Tab from "../../../components/Admin Panel/Tab/Tab";
import {useParams} from "react-router-dom";
import {getPractitioner} from "../../../../core/axios/services";

const PractitionersProfile = ({showGlobalSnackbar}) => {
    const app = useSelector((state) => state.app);
    const [page, setPage] = useState(0);
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const [practitioner, setPractitioner] = useState(null);
    const user = useSelector((state) => state.user);

    useEffect( () => {
        try {
             getPractitioner(user, id).then((res) => {
                setPractitioner(res.data);
            })
        } catch (error) {
            showGlobalSnackbar(i18n.t("thereWasAnError"));

        }
    }, [id]);

    return (
        <div className="practitionersProfileWrap">
            <Box className={"tabContainer"}>
                <Tab
                    text={i18n.t("practitionerProfile")}
                    isActive={page === 0}
                    page={0}
                    setPage={setPage}
                />
                <Tab
                    text={i18n.t("practitionerStat")}
                    isActive={page === 1}
                    page={1}
                    setPage={setPage}
                />
            </Box>
            <Box className={"tabLineContainer"}>
                <TabLine total={2} step={page} app={app}/>
            </Box>

            {page === 0 ? (
                <Profile
                    showGlobalSnackbar={showGlobalSnackbar}
                    app={app}
                    user={user}
                    practitioner={practitioner}
                    setPractitioner={setPractitioner}
                    i18n={i18n}
                />
            ) : (
                <Statistics
                    showGlobalSnackbar={showGlobalSnackbar}
                    app={app}
                    user={user}
                    practitioner={practitioner}
                    i18n={i18n}
                />
            )}
        </div>
    );
};

export default PractitionersProfile;
