import "./SplashScreen.scss";

const Loading = () => {
    return (
        <div className={"splash-container"}>
        <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </div>
    )
}
export default Loading;