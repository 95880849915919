import React from 'react';

const NotFound = () => {
  return (
      <div className="Boilerplate">
        Link doesn't exist
      </div>
  );
};

export default NotFound;
