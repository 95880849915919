import React, { useEffect, useState } from "react";
import "./UploadFile.scss";
import getThemes from "../../../../constants/colors/getTheme";
import { Box } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";

const UploadFile = ({ app, i18n }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    setStyle({
      borderColor: getThemes("mainColor", app.appTheme),
      backgroundColor: getThemes("thirdColor", app.appTheme),
    });
  }, [app]);

  return (
    <div className="UploadFile" style={style}>
      <img
        className={"uploadImage"}
        src={"/assets/Icon_Attachment.svg"}
        alt="uploadImage"
      />
      <Box className={"UploadFileText"}>{i18n.t("attachmentDescription")}</Box>
    </div>
  );
};

export default UploadFile;
