import React, {useEffect} from 'react';
import './SurveyCheckboxOption.scss';
import CustomCheckbox from "../../Admin Panel/CustomCheckbox/CustomCheckbox";
import {Input} from "@mui/material";
import {useTranslation} from "react-i18next";

const SurveyCheckboxOption = ({text, setText, selected, setSelected, isOther}) => {
    const {i18n} = useTranslation();
    let otherInput = null;

    const handleChange = (text) => {
        if (text.length > 0) {
            setSelected(true);
        }
        setText(text);
    }

    const handleFocus = (event) => event.target.select();

    useEffect(() => {
        console.log("selected:   ", selected,"  isOther:  ", isOther);
        if (selected && isOther) {
            otherInput.children[0].focus();
            if (text === i18n.t("other")) {
                setText("");
            }
        } else if (selected === false && isOther) {
            if (text === "")
            setText(i18n.t("other"));
        }
    }, [selected]);



    return (
      <div className="SurveyCheckboxOption" onClick={(e) => setSelected(!selected)}>
        <CustomCheckbox text={''} value={selected} setValue={setSelected} />
          {isOther ?
              <Input  ref={(input) => otherInput = input}
                               className={"surveyCheckboxInput"} type={text} onFocus={handleFocus} value={text} onChange={e => handleChange(e.target.value)}/>
              :
              <div className={"surveyCheckboxText"}> {text} </div>
          }
      </div>
  );
};

export default SurveyCheckboxOption;
