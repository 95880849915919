import React, { useEffect, useState } from "react";
import "./Statistics.scss";
import {
  getAllPractitioners, getMyUser,
  getStatistics,
} from "../../../../core/axios/services";
import { useSelector } from "react-redux";
import BarChart from "../../../components/Admin Panel/BarChart/BarChart";
import Headline from "../../../components/Comon/Headline/Headline";
import { Box, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../../../components/Admin Panel/CustomCheckbox/CustomCheckbox";
import Dropdown from "../../../components/Admin Panel/Dropdown/Dropdown";
import XYDiagram from "../../../components/Admin Panel/XYDiagram/XYDiagram";
import practitioner from "../Practitioner/Practitioner";
// import { Dna } from "react-loader-spinner";

const Statistics = (showGlobalSnackbar) => {
  let [statistics, setStatistics] = useState([]);
  const user = useSelector((state) => state.user);
  const [items, setItems] = useState([]);
  const { i18n } = useTranslation();
  const [practitioners, setPractitioners] = useState([]);
  const [show, setShow] = useState(true);

  const diagramTypes = [
    { id: 1, name: i18n.t("xDiagram") },
    { id: 2, name: i18n.t("xyDiagram") },
  ];
  const [selectedDiagram, setSelectedDiagram] = useState(diagramTypes[1]);
  const [selectedPractitioner, setSelectedPractitioner] = useState({
    id: 0,
    name: user.user.clinic.name,
  });
  // const [selectedPractitioner, setSelectedPractitioner] = useState([]);
  const [surveyOptions, setSurveyOptions] = useState([
    {
      id: 1,
      selected: true,
    },
    {
      id: 2,
      selected: true,
    },
    {
      id: 3,
      selected: true,
    },
    {
      id: 4,
      selected: true,
    },
    {
      id: 5,
      selected: true,
    },
    {
      id: 6,
      selected: true,
    },
  ]);

  const [surveyType, setSurveyType] = useState([
    {
      id: 1,
      name: "ChiroForm",
      selected: true,
    },
    {
      id: 2,
      name: "ChiroForm +",
      selected: true,
    },
  ]);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getPractitionerLogic();
  }, []);

  useEffect(() => {
    getPractitionerLogic();
  }, [selectedPractitioner]);

  const getPractitionerLogic = () => {
    setLoader(false);
    let postData = {};
    if (selectedPractitioner) {
      postData.practitionerId = selectedPractitioner.id;
    }
    getStatistics(postData, user).then((res) => {
      setLoader(true);
      statistics = res.data.data;
      console.log("statistics  ", statistics);
      setStatistics(res.data.data);
      return getMyUser(user)
          .then((res) => {
            console.log(res.data.data.clinic.surveySettings.sort(((a, b) => a.language.id - b.language.id))[0].bodyMetrics);
            const bodyMetrics = res.data.data.clinic.surveySettings.sort(((a, b) => a.language.id - b.language.id))[0].bodyMetrics ?? [];

            if (bodyMetrics.length > 0) {
              setItems([
                {
                  id: 1,
                  property: bodyMetrics[0].pdfName,
                },
                {
                  id: 2,
                  property: bodyMetrics[1].pdfName,
                },
                {
                  id: 8,
                  property: bodyMetrics[2].pdfName,
                },
                {
                  id: 9,
                  property: bodyMetrics[3].pdfName,
                },
                {
                  id: 3,
                  property: bodyMetrics[4].pdfName,
                },
                {
                  id: 4,
                  property: bodyMetrics[5].pdfName,
                },
                {
                  id: 5,
                  property: bodyMetrics[6].pdfName,
                },
                {
                  id: 6,
                  property: bodyMetrics[7].pdfName,
                },
                {
                  id: 7,
                  property: bodyMetrics[8].pdfName,
                },
                {
                  id: 10,
                  property: bodyMetrics[9].pdfName,
                },
              ]);
            } else {
              setItems([
                {
                  id: 1,
                  property: "neck",
                },
                {
                  id: 2,
                  property: "midBack",
                },
                {
                  id: 8,
                  property: "lowerBack",
                },
                {
                  id: 9,
                  property: "posture",
                },
                {
                  id: 3,
                  property: "energyLevels",
                },
                {
                  id: 4,
                  property: "abilityStress",
                },
                {
                  id: 5,
                  property: "sleepQuality",
                },
                {
                  id: 6,
                  property: "immuneSystem",
                },
                {
                  id: 7,
                  property: "mentalState",
                },
                {
                  id: 10,
                  property: "emotionalState",
                },
              ]);
            }
          });
    });
  };

  useEffect(() => {
    getAllPractitioners(user).then((res) => {
      let allPractitioners = res.data;
      allPractitioners.unshift({ id: 0, name: user.user.clinic.name });
      setPractitioners(allPractitioners);
    });
  }, []);

  const onCheckboxClick = (index) => {
    surveyOptions[index].selected = !surveyOptions[index].selected;
    setSurveyOptions([...surveyOptions]);
    updateStatisticsAccordingToOptions();
  };

  const onTypeClick = (index) => {
    surveyType[index].selected = !surveyType[index].selected;
    setSurveyType([...surveyType]);

    const selectedTypeIds = surveyType
      .filter((item) => item.selected)
      .map((item) => item.id);

    getStatistics({ surveyTypes: selectedTypeIds }, user).then((res) => {
      updateStatisticsAccordingToOptions(res.data.data);
    });
  };

  const updateStatisticsAccordingToOptions = (customStatistics = null) => {
    if (customStatistics) {
      statistics = customStatistics;
    }
    surveyOptions.forEach((o) => {
      const statisticIndex = statistics.findIndex((s) => {
        return s.surveyOrder === o.id;
      });
      if (o.selected === false) {
        if (statisticIndex !== -1) {
          statistics[statisticIndex].visible = false;
        }
      } else {
        if (statisticIndex !== -1) {
          statistics[statisticIndex].visible = true;
        }
      }
    });

    setStatistics([...statistics]);
  };
  const setSelected = (selected) => {
    setSelectedDiagram(selected);
  };

  console.log(user.user.clinic.name, "USER");

  return (
    <div className="Statistics">
      <div className="desktopView">
        <Headline
          text={i18n.t("globalStatistics")}
          type={"Headline-big-left"}
        />

        <div className={"statisticsDropdownContainer"}>
          <Dropdown
            options={practitioners}
            setSelectedOption={setSelectedPractitioner}
            selectedOption={selectedPractitioner}
            label="Select"
            type={"statistics"}
            placeholder="Select"
            show={show}
            excludeFirstOption={true}
          />
        </div>

        <div className={"statisticsDropdownContainer"}>
          <Dropdown
            options={diagramTypes}
            setSelectedOption={(selected) => setSelected(selected)}
            selectedOption={selectedDiagram}
            label={i18n.t("chooseDiagramType")}
            type={"statistics"}
            placeholder={i18n.t("diagramType")}
          />
        </div>

        <div className={"autoSelectContainer"}>
          <div className={"autoSelectTitle"}>
            {i18n.t("autoSelectStatistics")}
          </div>
          <div className={"autoSelectBox"}>
            {surveyOptions.map((s, index) => {
              return (
                <div className={"checkboxContainerBox"} key={index}>
                  <CustomCheckbox
                    value={s.selected}
                    setValue={() => onCheckboxClick(index)}
                  />
                  <div
                    className={"checkboxCustomLabel"}
                    onClick={() => onCheckboxClick(index)}
                  >
                    {" "}
                    {i18n.t("survey")} {s.id}{" "}
                  </div>
                </div>
              );
            })}
          </div>
          {user.user.clinic.businessPlan.id === 1 ? (
            ""
          ) : (
            <div className={"autoSelectBox"}>
              {surveyType.map((item, index) => {
                return (
                  <div className={"checkboxContainerBox"} key={index}>
                    <CustomCheckbox
                      value={item.selected}
                      setValue={() => onTypeClick(index)}
                    />
                    <div
                      className={"checkboxCustomLabel"}
                      onClick={() => {
                        onTypeClick(index);
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {items.map((item, index) => {
          if (selectedDiagram.id === 1)
            return (
              <div key={index}>
                <BarChart
                  values={statistics}
                  surveyOptions={surveyOptions}
                  property={item.property}
                  id={item.id}
                />
              </div>
            );

          if (selectedDiagram.id === 2)
            return (
              <div key={index}>
                <XYDiagram
                  values={statistics}
                  surveyOptions={surveyOptions}
                  property={item.property}
                  id={item.id}
                />
              </div>
            );
        })}

        {/* {loader ? (
          <>
            {items.map((item, index) => {
              if (selectedDiagram.id === 1)
                return (
                  <div key={index}>
                    <BarChart
                      values={statistics}
                      surveyOptions={surveyOptions}
                      property={item.property}
                      id={item.id}
                    />
                  </div>
                );

              if (selectedDiagram.id === 2)
                return (
                  <div key={index}>
                    <XYDiagram
                      values={statistics}
                      surveyOptions={surveyOptions}
                      property={item.property}
                      id={item.id}
                    />
                  </div>
                );
            })}
          </>
        ) : (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )} */}
      </div>
      <div className="mobileView">{i18n.t("desktopView")}</div>
    </div>
  );
};

export default Statistics;
