import React, { useState, useEffect } from "react";
import Logo from "../components/Comon/Logo/Logo";
import hamburger from "../../../src/hamburger.svg";
import close from "../../../src/Ic_close.svg";
import { appData } from "../../core/axios/services";
import { getAppData } from "../../redux/actions/app";
import { useDispatch, useSelector } from "react-redux";

const MobileHeader = () => {
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [open, setOpen] = useState(false);
  const [landingData, setLandingData] = useState();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  useEffect(() => {
    appData().then((res) => {
      dispatch(getAppData(res.data.data));
      setLandingData(res.data.data.landingData);
    });
  }, []);

  const handleHamburger = () => {
    setOpen(true);
  };

  const handleClosehamburger = () => {
    setOpen(false);
  };

  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };

  return (
    <>
      <div className="headerNavigationMobile">
        <div className="headerNavigationMobileLogo">
          <a href="/">
            <Logo className="logo" src={logo} />
          </a>
        </div>
        <div className="hamburgerMenu" onClick={handleHamburger}>
          <img src={hamburger} alt="hamburger" width="20px" height="14px" />
        </div>
      </div>

      {open && (
        <div className="mobileMenuOverlay" onClick={handleClosehamburger}>
          <div className="menuMobileopen">
            <div className="menuMobileopenHeader">
              <a href="/">
                <Logo className="logo" src={logo} />
              </a>
              <span onClick={handleClosehamburger}>
                <img src={close} alt="close hamburger" />
              </span>
            </div>
            <button className="buttonBookMobile" onClick={() => scroll()}>
              {app?.app_data?.landingData?.cta}
            </button>
            <ul className="menuItems">
              <li>
                <a href="/product-tour">Product Tour</a>
              </li>
              <li>
                <a href="/pricing">Pricing</a>
              </li>
            </ul>

            <ul className="menuItemsAuth">
              <li>
                <a href="login">Log in</a>
              </li>
              <li>
                <a href="signup">Sign up</a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileHeader;
