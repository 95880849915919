import React, { useMemo, useState, useEffect } from "react";
import "./VideoPlayer.scss";
import PlayIcon from "../PlayIcon/PlayIcon";
import ReactPlayer from "react-player";

const AllVideos = ({
  themeColor,
  url,
  videoSize,
  landing = false,
  controls = false,
  className,
  onPlay,
  onPause,
  id,
  isPlaying,
  volume,
}) => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const containerClass = useMemo(() => {
    if (landing === true) {
      return "videoContainerLanding";
    } else {
      return "videoContainer";
    }
  }, [landing]);

  useEffect(() => {
    if (isPlaying) {
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  }, [isPlaying]);

  const handlePlay = () => {
    if (!videoPlaying) {
      setVideoPlaying(true);
      onPlay(id);
    }
  };

  const handlePause = () => {
    if (videoPlaying) {
      setVideoPlaying(false);
      onPause();
    }
  };

  return (
    <div className={containerClass}>
      {videoPlaying ? (
        ""
      ) : (
        <div
          className={"playContainer"}
          style={videoPlaying ? { opacity: 0 } : null}
          onClick={handlePlay}
        >
          <PlayIcon themeColor={themeColor} />
        </div>
      )}

      <ReactPlayer
        playing={videoPlaying}
        url={url}
        playsinline
        width={videoSize.width}
        controls={true}
        height={videoSize.height}
        playIcon={<PlayIcon themeColor={themeColor} />}
        className={className}
        volume={volume}
        autoPlay={true}
        onPlay={handlePlay}
        onPause={handlePause}
        id={id}
      />
    </div>
  );
};

export default AllVideos;
