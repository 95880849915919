import "./NewLanding.scss";
import { useSelector } from "react-redux";

const AmazingResults = () => {
  const app = useSelector((state) => state.app);

  return (
    <div className="amazingWrapper amazingWrapperContainer">
      <div className="leftContent">
        <img src={"/assets/landing/tablet@2x.webp"} alt="tablet" />
        <div className="glow3" />
      </div>
      <div className="rightContent">
        <p className="landing-thin-font landing-section-two-text-one">
          {app?.app_data?.landingData.tablet_title}{" "}
        </p>

        <p className="landing-section-two-text-three">
          {app?.app_data?.landingData.tablet_text}
        </p>
      </div>
    </div>
  );
};

export default AmazingResults;
