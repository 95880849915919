import React from 'react';
import './LinkText.scss';
import {useNavigate} from "react-router-dom";

const LinkText = ({text, link}) => {
    const navigate = useNavigate();

    const openLink = () => {
        navigate(link);
    };
  return (
      <div className="LinkText" >
        {text}
      </div>
  );
};

export default LinkText;
