import React, { useState, useEffect, useMemo } from "react";
import "./ProductTour.scss";
import Logo from "../../components/Comon/Logo/Logo";
import hamburger from "../../../../src/hamburger.svg";
import close from "../../../../src/closeHamburger.svg";
import { useDispatch, useSelector } from "react-redux";
import { appData } from "../../../core/axios/services";
import { getAppData } from "../../../redux/actions/app";
import { NavLink, useNavigate } from "react-router-dom";
// import tourImage from "../../../../src/productTour.webp";
import tourImage from "../../../../src/productTourSmall.webp";
import tourImageSmall from "../../../../src/productTourLight.webp";
import demoVideo from "../../../../src/demoVideo.png";
import demoClose from "../../../../src/demoClose.svg";
import AllVideos from "../../components/Survey/VideoPlayer/AllVideos";
import getThemes from "../../../constants/colors/getTheme";

import ReactPlayer from "react-player";
import PlayIcon from "../../components/Survey/PlayIcon/PlayIcon";
import Header from "../../components/Header";
import MobileHeader from "../../components/MobileHeader";
import Footer from "../../components/Footer";

const ProductTour = () => {
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [open, setOpen] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);
  const [landingData, setLandingData] = useState();
  const [videoDemo, setVideoDemo] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const app = useSelector((state) => state.app);
  const themeColor = useMemo(() => {
    return getThemes("mainColor", app.appTheme);
  }, [app.appTheme]);
  const [videoSize, setVideoSize] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    appData().then((res) => {
      dispatch(getAppData(res.data.data));
      setLandingData(res.data.data.landingData);
      setVideoDemo(res.data.data.landingData.demo_video);
    });
  }, []);

  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };

  const handleHamburger = () => {
    setOpen(true);
  };

  const handleClosehamburger = () => {
    setOpen(false);
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleWatchDemo = () => {
    setOpenDemo(true);
  };

  const handleDemoClose = () => {
    setOpenDemo(false);
  };

  const loadedImage = () => {
    setTimeout(() => {
      setImageLoaded(true);
    }, 50);
  };

  return (
    <div>
      {/* Header */}
      <div className="container-fluid">
        <div className="row  p-0 landing-section landing-section-one">
          <Header />

          {/* Mobile header */}
          <MobileHeader />
          {/* End mobile header */}
        </div>
      </div>
      {/* End Header */}

      <div className="contentFooterWrap">
        {/* Content */}
        <div className="productTourContent">
          <div className="leftSide">
            <div className="leftContent">
              <h2>
                <strong>Quick overview</strong> <br /> of the platform.
              </h2>
              <p>Start here if you´re new to Chiropractic Outcomes.</p>
              <div className="buttonsWrapp">
                <button className="signup" onClick={handleSignUp}>
                  Sign up
                </button>
                <button className="watchDemo" onClick={handleWatchDemo}>
                  Watch demo
                </button>
              </div>
            </div>
          </div>
          <div className="rightSide">
            <img
              // style={{ visibility: imageLoaded ? "visible" : "hidden" }}
              className="rightSideBigImage"
              src={tourImage}
              onLoad={() => loadedImage()}
              alt="tourImage"
            />
            {/* <img
              style={{ visibility: !imageLoaded ? "visible" : "hidden" }}
              className="rightSideSmallImage"
              src={tourImageSmall}
              alt="tourImage"
            /> */}
          </div>
        </div>

        {openDemo && (
          <div className="demoOverlay">
            <div className="demoContent">
              <div>
                <img
                  className="demoClose"
                  src={demoClose}
                  alt="demoClose"
                  onClick={handleDemoClose}
                />
                <AllVideos
                  themeColor={themeColor}
                  url={videoDemo}
                  videoSize={videoSize}
                  landing={false}
                  className="videoClip"
                  volume={100}
                />
              </div>
            </div>
          </div>
        )}
        {/* End Content */}

        <Footer />

        {/* Footer */}
        {/* <div className="row landing-section landing-section-nine">
          <div className=" landing-section-nine-top">
            <div className="landing-top-left-div landing-section-nine-div">
              <h4 className="landing-dark-text-top landing-section-nine-text">
                <span className="landing-word-top">Chiropractic</span>
                <span className="landing-word-bottom landing-text-nine">
                  Outcomes
                </span>
              </h4>

              <div className="landing-section-nine-right">
                <img
                  src={"/assets/landing/ic_instagram@3x.png"}
                  id="insta"
                  className="img-fluid landing-section-nine-icon float-end"
                  alt="section-nine-icon"
                />
                <img
                  src={"/assets/landing/ic_facebook@3x.png"}
                  className="img-fluid landing-section-nine-icon float-end"
                  alt="section-nine-icon"
                />
                <img
                  src={"/assets/landing/ic_linkedin@3x.png"}
                  className="img-fluid landing-section-nine-icon float-end"
                  alt="section-nine-icon"
                />
              </div>
            </div>
          </div>

          <div className=" landing-section-nine-bottom text-center">
            <hr className="landing-line" />
            <div className="copyright">
              <p
                className="landing-privacy"
                onClick={() => navigate("/privacy")}
              >
                Privacy Policy
              </p>
              <span>|</span>
              <p className="landing-privacy" onClick={() => navigate("/terms")}>
                Terms of Use
              </p>
            </div>
          </div>
        </div> */}
        {/* End footer */}
      </div>
    </div>
  );
};

export default ProductTour;
