import React from 'react';

const Boilerplate = ({fill, stroke}) => {
    return (
        <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>star</title>
            <g id="For-Brock---MVP-Design" strokeWidth="1" fill={fill} stroke={stroke} fillRule="evenodd">
                <g id="Design-_-Survey-2-6-_-Step-3" transform="translate(-877.000000, -3179.000000)" fillRule="nonzero" stroke="{stroke}" strokeWidth="2">
                    <g id="Group-7" transform="translate(360.000000, 3065.000000)">
                        <g id="Group-6-Copy-3" transform="translate(498.000000, 95.000000)">
                            <g id="star" transform="translate(20.000000, 20.000000)">
                                <path d="M20.9452161,7.55775998 C20.8077464,7.13516868 20.430586,6.83502379 19.9843703,6.79505754 L13.9232076,6.24810917 L11.5264607,0.67305699 C11.3497369,0.264477802 10.9472616,1.44817079e-16 10.5000846,1.44817079e-16 C10.0529076,1.44817079e-16 9.65043231,0.264477802 9.47370847,0.674012378 L7.07696161,6.24810917 L1.01483756,6.79505754 C0.569422973,6.83597914 0.193223962,7.13516868 0.0549531754,7.55775998 C-0.0833176109,7.98035123 0.0443785648,8.44386413 0.381323467,8.73604764 L4.96284473,12.729169 L3.61186074,18.6433769 C3.51300436,19.0782288 3.68283864,19.5277296 4.04589958,19.7885451 C4.24104886,19.9286658 4.46936386,20 4.69960152,20 C4.89811542,20 5.0950271,19.9468178 5.27175094,19.8417273 L10.5000846,16.7363024 L15.7264956,19.8417273 C16.1089433,20.0703788 16.5910485,20.0495199 16.9533083,19.7885451 C17.3165295,19.5269334 17.4862035,19.0772734 17.3873471,18.6433769 L16.0363632,12.729169 L20.6178844,8.73684377 C20.9548293,8.44386413 21.0834868,7.98114736 20.9452161,7.55775998 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Boilerplate;
