import React, { useState } from "react";
import "../pages/NewLanding/NewLanding.scss";
import Logo from "./Comon/Logo/Logo";

const Footer = () => {
  const [logo, setLogo] = useState("/assets/logotm.png");

  return (
    <div className="footerWrapper mainContainerWrapper">
      <div className="topArea">
        <img className="footerLogo" src={logo} alt="logo" />
        <div className="footerSocial">
          <img src={"/assets/landing/ic_linkedin@3x.png"} alt="linkedin" />
          <img src={"/assets/landing/ic_facebook@3x.png"} alt="facebook" />
          <img src={"/assets/landing/ic_instagram@3x.png"} alt="instagram" />
        </div>
      </div>
      <div className="bottomArea">
        <a href="/privacy">Privacy</a> | <a href="/terms">Terms of Use</a>
      </div>
    </div>
  );
};

export default Footer;
