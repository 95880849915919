import React, { useState } from "react";
import "./NewLanding.scss";
import { useSelector } from "react-redux";

const Measur = () => {
  const [seeMore, setSeeMore] = useState(false);
  const app = useSelector((state) => state.app);

  return (
    <div className="measureWrap measureContaienrWrap">
      <div className="measureWidth">
        <div className="leftContent">
          <img
            src={"/assets/landing/laptop-foto@2x.webp"}
            className="img-fluid landing-laptop-foto"
            alt="laptop-foto"
          />
        </div>
        <div className="rightContent">
          <p className="landing-thin-font landing-section-five-text-title">
            {app?.app_data?.landingData.laptop_2_regular}
            <span className="landing-section-five-span">
              {" "}
              {app?.app_data?.landingData.laptop_2_bold}
            </span>
          </p>

          <p className="landing-section-five-text-body landing-basic-font">
            {app?.app_data?.landingData.laptop_2_text}
          </p>

          <p
            className="landing-section-five-text-body landing-basic-font"
            style={!seeMore ? { display: "none" } : null}
          >
            {app?.app_data?.landingData.laptop_2_long_text}
          </p>

          <p
            // className="landing-bolder-font landing-section-five-text-read-more"
            className="readMoreBtn"
            onClick={() => setSeeMore(!seeMore)}
          >
            {seeMore ? <span>CLOSE</span> : <span>READ MORE…</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Measur;
