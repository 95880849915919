import React, { useEffect, useState } from "react";
import "./SideNavigationElement.scss";
import getThemes from "../../../../constants/colors/getTheme";
import i18n from "i18next";

const SideNavigationElement = ({ app, icon, title, active, setActivePage }) => {
  const [elementStyle, setElementStyle] = useState({});

  useEffect(() => {
    setElementStyle({
      backgroundColor: getThemes("opacityColor", app.appTheme),
    });
  }, []);

  return (
    <div
      className="SideNavigationElement"
      style={active ? elementStyle : null}
      onClick={() => setActivePage(title)}
    >
      <img
        className={"SideNavigationElementIcon"}
        src={
          active
            ? "/assets/" + icon + "_Active.svg"
            : "/assets/" + icon + ".svg"
        }
      />
      <div className={"SideNavigationElementText"}>{i18n.t(title)}</div>
    </div>
  );
};

export default SideNavigationElement;
