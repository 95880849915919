import React, { useEffect, useMemo, useState } from "react";
import "./EditablePatientProfileSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {
  updateSurveyData,
  updateSurveyPage,
  updateSurveyPatient,
  updateSurveySetting,
} from "../../../../../redux/actions/survey";
import { useDispatch } from "react-redux";
import {
  checkIfPatientExists,
  getSettingsFromCode,
  getSurveyById,
} from "../../../../../core/axios/services";

const EditablePatientProfileSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
  isEditable,
}) => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [themeColor, setThemeColor] = useState("");
  const dispatch = useDispatch();
  const name = useMemo(() => {
    if (survey && survey.patient) {
      return survey.patient.name;
    } else {
      return "";
    }
  }, [survey]);
  const lastName = useMemo(() => {
    if (survey && survey.patient) {
      return survey.patient.lastName;
    } else {
      return "";
    }
  }, [survey]);
  const email = useMemo(() => {
    if (survey && survey.patient) {
      return survey.patient.email;
    } else {
      return "";
    }
  }, [survey]);
  const { surveyId } = useParams();

  useEffect(() => {
    dispatch(updateSurveyPage(1));
    window.scrollTo(0, 0);

    getSurveyById(surveyId).then((res) => {
      dispatch(updateSurveyData(res.data.data));
      dispatch(updateSurveyPatient(res.data.data.patient));
    });
  }, []);

  const submit = (data) => {
    if (survey.settings.type === 1 || survey.settings.type === 3) {
      //basic
      dispatch(updateSurveyPage(3));
      navigate("/survey-edit/" + code + "/" + surveyId + "/health-concerns");
    } else if (survey.settings.type === 2) {
      //advanced
      dispatch(updateSurveyPage(2));
      navigate("/survey-edit/" + code + "/" + surveyId + "/health-stresses");
    }
  };

  return (
    <div className="SecondScreenSurvey">
      <SurveyHero
        // step={i18n.t("patientsProfile")}
        headline={survey.settings.patientHeadline}
        text={survey.settings.introText}
        buttonText={i18n.t("submit")}
        clickedButton={(data) => submit(data)}
        app={app}
        isForm={true}
        surveyType={survey.settings.type}
        i18n={i18n}
        email={email}
        name={name}
        lastName={lastName}
        isEditable={isEditable}
      />
    </div>
  );
};

export default EditablePatientProfileSurvey;
