import React from 'react';
import './ErrorPage.scss';

const ErrorPage = () => {
  return (
      <div className="Boilerplate">
        Error
      </div>
  );
};

export default ErrorPage;
