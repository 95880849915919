import React, {useEffect, useState} from 'react';
import './EditableNextIntroSurvey.scss';
import {useNavigate, useParams} from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {
    updateSurveyData,
    updateSurveyPage,
    updateSurveyPatient,
    updateSurveyPatientId
} from "../../../../../redux/actions/survey";
import {useDispatch} from "react-redux";
import {getSurveyById} from "../../../../../core/axios/services";


const EditableNextIntroSurvey = ({showGlobalSnackbar, survey, i18n, app, isNext}) => {

    const navigate = useNavigate();
    const {code} = useParams();
    const {previousId} = useParams();
    const {surveyId} = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateSurveyPatientId(null));
        dispatch(updateSurveyPage(0));
        window.scrollTo(0, 0)


        getSurveyById(surveyId).then((res) => {
            dispatch(updateSurveyData(res.data.data))
            dispatch(updateSurveyPatient(res.data.data.patient))
        })

    }, []);


    const nextPage = () => {
        dispatch(updateSurveyPage(1));
        if (isNext) {
            navigate("/survey-next-edit/" + code  + "/" + surveyId + "/" + previousId + "/health-concerns");

        } else {
            navigate("/survey-edit/" + code + "/" + surveyId + "/patient-profile");
        }
    }


    return (
        <div className="FirstScreenSurvey">

            <SurveyHero
                step={i18n.t("hello")}
                headline={survey?.settings?.nextIntroHeadline}
                text={survey?.settings?.nextIntroText}
                videoLink={survey?.settings?.nextIntroVideo}
                buttonText={i18n.t("updateSurvey")}
                clickedButton={() => nextPage()}
                app={app}
                surveyType={survey?.settings?.type}
            />


        </div>
    );
};

export default EditableNextIntroSurvey;
