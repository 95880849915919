import React, { useState } from "react";
import "./NewLanding.scss";
import { useSelector } from "react-redux";

const Testimonials = () => {
  const [seeMore, setSeeMore] = useState(false);

  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };
  const app = useSelector((state) => state.app);

  return (
    <div className="testimonialWrap testimonialContainerWrapper">
      <div className="topContent">
        <p className="landing-thin-font p-0">
          {app?.app_data?.landingData.testimonial_title_regular1}
          <span className="landing-section-seven-span">
            {" "}
            {app?.app_data?.landingData.testimonial_title_bold}
          </span>{" "}
          {app?.app_data?.landingData.testimonial_title_regular2}
        </p>
      </div>

      <div className="middleContent">
        <div>
          <div className="info">
            <div className="row infoTopWrap p-0">
              <img
                src={
                  process.env.REACT_APP_API_STORAGE +
                  app?.app_data?.landingData?.testimonial_1_image
                }
                className="img-fluid imageInfo landing-float-start"
                alt="section-seven-face"
              />
              <div className="landing-section-seven-bio">
                <p className="landing-section-seven-title">
                  {app?.app_data?.landingData.testimonial_1_name}
                </p>
                <p className="landing-bolder-font landing-section-seven-position">
                  {app?.app_data?.landingData.testimonial_1_job}
                </p>
              </div>
            </div>

            <div className="row textInfo p-0">
              <p className="landing-section-seven-bottom-text p-0 m-0">
                {app?.app_data?.landingData.testimonial_1_text}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="info">
            <div className="row infoTopWrap p-0">
              <img
                src={
                  process.env.REACT_APP_API_STORAGE +
                  app?.app_data?.landingData?.testimonial_2_image
                }
                className="img-fluid imageInfo landing-float-start"
                alt="section-seven-face"
              />
              <div className="landing-section-seven-bio">
                <p className="landing-section-seven-title">
                  {app?.app_data?.landingData?.testimonial_2_name}
                </p>
                <p className="landing-bolder-font landing-section-seven-position">
                  {app?.app_data?.landingData?.testimonial_2_job}
                </p>
              </div>
            </div>

            <div className="row textInfo p-0">
              <p className="landing-section-seven-bottom-text p-0 m-0">
                {app?.app_data?.landingData?.testimonial_2_text}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="info">
            <div className="row infoTopWrap p-0">
              <img
                src={
                  process.env.REACT_APP_API_STORAGE +
                  app?.app_data?.landingData?.testimonial_3_image
                }
                className="img-fluid imageInfo landing-float-start"
                alt="section-seven-face"
              />
              <div className="landing-section-seven-bio">
                <p className="landing-section-seven-title">
                  {app?.app_data?.landingData?.testimonial_3_name}
                </p>
                <p className="landing-bolder-font landing-section-seven-position">
                  {app?.app_data?.landingData?.testimonial_3_job}
                </p>
              </div>
            </div>

            <div className="row textInfo p-0">
              <p className="landing-section-seven-bottom-text p-0 m-0">
                {app?.app_data?.landingData?.testimonial_3_text}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomContent">
        <button
          className="btn bg-primary landing-bolder-font landing-section-seven-button landing-section-seven-button-margin"
          onClick={() => scroll()}
        >
          {app?.app_data?.landingData?.cta}
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
