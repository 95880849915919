import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Comon/Button/Button";
import Headline from "../../../components/Comon/Headline/Headline";
import { useNavigate } from "react-router-dom";
import "./Practitioners.scss";
import Table from "../../../components/Admin Panel/Table/Table";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";

import {
  checkPractitionerPayment,
  getMyUser,
  getPatients,
  getPractitioners,
  payment,
} from "../../../../core/axios/services";
import { addUser } from "../../../../redux/actions/user";

import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  mobile: `max-width: 1200px`,
  desktop: `min-width: 1200px`,
};

const Practitioners = ({ showGlobalSnackbar }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // From patients
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [practitioners, setPractitioners] = useState([]);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [perPage, setPerPage] = useState(0);
  const [loadedUser, setLoadedUser] = useState(false);

  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [sortDirection]);

  // useEffect(() => {
  //   console.log("OVO JE SORT PATIENTS", sort);
  // }, [sort]);

  const getData = async () => {
    setLoadedUser(true);
    try {
      return await getPractitioners(
        user?.user?.clinic?.id,
        page,
        showAll,
        sortDirection,
        sort,
        user
      ).then((res) => {
        setPractitioners(res.data.data);
        setTotal(res.data.total);
        setPerPage(parseInt(res.data.perPage));
        setLoadedUser(false);
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };
  // End patients

  const newPractitioner = async () => {
    console.log("open new practitioner");
    return await checkPractitionerPayment(user).then((res) => {
      console.log("count   ", res.data.data.count);
      if (res.data.data.count == 1) {
        setOpen(true);
      } else {
        navigate("/dashboard/practitioner");
      }
    });
  };

  const startSubscription = async () => {
    try {
      const res = await payment(3, user);
      if (res.data.url != null) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  let practitionersLength = 1;

  const popup = (
    <>
      {open && (
        <div className="popUpPay">
          <div className="popUpPayBackdrop"></div>
          <div className="popUpContent">
            <h1>{i18n.t("newPractitioner")}</h1>
            <p>{i18n.t("newPractitionerDesc")}</p>
            <div className="priceTag">
              {i18n.t("perMonth")} / {i18n.t("perPractitioner")} <br />{" "}
              <span>10 EUR</span>
            </div>
            <h2>{i18n.t("wantAction")}</h2>
            <div className="popUpButtons">
              <div
                className={"smallButtonContainers"}
                onClick={() => startSubscription()}
              >
                <Button text="Yes, continue" mode={"small"} />
              </div>
              <a className="goBack" onClick={() => setOpen(false)}>
                {i18n.t("goBack")}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const empryState = (
    <div className="empryState">
      <div className="content">
        <p>
          {" "}
          {i18n.t("emptyPractitioners")} <br /> {i18n.t("firstFree")}
        </p>
        <div
          className={"smallButtonContainers"}
          onClick={() => newPractitioner()}
        >
          <Button text={i18n.t("addNewPractitioners")} mode={"small"} />
        </div>
      </div>
    </div>
  );

  // Mobile
  const clickMore = (item) => {
    navigate("/dashboard/practitioner/" + item.id);
  };

  const [pages, setPages] = useState([0]);
  const [currentPage, setCurrentPage] = useState("");

  const goToPage = (selectedPage) => {
    if (typeof selectedPage === "number") {
      if (selectedPage < pages[0] || selectedPage > pages[pages.length - 1]) {
        return;
      }
      setPage(selectedPage);

      if (selectedPage >= pages[pages.length - 2]) {
        // setCurrentPage(1);
      } else if (selectedPage == 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage(selectedPage);
      }
    }
  };

  useEffect(() => {
    const lastPage = Math.ceil(total / perPage);

    if (lastPage > 0) {
      const pages = Array.from(Array(lastPage).keys());
      if (pages.length <= 5) {
        setPages(pages);
      } else {
        // console.log(currentPage,'currentPage');
        let pagesFirstPart = [
          pages[currentPage - 1],
          pages[currentPage],
          pages[currentPage + 1],
          "...",
        ];
        let pagesLastPart = [pages[pages.length - 2], pages[pages.length - 1]];

        if (currentPage == 0) {
          setCurrentPage(1);
        } else if (currentPage >= pages[pages.length - 3]) {
          setCurrentPage(currentPage);
          pagesFirstPart = [
            pages[currentPage - 2],
            pages[currentPage - 1],
            pages[currentPage],
            "...",
          ];
        }

        const pagesItems = [...pagesFirstPart, ...pagesLastPart];

        setPages(pagesItems);
      }
    }
  }, [practitioners]);

  return (
    <div className="practitionersWrappper">
      {" "}
      {popup}
      <div className="headingWrapper">
        <Headline text={i18n.t("practitioners")} type={"Headline-medium"} />
        {practitioners?.length != 0 && (
          <div
            className={"smallButtonContainers"}
            onClick={() => newPractitioner()}
          >
            <Button text={i18n.t("addNewPractitioners")} mode={"small"} />
          </div>
        )}
      </div>
      {loadedUser ? (
        <Oval
          visible={true}
          height="80"
          width="80"
          color="#61849d"
          secondaryColor="#F4F4F3"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass="oval-wrapper"
        />
      ) : isMobile ? (
        practitioners.length > 0 ? (
          <div className="mobileTable">
            <div className="topSortMobile"></div>
            {practitioners.map((item, index) => {
              return (
                <div className="mobileWrapper">
                  {" "}
                  <div className="patientName">
                    <h3>
                      {item.firstName} {item.lastName}
                    </h3>
                    {item.patientsCount}
                  </div>
                  <div className="seeMoreMob" onClick={() => clickMore(item)}>
                    {" "}
                    See more
                    <img
                      className={"rightArrowIcon"}
                      alt="rightArrowIcon"
                      src={"/assets/Icon_Arrow-Right.svg"}
                      width="9px"
                      height="100%"
                    />
                  </div>
                </div>
              );
            })}
            <div className={"tableFooter"} style={{ marginTop: "20px" }}>
              <div className={"tableFooterLeft"}>
                <div className={"resultsInfo"}>
                  {i18n.t("showingOutOf", {
                    count: practitioners?.length,
                    total,
                  })}
                </div>
                {!showAll ? (
                  <div
                    className={"viewAllResults"}
                    onClick={() => setShowAll(true)}
                  >
                    {i18n.t("viewAllResults")}
                  </div>
                ) : null}
              </div>
              <div className={"tableFooterRight"}>
                <img
                  className={"iconLeftPaginate"}
                  src={"/assets/Arrow_Left.svg"}
                  alt="iconLeftPaginate"
                  onClick={() => goToPage(page - 1)}
                  width="7px"
                  height="100%"
                />
                {pages.map((pageOption, index) => {
                  let pageClass = "page";
                  if (pageOption === page) {
                    pageClass += " activePage";
                  }
                  let pageNumber = pageOption;
                  if (typeof pageOption === "number") {
                    pageNumber += 1;
                  }
                  return (
                    <div
                      className={pageClass}
                      onClick={() => goToPage(pageOption)}
                      key={index}
                    >
                      {" "}
                      {pageNumber}{" "}
                    </div>
                  );
                })}
                <img
                  className={"iconRightPaginate"}
                  onClick={() => goToPage(page + 1)}
                  src={"/assets/Arrow_Right.svg"}
                  alt="iconRightPaginate"
                  width="8px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        ) : (
          empryState
        )
      ) : practitioners?.length == 0 ? (
        empryState
      ) : (
        <div className={"dataTableContainer"}>
          <div className="patientTable">
            <Table
              app={app}
              perPage={perPage}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              type={"practitioners"}
              i18n={i18n}
              total={total}
              sort={sort}
              setSort={setSort}
              setPage={setPage}
              page={page}
              data={practitioners}
              showAll={showAll}
              setShowAll={setShowAll}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Practitioners;
