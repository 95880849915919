import React, { useState, useEffect } from "react";
import Logo from "./Comon/Logo/Logo";
import { NavLink } from "react-router-dom";
import { appData } from "../../core/axios/services";
import { getAppData } from "../../redux/actions/app";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [landingData, setLandingData] = useState();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  useEffect(() => {
    appData().then((res) => {
      dispatch(getAppData(res.data.data));
      setLandingData(res.data.data.landingData);
    });
  }, []);

  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };

  return (
    <div className="headerNavigation">
      <div className="headerNavigationLogo">
        <NavLink to="/">
          <Logo className="logo" src={logo} />
        </NavLink>
      </div>
      <div className="headerNavigationContent">
        <div className="leftContent">
          <ul>
            <li>
              <NavLink activeClassName="is-active" to="/product-tour">
                Product Tour
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/pricing">
                Pricing
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="is-active" to="/signup">
                Sign Up
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="rightContent">
          <a href="/login">Log in</a>
          <button className="buttonBook" onClick={() => scroll()}>
            {app?.app_data?.landingData?.cta}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
