import React, { useEffect, useState } from "react";
import "./SurveySettings.scss";
import Headline from "../../../components/Comon/Headline/Headline";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Dropdown from "../../../components/Admin Panel/Dropdown/Dropdown";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Button from "../../../components/Comon/Button/Button";
import {
  getMyUser,
  updateSurveySettings,
  updateSurveyClinicSettings,
  updateHealthConcerns,
  updateOverallHealth,
  updateLifeImpact,
} from "../../../../core/axios/services";
import {
  addUser,
  updateClinicSurveySettings,
} from "../../../../redux/actions/user";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";

import Accordion from "./Accordion";
import CustomisableQuestion from "../../../components/Survey/CustomisableQuestions/Index.js";
import HealthMetricsQuestions from "../../../components/Survey/HealthMetricsQuestions/Index.js";
import { SetMealSharp } from "@mui/icons-material";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useNavigate } from "react-router-dom";
import TextInputLabel from "../../../components/Comon/TextInputLabel/TextInputLabel";
import QuestionPropertiesForm from "../../../components/QuestionPropertiesForm";

const SurveySettings = ({ showGlobalSnackbar }) => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const initialSettings = {
    introHeadline: "",
    introText: "",
    introVideo: "",
    nextStressHeadline: "",
    nextStressText: "",
    nextStressVideo: "",
    patientHeadline: "",
    patientText: "",
    patientVideo: "",
    stressesHeadline: "",
    stressesText: "",
    stressesVideo: "",
    topThreeHeadline: "",
    topThreeText: "",
    topThreeVideo: "",
    globalHeadline: "",
    globalText: "",
    globalVideo: "",
    thankHeadline: "",
    thankText: "",
    thankVideo: "",
    nextIntroHeadline: "",
    nextIntroText: "",
    nextIntroVideo: "",
    nextTopThreeHeadline: "",
    nextTopThreeText: "",
    nextTopThreeVideo: "",
    nextGlobalHeadline: "",
    nextGlobalText: "",
    nextGlobalVideo: "",
    nextThankHeadline: "",
    nextThankText: "",
    nextThankVideo: "",
    language: user?.user?.clinic?.languages[0],
    type: user?.user?.clinic?.businessPlan?.id,
    clinicId: user?.user?.clinic?.id,
    openEndedQuestions: null,
    surveyLink: null,
    isEmpty: true,
    openEnded1Title: null,
    openEnded2Title: null,
    openEnded3Title: null,
    openEnded4Title: null,
    openEnded1Description: null,
    openEnded2Description: null,
    openEnded3Description: null,
    openEnded4Description: null,
    send_clinic_mail_address: "",
  };
  let [settings, setSettings] = useState(initialSettings);

  let [toGenerateLink, setToGenerateLink] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [settingTypes, setSettingTypes] = useState([
    { id: 1, name: "ChiroForm" },
    {
      id: 2,
      name: "ChiroForm +",
    },
    {
      id: 3,
      name: "ChiroForm (CP)",
    },
  ]);

  const latestUpdatedObject = user?.user?.clinic?.surveySettings.reduce(
    (acc, curr) => (curr.id > acc.id ? curr : acc),
    user.user.clinic.surveySettings[0]
  );
  const [answersLang, setAnswersLang] = useState("en");

  const [userSetting, setUserSetting] = useState("");

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedClinic, setIsCheckedClinic] = useState(false);
  const [email, setEmail] = useState("");
  const [account, setAccount] = useState(null);
  const [dummyState, setDummyState] = useState(0);
  const [emailError, setEmailError] = useState(null);
  const [openHealth, setOpenHealth] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [screenDropdown1, setScreenDropdown1] = useState(false);
  const [screenDropdown2, setScreenDropdown2] = useState(false);

  const [healthConcernsFormData, setHealthConcernsFormData] = useState({});
  const [overallHealthFormData, setOverallHealthFormData] = useState({});
  const [lifeImpactFormData, setLifeImpactFormData] = useState({});

  useEffect(() => {
    setAccount(settings);
    if (email === "") {
      setEmail(settings.send_clinic_mail_address);
      // Trigger a re-render
    }
  }, [settings]);

  const checkEmail = (value) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(value) && email) {
      setEmailError(i18n.t("emailFormat"));

      return false;
    } else {
      setEmailError(null);
      return true;
    }
  };

  const getMeData = async () => {
    return getMyUser(user)
      .then((res) => {
        setUserSetting(res.data.data.type);
        setAccount({ ...account });
        dispatch(addUser(res.data.data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    getMeData();
  }, []);

  useEffect(() => {
    if (user.user.clinic.businessPlan.id === 1) {
      // basic
      setSettingTypes([{ id: 1, name: "ChiroForm" }]);
    }
    if (
      user.user.clinic.surveySettings &&
      user.user.clinic.surveySettings.length > 0 &&
      settings.isEmpty
    ) {
      let settings = user.user.clinic.surveySettings;
      if (user.user.clinic.businessPlan.id === 1) {
        if (user.user.clinic.languages.length === 1) {
          settings = user.user.clinic.surveySettings.filter(
            (s) =>
              s.type === 1 && s.language.id === user.user.clinic.languages[0].id
          );
        } else {
          // basic
          settings = user.user.clinic.surveySettings.filter(
            (s) => s.type === 1
          );
        }
      }

      const sortedSettings = settings.sort((a, b) => b.id - a.id);

      setSettings(sortedSettings[0]);
    }
  }, [user]);

  useEffect(() => {
    if (settings.surveyLink === "" || settings.surveyLink == null) {
      //generateLink(false);
    }

    const openEnded = [];

    setAnswersLang(settings.language.code);

    // if (settings.language.id === 1) {
    //   setAnswersLang("en");
    // } else if (settings.language.id === 2) {
    //   setAnswersLang("es");
    // } else {
    //   setAnswersLang("sr");
    // }
  }, [settings]);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "surveySettings":
          updateSettingsApi();
          dispatch(resetPopUp());
          break;
        case "deleteField":
          setSettings({ ...app.popUpData.data });
          dispatch(resetPopUp());
          break;
        case "enableField":
          setSettings({ ...app.popUpData.data });
          dispatch(resetPopUp());
          break;
        case "changePricePlan":
          navigate("/dashboard/price-plans");
          dispatch(resetPopUp());
          break;
        case "userPopup":
          dispatch(resetPopUp());
          break;
        case "linkReviewBasic":
          handleBasicPlan();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
      switch (app.popUpData?.type) {
        case "deleteField":
          const indexDelete = app.popUpData?.index;
          const newArr = settings.openEndedQuestions;
          newArr[indexDelete].isActive = true;
          settings.openEndedQuestions = newArr;
          setSettings({ ...settings });
          break;
        case "enableField":
          const indexEnable = app.popUpData?.index;
          const newArrEn = settings.openEndedQuestions;
          newArrEn[indexEnable].isActive = false;
          settings.openEndedQuestions = newArrEn;

          setSettings({ ...settings });
          break;
      }
    }
  }, [app]);

  const generateAndSave = () => {
    save();

    setToGenerateLink(true);
  };

  const generateLink = (showSnackbar) => {
    // on button click don't allow user to generate if he doesn't have permissions
    if (showSnackbar) {
      if (user.user.type === 3) {
        showGlobalSnackbar(i18n.t("dontHavePermission"));
        return;
      }
    }

    settings.surveyLink = (Math.random() + 1).toString(36).substring(2);
    setSettings({ ...settings });

    if (showSnackbar) showGlobalSnackbar(i18n.t("newLinkGenerated"));
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_SURVEYLINK + "/survey/" + settings.surveyLink
    );
    showGlobalSnackbar(i18n.t("linkCopied"));
  };

  const updateLanguage = (language) => {
    const correctSettings = user.user.clinic.surveySettings.find((s) => {
      return s.language.id === language.id && s.type === settings.type;
    });

    if (correctSettings) {
      console.log("found correct settings", correctSettings);
      setSettings(correctSettings);
    } else {
      console.log("didnt find correct settings");

      settings = initialSettings;
      settings.language = language;
      settings.id = null;
      setSettings({ ...settings });
    }
  };

  const updateType = (type) => {
    // console.log(user.user.clinic.surveySettings);
    // settings.type = type.id;
    // setSettings({...settings});

    const defaultSettings = user.user.clinic.surveySettings.find((s) => {
      return s.type === type.id && s.language.id === settings.language.id;
    });

    setIsCheckedClinic(defaultSettings?.send_clinic_mail);

    if (defaultSettings) {
      setSettings(defaultSettings);
    } else {
      settings = initialSettings;
      settings.type = type.id;
      setSettings({ ...settings });
    }
  };

  const updateSettings = (value, property) => {
    settings[property] = value;
    setSettings({ ...settings });
  };

  const updateSettingsApi = async () => {
    if (toGenerateLink) {
      generateLink(false);
    }

    const updatedSettings = {
      ...settings,
      sendMail: isChecked,
      send_clinic_mail: isCheckedClinic,
      send_clinic_mail_address: email,
    };

    try {
      return await updateSurveySettings(updatedSettings, user).then((res) => {
        showGlobalSnackbar(i18n.t("saved"));
        dispatch(addUser(res.data.data));
        setToGenerateLink(false);
      });
    } catch (error) {
      setToGenerateLink(false);
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const save = () => {
    if (user.user.type === 3) {
      showGlobalSnackbar(i18n.t("dontHavePermission"));
      return;
    }

    const isEmailValid = checkEmail(email);

    if (!isEmailValid) {
      showGlobalSnackbar("Email is not valid");
      return;
    }

    setToGenerateLink(true);

    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmSurveySettings"),
        confirmButton: i18n.t("confirmSurveySettingsButton"),
        denyButton: i18n.t("denySurveySettingsButton"),
        type: "surveySettings",
      },
    };
    dispatch(showPopup(data));
  };

  useEffect(() => {
    setItems(settings.openEndedQuestions);
  }, [settings]);

  const [items, setItems] = useState(settings.openEndedQuestions);

  const [openEndedSectionTitleError, setOpenEndedSectionTitleError] = useState(null);
  const updateOpenEndedSectionTitle = (title) => {
    if(title?.length > 0 && title?.length <= 255){
      setOpenEndedSectionTitleError(null);
      setSettings({ ...settings, openEndedSectionTitle: title });
    }else{
      if(title?.length >= 255){
        setOpenEndedSectionTitleError('The maximum length for the section title is 255 characters.');
      }else{
        setOpenEndedSectionTitleError('The section title is required and cannot be left blank.');
        setSettings({ ...settings, openEndedSectionTitle: title });
      }
    }
  };


  const [healthMetricsPdfTitleError, setHealthMetricsPdfTitleError] = useState(null);
  const updateHealthMetricsPdfTitle = (title) => {
    if(title?.length > 0 && title?.length <= 255){
      setHealthMetricsPdfTitleError(null);
      setSettings({ ...settings, healthMetricsPdfTitle: title });
    }else{
      if(title?.length >= 255){
        setHealthMetricsPdfTitleError('The maximum length for the section title is 255 characters.');
      }else{
        setHealthMetricsPdfTitleError('The section title is required and cannot be left blank.');
        setSettings({ ...settings, healthMetricsPdfTitle: title });
      }
    }
  };



  const updateItemTitle = (itemId, newTitle) => {
    const index = settings.openEndedQuestions.findIndex((i) => i.id === itemId);
    if (index !== -1) {
      settings.openEndedQuestions[index].title = newTitle;
    }

    setItems(settings.openEndedQuestions);
    setSettings({ ...settings });
  };

  const updateItemText = (itemId, newText) => {
    const index = settings.openEndedQuestions.findIndex((i) => i.id === itemId);
    if (index !== -1) {
      settings.openEndedQuestions[index].description = newText;
    }

    setItems(settings.openEndedQuestions);
    setSettings({ ...settings });
  };

  const deleteField = (itemId) => {
    if (user?.user?.clinic?.businessPlan?.id != 1) {
      const index = settings.openEndedQuestions.findIndex(
        (i) => i.id === itemId
      );
      if (index !== -1) {
        settings.openEndedQuestions[index].isActive =
          !settings.openEndedQuestions[index].isActive;
      }

      setItems(settings.openEndedQuestions);
      setSettings({ ...settings });
      let type = "deleteField";
      if (settings.openEndedQuestions[index].isActive) {
        type = "enableField";
      }

      const data = {
        showPopup: true,
        data: {
          title:
            type === "deleteField"
              ? i18n.t("confirmDeleteField")
              : i18n.t("confirmEnableField"),
          confirmButton:
            type === "deleteField"
              ? i18n.t("confirmSurveyOpenDeleteButton")
              : i18n.t("buttonActivate"),
          denyButton: i18n.t("denySurveyDeleteButton"),
          type: type,
          data: settings,
          index: index,
        },
      };

      dispatch(showPopup(data));
    }
  };

  const [introScreen, setIntroScreen] = useState(false);
  const [stressScreen, setStressScreen] = useState(false);
  const [introScreenSecond, setIntroScreenSecond] = useState(false);
  const [patientProfileScreen, setPatientProfile] = useState(false);
  const [healthConcerns, setHealthConcerns] = useState(false);
  const [healthConcernsSecond, setHealthConcernsSecond] = useState(false);
  const [healthImpovements, setHealthImpovements] = useState(false);
  const [lifeImpact, setLifeImpact] = useState(false);
  const [healthImpovementsSecond, setHealthImpovementsSecond] = useState(false);
  const [thankYouScreen, setThankYouScreen] = useState(false);
  const [thankYouScreenSecond, setThankYouScreenSecond] = useState(false);

  const handleIntroScreen = () => {
    setIntroScreen(true);
  };

  const handleIntroScreenClose = () => {
    setIntroScreen(false);
  };

  const handleStressScreen = () => {
    setStressScreen(true);
  };

  const handleStressScreenClose = () => {
    setStressScreen(false);
  };

  const handleIntroScreenSecond = () => {
    setIntroScreenSecond(true);
  };

  const handleIntroScreenSecondClose = () => {
    setIntroScreenSecond(false);
  };

  const handlePatientProfile = () => {
    setPatientProfile(true);
  };

  const handlePatientProfileClose = () => {
    setPatientProfile(false);
  };

  const handleHealthConcerns = () => {
    setHealthConcerns(true);
  };

  const handleHealthConcernsClose = () => {
    setHealthConcerns(false);
  };

  const handleHealthConcernsSecond = () => {
    setHealthConcernsSecond(true);
  };

  const handleHealthConcernsCloseSecond = () => {
    setHealthConcernsSecond(false);
  };

  const handleHealthImpovements = () => {
    setHealthImpovements(true);
  };

  const handleHealthImpovementsClose = () => {
    setHealthImpovements(false);
  };

  const handleLifeImpactImpovements = () => {
    setLifeImpact(true);
  };

  const handleLifeImpactImpovementsClose = () => {
    setLifeImpact(false);
  };

  const handleHealthImpovementsSecond = () => {
    setHealthImpovementsSecond(true);
  };

  const handleHealthImpovementsCloseSecond = () => {
    setHealthImpovementsSecond(false);
  };

  const handleThanksYouScreen = () => {
    setThankYouScreen(true);
  };

  const handleThanksYouScreenClose = () => {
    setThankYouScreen(false);
  };

  const handleThanksYouScreenSecond = () => {
    setThankYouScreenSecond(true);
  };

  const handleThanksYouScreenCloseSecond = () => {
    setThankYouScreenSecond(false);
  };

  const checkIfPossibleToEdit = (functionName) => {
    let possibleToEdit = false;
    if (user?.user?.clinic?.businessPlan?.id != 1) {
      possibleToEdit = true;
    }

    if (!possibleToEdit) {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("changePricePlan"),
          confirmButton: i18n.t("changePricePlanButton"),
          denyButton: i18n.t("cancel"),
          type: "changePricePlan",
        },
      };

      const dataUser = {
        showPopup: true,
        data: {
          title: i18n.t("noPermision"),
          confirmButton: i18n.t("ok"),
          type: "userPopup",
        },
      };

      console.log("user type    ", user?.user?.type);

      if (userSetting == 2) {
        dispatch(showPopup(data));
      }

      if (userSetting == 3) {
        dispatch(showPopup(dataUser));
      }
    } else {
      switch (functionName) {
        case "handleIntroScreen":
          handleIntroScreen(true);
          break;
        case "handleStressScreen":
          handleStressScreen(true);
          break;
        case "handlePatientProfile":
          handlePatientProfile(true);
          break;
        case "handleHealthConcerns":
          handleHealthConcerns(true);
          break;
        case "handleHealthImpovements":
          handleHealthImpovements(true);
          break;
        case "handleLifeImpactImpovements":
          handleLifeImpactImpovements(true);
          break;
        case "handleThanksYouScreen":
          handleThanksYouScreen(true);
          break;
        case "handleIntroScreenSecond":
          handleIntroScreenSecond(true);
          break;
        case "handleHealthConcernsSecond":
          handleHealthConcernsSecond(true);
          break;
        case "handleHealthImpovementsSecond":
          handleHealthImpovementsSecond(true);
          break;
        case "handleLifeImpactImpovementsClose":
          handleLifeImpactImpovementsClose(true);
          break;
        case "handleThanksYouScreenSecond":
          handleThanksYouScreenSecond(true);
          break;
      }
    }
  };

  const savePopup = () => {
    updateSettingsApi();
    setIntroScreen(false);
    setStressScreen(false);
    setIntroScreenSecond(false);
    setPatientProfile(false);
    setHealthConcernsSecond(false);

    setHealthImpovementsSecond(false);
    setThankYouScreen(false);
    setThankYouScreenSecond(false);
  };

  const handleUpdateHealthConcerns = async () => {
    try {
      return await updateHealthConcerns(healthConcernsFormData, user).then(
        (res) => {
          showGlobalSnackbar(i18n.t("saved"));
          dispatch(addUser(res.data.data));
          setHealthConcerns(false);
        }
      );
    } catch (error) {
      setToGenerateLink(false);
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const handleUpdateOverallHealth = async () => {
    try {
      return await updateOverallHealth(overallHealthFormData, user).then(
        (res) => {
          showGlobalSnackbar(i18n.t("saved"));
          dispatch(addUser(res.data.data));
          setHealthImpovements(false);
        }
      );
    } catch (error) {
      setToGenerateLink(false);
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const handleUpdateLifeImpactHealth = async () => {
    try {
      return await updateLifeImpact(lifeImpactFormData, user).then((res) => {
        showGlobalSnackbar(i18n.t("saved"));
        dispatch(addUser(res.data.data));
        setLifeImpact(false);
      });
    } catch (error) {
      setToGenerateLink(false);
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const handleBasicPlan = () => {
    navigate("/dashboard/price-plans");
  };

  const changeReviewLinkBasic = () => {
    const data = {
      showPopup: true,
      data: {
        title: "This feature is available in the Advanced subscription",
        confirmButton: "Upgrade price plan",
        denyButton: "Cancel",
        type: "linkReviewBasic",
      },
    };
    dispatch(showPopup(data));
  };

  useEffect(() => {
    setIsCheckedClinic(settings.send_clinic_mail || false);
  }, [settings.send_clinic_mail]);

  const handleCheckboxChangeClinic = () => {
    if (user.user.clinic.businessPlan.name === "Advanced") {
      setIsCheckedClinic(!isCheckedClinic);
    } else {
      changeReviewLinkBasic();
    }
  };

  useEffect(() => {
    setIsChecked(settings.sendMail || false);
  }, [settings.sendMail]);

  const handleCheckboxChange = () => {
    if (user.user.clinic.businessPlan.name === "Advanced") {
      setIsChecked(!isChecked);
    } else {
      changeReviewLinkBasic();
    }
  };

  const updateBodyMetrics = (updatedBodyMetrics) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      // Map through the bodyMetrics array and replace the updated ones
      bodyMetrics: prevSettings.bodyMetrics.map((bodyMetric) => {
        // Find the matching updated body metric by ID
        const updatedMetric = updatedBodyMetrics.find(
          (updatedMetric) => updatedMetric.id === bodyMetric.id
        );
        // If an updated metric exists, return it; otherwise, return the original body metric
        return updatedMetric ? updatedMetric : bodyMetric;
      }),
    }));
    getMeData();

    console.log(updatedBodyMetrics, "updatedBodyMetrics");
  };

  const updateUser = (user) => {
    const newSetting = user.clinic.surveySettings.filter(
      (s) => s.type === settings.type && s.language.id === settings.language.id
    );

    if (newSetting[0]) {
      settings = newSetting[0];
      setSettings(settings);
    }
  };

  useEffect(() => {
    setHealthConcernsFormData(
      user.user.clinic.healthConcerns.find(
        (item) => item.language.id === settings?.language?.id
      )
    );

    setOverallHealthFormData(
      user.user.clinic.overallHealths.find(
        (item) => item.language.id === settings?.language?.id
      )
    );

    setLifeImpactFormData(
      user.user.clinic.lifeImpacts.find(
        (item) => item.language.id === settings?.language?.id
      )
    );
  }, [settings]);



  return (
    <div className="SurveySettings">
      <Headline
        sx={{ marginBottom: "60px", textAlign: "left !important;" }}
        text={i18n.t("surveySettings")}
        type={"Headline-big-left"}
      />
      {user?.user?.clinic?.businessPlan?.id != 1 ? (
        <div className={"settingSection"}>
          <Headline text={i18n.t("surveyLanguage")} type={"Headline-medium"} />
          <div className={"dropdownContainer"}>
            <Dropdown
              type={"language"}
              setSelectedOption={(opt) => updateLanguage(opt)}
              selectedOption={settings?.language}
              options={user.user.clinic.languages}
              app={app}
              placeholder={i18n.t("surveyLanguage")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={"settingSection"}>
        <Headline text={i18n.t("typeOfSurvey")} type={"Headline-medium"} />
        <div className={"dropdownContainer"}>
          <Dropdown
            type={"settingType"}
            setSelectedOption={(opt) => updateType(opt)}
            selectedOption={settings?.type}
            options={settingTypes}
            app={app}
            placeholder={i18n.t("typeOfSurvey")}
          />
        </div>
      </div>
      <div className={"headline-settings"}>
        <Headline
          text={i18n.t("screenPersonalization")}
          type={"Headline-big-left"}
        />{" "}
      </div>
      <div className="wrapper">
        <Accordion title={settings.type === 3 ? "Survey 1-6" : "Survey 1"}>
          <div
            className="dropdownTitle"
            onClick={() => setScreenDropdown2(!screenDropdown2)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={
                !screenDropdown2
                  ? "/assets/downArrow.svg"
                  : "/assets/upArrow.svg"
              }
              alt="arrow"
              style={{ marginRight: "14px" }}
            />
            {i18n.t("Screens")}
          </div>

          {screenDropdown2 && (
            <ul className="dropdownList">
              <li>
                <a onClick={() => checkIfPossibleToEdit("handleIntroScreen")}>
                  {i18n.t("introScreen")}{" "}
                </a>
              </li>
              <li>
                <a
                  onClick={() => checkIfPossibleToEdit("handlePatientProfile")}
                >
                  {i18n.t("patientProfile")}{" "}
                </a>
              </li>
              {settings.type === 2 && (
                <li>
                  <a
                    onClick={() => checkIfPossibleToEdit("handleStressScreen")}
                  >
                    {i18n.t("stressScreen")}
                  </a>
                </li>
              )}
              <li>
                <a
                  onClick={() => checkIfPossibleToEdit("handleHealthConcerns")}
                >
                  {i18n.t("topThreeHealthConcerns")}{" "}
                </a>
              </li>
              <li>
                <a
                  onClick={() =>
                    checkIfPossibleToEdit("handleLifeImpactImpovements")
                  }
                >
                  {i18n.t("LifeImpact")}
                </a>
              </li>
              <li>
                <a
                  onClick={() =>
                    checkIfPossibleToEdit("handleHealthImpovements")
                  }
                >
                  {i18n.t("globalHealthImprovements")}{" "}
                </a>
              </li>

              <li>
                <a
                  onClick={() => checkIfPossibleToEdit("handleThanksYouScreen")}
                >
                  {i18n.t("thankYouScreen")}{" "}
                </a>
              </li>
            </ul>
          )}

          {settings.type === 3 && (
            <>
              {/* New */}
              <ul
                className="dropdownBottomTitles"
                style={{ marginBottom: "8px" }}
              >
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenHealth(!openHealth)}
                  >
                    <img
                      src={
                        !openHealth
                          ? "/assets/downArrow.svg"
                          : "/assets/upArrow.svg"
                      }
                      alt="arrow"
                      style={{ marginRight: "14px" }}
                    />
                    {i18n.t("healthMetrics")}{" "}
                  </div>

                  {openHealth && (
                    <div className="healthMetricsQuestions">
                      {/* <p>
                        You have the possibility to edit the Global Health
                        Improvement.
                      </p> */}
                      <TextInput
                          type={"text"}
                          setValue={(value) => updateHealthMetricsPdfTitle(value)}
                          label="PDF Title"
                          value={settings?.healthMetricsPdfTitle}
                          mode={"grey"}
                          errorMessage={healthMetricsPdfTitleError}
                      />

                      <HealthMetricsQuestions
                        items={settings.bodyMetrics}
                        updateBodyMetrics={updateBodyMetrics}
                        answersLang={answersLang}
                        businessPlan={user?.user?.clinic?.businessPlan?.id}
                        userType={user?.user?.type}
                        showSet={settings.language}
                        allSet={settings}
                        surveySettingId={settings.id}
                        surveySettings={settings}
                        updateUser={updateUser}
                        showGlobalSnackbar={showGlobalSnackbar}
                      />
                    </div>
                  )}
                </li>
              </ul>

              <div
                className="dropdownTitleCustomizable"
                onClick={() => setOpenCustom(!openCustom)}
              >
                <img
                  src={
                    !openCustom
                      ? "/assets/downArrow.svg"
                      : "/assets/upArrow.svg"
                  }
                  alt="arrow"
                  style={{ marginRight: "11px" }}
                />{" "}
                {i18n.t("customizibleQuestions")}
              </div>
              {openCustom && (
                  <>
                    {/* <div className="dropdownParagraphCustomizable">
                    {i18n.t("customizibleQuestions")}
                  </div> */}
                    <div className="dropdownParagraphCustomizable">
                      Select the question and description you want to edit or
                      delete.
                    </div>

                    <CustomisableQuestion
                        openEndedSectionTitle={settings.openEndedSectionTitle}
                        updateOpenEndedSectionTitle={updateOpenEndedSectionTitle}
                        openEndedSectionTitleError={openEndedSectionTitleError}
                        items={settings.openEndedQuestions}
                        updateItemTitle={updateItemTitle}
                        updateItemText={updateItemText}
                        handleDeleteField={deleteField}
                        answersLang={answersLang}
                        businessPlan={user?.user?.clinic?.businessPlan?.id}
                        userType={user?.user?.type}
                    />
                  </>
              )}
            </>
          )}

          {settings?.type === 3 ? (
            ""
          ) : (
              <ul className="dropdownBottomTitles">
              <li id={`tooltip_1`}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenHealth(!openHealth)}
                >
                  <img
                    src={
                      !openHealth
                        ? "/assets/downArrow.svg"
                        : "/assets/upArrow.svg"
                    }
                    alt="arrow"
                    style={{ marginRight: "14px" }}
                  />
                  {i18n.t("healthMetrics")}{" "}
                </div>

                {openHealth && (
                  <div className="healthMetricsQuestions">
                    {/* <p>
                      You have the possibility to edit the Global Health
                      Improvement.
                    </p> */}
                    <TextInput
                        type={"text"}
                        setValue={(value) => updateHealthMetricsPdfTitle(value)}
                        label="PDF Title"
                        value={settings?.healthMetricsPdfTitle}
                        mode={"grey"}
                        errorMessage={healthMetricsPdfTitleError}
                    />

                    <HealthMetricsQuestions
                      items={settings.bodyMetrics}
                      updateBodyMetrics={updateBodyMetrics}
                      answersLang={answersLang}
                      businessPlan={user?.user?.clinic?.businessPlan?.id}
                      userType={user?.user?.type}
                      showSet={settings.language}
                      allSet={settings}
                      surveySettingId={settings.id}
                      surveySettings={settings}
                      updateUser={updateUser}
                      showGlobalSnackbar={showGlobalSnackbar}
                    />
                  </div>
                )}
              </li>

              {/* {settings.type === 1 ? (
                ""
              ) : (
                <> */}
              <li id={`tooltip_2`}>
                <img
                  src={"/assets/downArrow.svg"}
                  style={{ marginRight: "14px" }}
                  alt="arrow"
                />
                {i18n.t("stresses")}{" "}
                <ReactTooltip
                  anchorId={`tooltip_2`}
                  place="right"
                  content={"Coming soon"}
                />
              </li>
              <li id={`tooltip_3`}>
                <img
                  src={"/assets/downArrow.svg"}
                  style={{ marginRight: "14px" }}
                  alt="arrow"
                />
                {i18n.t("valueAdd")}{" "}
                <ReactTooltip
                  anchorId={`tooltip_3`}
                  place="right"
                  content={"Coming soon"}
                />
              </li>
              {/* </>
              )} */}
            </ul>
          )}
        </Accordion>

        {introScreen && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("introScreen")}</h2>
              <p>{i18n.t("fillBlankIntro")}</p>
              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) => updateSettings(val, "introHeadline")}
                  errorMessage={
                    settings.introHeadline?.length >= 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.introHeadline}
                />
              </Box>
              <Box className={"textContainer"}>
                <TextInput
                  rows={7}
                  label={i18n.t("supportText")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourSupportTextHere")}
                  type={"textarea"}
                  setValue={(val) => updateSettings(val, "introText")}
                  disabled={user.user.type === 3}
                  maxlength="450"
                  errorMessage={
                    settings.introText?.length >= 450
                      ? "450 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.introText}
                />
              </Box>
              {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                <Box className={"videoLinkContainer"}>
                  <TextInput
                    label={i18n.t("videoLink")}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourVideoLinkHere")}
                    type={"text"}
                    disabled={user.user.type === 3}
                    setValue={(val) => updateSettings(val, "introVideo")}
                    value={settings.introVideo}
                  />
                </Box>
              ) : null}
              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button className="closeForm" onClick={handleIntroScreenClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {stressScreen && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("stressScreen")}</h2>
              <p>{i18n.t("fillBlankStress")}</p>
              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) => updateSettings(val, "stressesHeadline")}
                  errorMessage={
                    settings.stressesHeadline?.length >= 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.stressesHeadline}
                />
              </Box>
              <Box className={"textContainer"}>
                <TextInput
                  rows={7}
                  label={i18n.t("supportText")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourSupportTextHere")}
                  type={"textarea"}
                  setValue={(val) => updateSettings(val, "stressesText")}
                  disabled={user.user.type === 3}
                  maxlength="450"
                  errorMessage={
                    settings.stressesText?.length >= 450
                      ? "450 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.stressesText}
                />
              </Box>
              {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                <Box className={"videoLinkContainer"}>
                  <TextInput
                    label={i18n.t("videoLink")}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourVideoLinkHere")}
                    type={"text"}
                    disabled={user.user.type === 3}
                    setValue={(val) => updateSettings(val, "stressesVideo")}
                    value={settings.stressesVideo}
                  />
                </Box>
              ) : null}
              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button className="closeForm" onClick={handleStressScreenClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {patientProfileScreen && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("patientProfile")}</h2>
              <p>{i18n.t("fillBlankPatient")}</p>
              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) => updateSettings(val, "patientHeadline")}
                  errorMessage={
                    settings.patientHeadline?.length > 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.patientHeadline}
                />
              </Box>

              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button
                  className="closeForm"
                  onClick={handlePatientProfileClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {healthConcerns && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <div style={{ height: "600px", overflow: "scroll" }}>
                <h2>{i18n.t("topThreeHealthConcerns")}</h2>
                <p>{i18n.t("fillBlankPatientHealthConcerns")}</p>

                <Box className={"titleContainer"}>
                  <TextInput
                    label={i18n.t("headline")}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourHeadlineHere")}
                    type={"text"}
                    disabled={user.user.type === 3}
                    setValue={(val) =>
                      setHealthConcernsFormData({
                        ...healthConcernsFormData,
                        title: val,
                      })
                    }
                    errorMessage={
                      healthConcernsFormData?.title?.length > 30
                        ? "30 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={healthConcernsFormData?.title}
                  />
                </Box>
                <Box className={"textContainer"}>
                  <TextInput
                    rows={7}
                    label={i18n.t("supportText")}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourSupportTextHere")}
                    type={"textarea"}
                    disabled={user.user.type === 3}
                    setValue={(val) =>
                      setHealthConcernsFormData({
                        ...healthConcernsFormData,
                        desc: val,
                      })
                    }
                    maxlength="450"
                    errorMessage={
                      healthConcernsFormData?.desc?.length >= 450
                        ? "450 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={healthConcernsFormData?.desc}
                  />
                </Box>

                {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                  <Box className={"videoLinkContainer"}>
                    <TextInput
                      label={i18n.t("videoLink")}
                      mode={"white"}
                      disabled={user.user.type === 3}
                      placeholder={i18n.t("typeOutYourVideoLinkHere")}
                      type={"text"}
                      setValue={(val) =>
                        setHealthConcernsFormData({
                          ...healthConcernsFormData,
                          videoUrl: val,
                        })
                      }
                      value={healthConcernsFormData?.videoUrl}
                    />
                  </Box>
                ) : null}

                <Box className={"textContainer"}>
                  <TextInput
                    rows={7}
                    label={`PDF ${i18n.t("Title")}`}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourSupportTextHere")}
                    type={"text"}
                    disabled={user.user.type === 3}
                    setValue={(val) =>
                      setHealthConcernsFormData({
                        ...healthConcernsFormData,
                        pdfTitle: val,
                      })
                    }
                    maxlength="30"
                    errorMessage={
                      healthConcernsFormData?.pdfTitle?.length >= 30
                        ? "30 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={healthConcernsFormData?.pdfTitle}
                  />
                </Box>

                <QuestionPropertiesForm
                  formData={healthConcernsFormData}
                  setFormData={setHealthConcernsFormData}
                  questionNumber={1}
                />

                <QuestionPropertiesForm
                  formData={healthConcernsFormData}
                  setFormData={setHealthConcernsFormData}
                  questionNumber={2}
                />

                <QuestionPropertiesForm
                  formData={healthConcernsFormData}
                  setFormData={setHealthConcernsFormData}
                  questionNumber={3}
                />

                <div className="screenButtonWrapper">
                  <button
                    className="saveForm"
                    onClick={() => handleUpdateHealthConcerns()}
                  >
                    Save
                  </button>
                  <button
                    className="closeForm"
                    onClick={handleHealthConcernsClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {healthImpovements && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <div style={{ height: "600px", overflow: "scroll" }}>
                <h2>{i18n.t("globalHealthImprovements")}</h2>
                <p>{i18n.t("fillBlankPatientHealthImprovements")}</p>

                <Box className={"titleContainer"}>
                  <TextInput
                    label={i18n.t("headline")}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourHeadlineHere")}
                    type={"text"}
                    disabled={user.user.type === 3}
                    setValue={(val) =>
                      setOverallHealthFormData({
                        ...overallHealthFormData,
                        title: val,
                      })
                    }
                    errorMessage={
                      settings.globalHeadline?.length > 30
                        ? "30 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={overallHealthFormData?.title}
                  />
                </Box>
                <Box className={"textContainer"}>
                  <TextInput
                    rows={7}
                    label={i18n.t("supportText")}
                    mode={"white"}
                    disabled={user.user.type === 3}
                    placeholder={i18n.t("typeOutYourSupportTextHere")}
                    type={"textarea"}
                    setValue={(val) =>
                      setOverallHealthFormData({
                        ...overallHealthFormData,
                        desc: val,
                      })
                    }
                    maxlength="255"
                    errorMessage={
                      settings.globalText?.length >= 255
                        ? "255 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={overallHealthFormData?.desc}
                  />
                </Box>

                {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                  <Box className={"videoLinkContainer"}>
                    <TextInput
                      label={i18n.t("videoLink")}
                      mode={"white"}
                      disabled={user.user.type === 3}
                      placeholder={i18n.t("typeOutYourVideoLinkHere")}
                      type={"text"}
                      setValue={(val) =>
                        setOverallHealthFormData({
                          ...overallHealthFormData,
                          videoUrl: val,
                        })
                      }
                      value={overallHealthFormData?.videoUrl}
                    />
                  </Box>
                ) : null}

                <QuestionPropertiesForm
                  formData={overallHealthFormData}
                  setFormData={setOverallHealthFormData}
                  questionNumber={1}
                />

                <QuestionPropertiesForm
                  formData={overallHealthFormData}
                  setFormData={setOverallHealthFormData}
                  questionNumber={2}
                />

                <div className="screenButtonWrapper">
                  <button
                    className="saveForm"
                    onClick={() => handleUpdateOverallHealth()}
                  >
                    Save
                  </button>
                  <button
                    className="closeForm"
                    onClick={handleHealthImpovementsClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {lifeImpact && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <div style={{ height: "600px", overflow: "scroll" }}>
                <h2>{i18n.t("LifeImpact")}</h2>

                <Box className={"titleContainer"}>
                  <TextInput
                    label={`PDF ${i18n.t("Title")}`}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourHeadlineHere")}
                    type={"text"}
                    disabled={user.user.type === 3}
                    setValue={(val) =>
                      setLifeImpactFormData({
                        ...lifeImpactFormData,
                        pdfTitle: val,
                      })
                    }
                    errorMessage={
                      lifeImpactFormData?.pdfTitle?.length > 30
                        ? "30 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={lifeImpactFormData?.pdfTitle}
                  />
                </Box>
                <Box className={"textContainer"}>
                  <TextInput
                    rows={7}
                    label={`PDF ${i18n.t("From")}`}
                    mode={"white"}
                    disabled={user.user.type === 3}
                    placeholder={i18n.t("typeOutYourSupportTextHere")}
                    type={"text"}
                    setValue={(val) =>
                      setLifeImpactFormData({
                        ...lifeImpactFormData,
                        pdfFrom: val,
                      })
                    }
                    maxlength="255"
                    errorMessage={
                      lifeImpactFormData?.pdfFrom?.length >= 255
                        ? "255 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={lifeImpactFormData?.pdfFrom}
                  />
                </Box>
                <Box className={"textContainer"}>
                  <TextInput
                    rows={7}
                    label={`PDF ${i18n.t("To")}`}
                    mode={"text"}
                    disabled={user.user.type === 3}
                    placeholder={i18n.t("typeOutYourSupportTextHere")}
                    type={"text"}
                    setValue={(val) =>
                      setLifeImpactFormData({
                        ...lifeImpactFormData,
                        pdfTo: val,
                      })
                    }
                    maxlength="255"
                    errorMessage={
                      lifeImpactFormData?.pdfTo?.length >= 255
                        ? "255 " + i18n.t("characterLimitations")
                        : null
                    }
                    value={lifeImpactFormData?.pdfTo}
                  />
                </Box>

                <QuestionPropertiesForm
                  formData={lifeImpactFormData}
                  setFormData={setLifeImpactFormData}
                  questionNumber={1}
                />

                <div className="screenButtonWrapper">
                  <button
                    className="saveForm"
                    onClick={() => handleUpdateLifeImpactHealth()}
                  >
                    Save
                  </button>
                  <button
                    className="closeForm"
                    onClick={handleLifeImpactImpovementsClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {thankYouScreen && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("thankYouScreen")}</h2>
              <p>{i18n.t("fillBlankThankYouScreen")}</p>
              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) => updateSettings(val, "thankHeadline")}
                  errorMessage={
                    settings.thankHeadline?.length > 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.thankHeadline}
                />
              </Box>
              <Box className={"textContainer"}>
                <TextInput
                  rows={7}
                  label={i18n.t("supportText")}
                  mode={"white"}
                  disabled={user.user.type === 3}
                  placeholder={i18n.t("typeOutYourSupportTextHere")}
                  type={"textarea"}
                  setValue={(val) => updateSettings(val, "thankText")}
                  maxlength="450"
                  errorMessage={
                    settings.thankText?.length >= 450
                      ? "450 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.thankText}
                />
              </Box>

              {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                <Box className={"videoLinkContainer"}>
                  <TextInput
                    label={i18n.t("videoLink")}
                    mode={"white"}
                    disabled={user.user.type === 3}
                    placeholder={i18n.t("typeOutYourVideoLinkHere")}
                    type={"text"}
                    setValue={(val) => updateSettings(val, "thankVideo")}
                    value={settings.thankVideo}
                  />
                </Box>
              ) : null}
              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button
                  className="closeForm"
                  onClick={handleThanksYouScreenClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {settings.type === 3 ? (
          ""
        ) : (
          // Screen dropdown
          <Accordion title="Survey 2-6">
            <div
              className="dropdownTitle"
              onClick={() => setScreenDropdown1(!screenDropdown1)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={
                  !screenDropdown1
                    ? "/assets/downArrow.svg"
                    : "/assets/upArrow.svg"
                }
                alt="arrow"
                style={{ marginRight: "19px" }}
              />
              {i18n.t("Screens")}
            </div>

            {screenDropdown1 && (
              <ul className="dropdownList">
                <li>
                  <a
                    onClick={() =>
                      checkIfPossibleToEdit("handleIntroScreenSecond")
                    }
                  >
                    {i18n.t("introScreen")}{" "}
                  </a>
                </li>
                {/*<li>*/}
                {/*  <a*/}
                {/*    onClick={() =>*/}
                {/*      checkIfPossibleToEdit("handleHealthConcernsSecond")*/}
                {/*    }*/}
                {/*  >*/}
                {/*    {i18n.t("topThreeHealthConcerns")}{" "}*/}
                {/*  </a>*/}
                {/*</li>*/}

                {/*<li>*/}
                {/*  <a*/}
                {/*    onClick={() =>*/}
                {/*      checkIfPossibleToEdit("handleHealthImpovementsSecond")*/}
                {/*    }*/}
                {/*  >*/}
                {/*    {i18n.t("globalHealthImprovements")}{" "}*/}
                {/*  </a>*/}
                {/*</li>*/}
                <li>
                  <a
                    onClick={() =>
                      checkIfPossibleToEdit("handleThanksYouScreenSecond")
                    }
                  >
                    {i18n.t("thankYouScreen")}{" "}
                  </a>
                </li>
              </ul>
            )}

            <div
              className="dropdownTitleCustomizable"
              onClick={() => setOpenCustom(!openCustom)}
            >
              <img
                src={
                  !openCustom ? "/assets/downArrow.svg" : "/assets/upArrow.svg"
                }
                style={{ marginRight: "14px" }}
                alt="arrow"
              />{" "}
              {i18n.t("customizibleQuestions")}
            </div>

            {openCustom && (
              <>
                <div className="dropdownParagraphCustomizable">
                  Select the question and description you want to edit or
                  delete.
                </div>

                <CustomisableQuestion
                    openEndedSectionTitle={settings?.openEndedSectionTitle}
                    updateOpenEndedSectionTitle={updateOpenEndedSectionTitle}
                    openEndedSectionTitleError={openEndedSectionTitleError}
                  items={settings.openEndedQuestions}
                  updateItemTitle={updateItemTitle}
                  updateItemText={updateItemText}
                  handleDeleteField={deleteField}
                  answersLang={answersLang}
                  businessPlan={user?.user?.clinic?.businessPlan?.id}
                  userType={user?.user?.type}
                />
              </>
            )}
          </Accordion>
        )}

        {introScreenSecond && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("introScreen")}</h2>
              <p>{i18n.t("fillBlankIntro")}</p>
              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) => updateSettings(val, "nextIntroHeadline")}
                  maxlength="30"
                  errorMessage={
                    settings.nextIntroHeadline?.length >= 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextIntroHeadline}
                />
              </Box>
              <Box className={"textContainer"}>
                <TextInput
                  rows={7}
                  label={i18n.t("supportText")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourSupportTextHere")}
                  type={"textarea"}
                  setValue={(val) => updateSettings(val, "nextIntroText")}
                  disabled={user.user.type === 3}
                  maxlength="450"
                  errorMessage={
                    settings.nextIntroText?.length >= 450
                      ? "450 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextIntroText}
                />
              </Box>

              {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                <Box className={"videoLinkContainer"}>
                  <TextInput
                    label={i18n.t("videoLink")}
                    mode={"white"}
                    placeholder={i18n.t("typeOutYourVideoLinkHere")}
                    type={"text"}
                    disabled={user.user.type === 3}
                    setValue={(val) => updateSettings(val, "nextIntroVideo")}
                    value={settings.nextIntroVideo}
                  />
                </Box>
              ) : null}
              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button
                  className="closeForm"
                  onClick={handleIntroScreenSecondClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {healthConcernsSecond && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("topThreeHealthConcerns")}</h2>
              <p>{i18n.t("fillBlankPatientHealthConcerns")}</p>

              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) =>
                    updateSettings(val, "nextTopThreeHeadline")
                  }
                  errorMessage={
                    settings.nextTopThreeHeadline?.length > 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextTopThreeHeadline}
                />
              </Box>
              <Box className={"textContainer"}>
                <TextInput
                  rows={7}
                  label={i18n.t("supportText")}
                  mode={"white"}
                  disabled={user.user.type === 3}
                  placeholder={i18n.t("typeOutYourSupportTextHere")}
                  type={"textarea"}
                  setValue={(val) => updateSettings(val, "nextTopThreeText")}
                  maxlength="450"
                  errorMessage={
                    settings.nextTopThreeText?.length >= 450
                      ? "450 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextTopThreeText}
                />
              </Box>

              {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                <Box className={"videoLinkContainer"}>
                  <TextInput
                    label={i18n.t("videoLink")}
                    mode={"white"}
                    disabled={user.user.type === 3}
                    placeholder={i18n.t("typeOutYourVideoLinkHere")}
                    type={"text"}
                    setValue={(val) => updateSettings(val, "nextTopThreeVideo")}
                    value={settings.nextTopThreeVideo}
                  />
                </Box>
              ) : null}
              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button
                  className="closeForm"
                  onClick={handleHealthConcernsCloseSecond}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {healthImpovementsSecond && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("globalHealthImprovements")}</h2>
              <p>{i18n.t("fillBlankPatientHealthImprovements")}</p>

              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) => updateSettings(val, "nextGlobalHeadline")}
                  errorMessage={
                    settings.nextGlobalHeadline?.length > 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextGlobalHeadline}
                />
              </Box>
              <Box className={"textContainer"}>
                <TextInput
                  rows={7}
                  label={i18n.t("supportText")}
                  mode={"white"}
                  disabled={user.user.type === 3}
                  placeholder={i18n.t("typeOutYourSupportTextHere")}
                  type={"textarea"}
                  setValue={(val) => updateSettings(val, "nextGlobalText")}
                  maxlength="450"
                  errorMessage={
                    settings.nextGlobalText?.length >= 450
                      ? "450 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextGlobalText}
                />
              </Box>

              {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                <Box className={"videoLinkContainer"}>
                  <TextInput
                    label={i18n.t("videoLink")}
                    mode={"white"}
                    disabled={user.user.type === 3}
                    placeholder={i18n.t("typeOutYourVideoLinkHere")}
                    type={"text"}
                    setValue={(val) => updateSettings(val, "nextGlobalVideo")}
                    value={settings.nextGlobalVideo}
                  />
                </Box>
              ) : null}
              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button
                  className="closeForm"
                  onClick={handleHealthImpovementsCloseSecond}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {thankYouScreenSecond && (
          <div className="ScreenOverlay">
            <div className="ScreenContent">
              <h2>{i18n.t("thankYouScreen")}</h2>
              <p>{i18n.t("fillBlankThankYouScreen")}</p>

              <Box className={"titleContainer"}>
                <TextInput
                  label={i18n.t("headline")}
                  mode={"white"}
                  placeholder={i18n.t("typeOutYourHeadlineHere")}
                  type={"text"}
                  disabled={user.user.type === 3}
                  setValue={(val) => updateSettings(val, "nextThankHeadline")}
                  errorMessage={
                    settings.nextThankHeadline?.length > 30
                      ? "30 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextThankHeadline}
                />
              </Box>
              <Box className={"textContainer"}>
                <TextInput
                  rows={7}
                  label={i18n.t("supportText")}
                  mode={"white"}
                  disabled={user.user.type === 3}
                  placeholder={i18n.t("typeOutYourSupportTextHere")}
                  type={"textarea"}
                  setValue={(val) => updateSettings(val, "nextThankText")}
                  maxlength="450"
                  errorMessage={
                    settings.nextThankText?.length >= 450
                      ? "450 " + i18n.t("characterLimitations")
                      : null
                  }
                  value={settings.nextThankText}
                />
              </Box>

              {settings.type && user.user.clinic.businessPlan.id == 2 ? (
                <Box className={"videoLinkContainer"}>
                  <TextInput
                    label={i18n.t("videoLink")}
                    mode={"white"}
                    disabled={user.user.type === 3}
                    placeholder={i18n.t("typeOutYourVideoLinkHere")}
                    type={"text"}
                    setValue={(val) => updateSettings(val, "nextThankVideo")}
                    value={settings.nextThankVideo}
                  />
                </Box>
              ) : null}
              <div className="screenButtonWrapper">
                <button className="saveForm" onClick={savePopup}>
                  Save
                </button>
                <button
                  className="closeForm"
                  onClick={handleThanksYouScreenCloseSecond}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={"settingSection headline-settingsGeneration"}>
        <Headline
          text={i18n.t("surveyGeneration")}
          type={"Headline-big-left"}
        />
      </div>
      <Box className={"textContainer linkContainer surveyTitle"}>
        <TextInput
          label={i18n.t("surveyLink")}
          labelClass={"specificSurveyLink"}
          mode={"grey"}
          type={"text"}
          setValue={() => {}}
          disabled={true}
          value={
            process.env.REACT_APP_SURVEYLINK + "/survey/" + settings.surveyLink
          }
        />
        <div className={"linkButtonsContainer"}>
          <div
            className={"linkButtonContainer"}
            onClick={() => generateAndSave(true)}
          >
            <Button text={i18n.t("generateLink")} mode={"small"} />
          </div>

          <div className={"linkButtonContainer"} onClick={() => copyLink()}>
            <Button text={i18n.t("copyLink")} color={"white"} mode={"small"} />
          </div>
        </div>
      </Box>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />{" "}
        Automatically Send PDF Results to Patients
      </label>

      <div className="autoClinicEmail">
        <label>
          <input
            type="checkbox"
            checked={isCheckedClinic}
            onChange={handleCheckboxChangeClinic}
          />{" "}
          Automatically Send PDF Results to Clinic
        </label>
        {isCheckedClinic && (
          <TextInput
            type={"email"}
            label={i18n.t("Clinic email")}
            setValue={setEmail}
            value={email}
            mode={"grey"}
          />
        )}
      </div>
      {isCheckedClinic && !email ? (
        <div className={"saveContainer"} style={{ opacity: "0.5" }}>
          <Button text={i18n.t("saveChanges")} mode={"small"} />
        </div>
      ) : (
        <div className={"saveContainer"} onClick={() => save()}>
          <Button text={i18n.t("saveChanges")} mode={"small"} />
        </div>
      )}
      <div className={"settingSection"}></div>
      <div className={"settingSection"}></div>
    </div>
  );
};

export default SurveySettings;
