import React, {useEffect, useState} from 'react';
import './HealthConcernRate.scss';
import SingleRate from "../SingleRate/SingleRate";

const HealthConcernRate = ({
                               themeColor, i18n, rate, setRate, leftOption, rightOption, hasSpecificRate
                           }) => {
    const [rateCount, setRateCount] = useState(10);

    useEffect(() => {
       if (hasSpecificRate) {
           setRateCount(5);
       }
    }, [hasSpecificRate]);

    const clickedConcernRate = (index) => {
        if (rate === index + 1) {
            setRate(0);
        } else {
            setRate(index + 1);
        }
    }


    return (
        <div className="HealthConcernRate">
            <div className={"mobileHealthRateNames"}>
                <div className={"severenessText"}>{leftOption ?  `${1} \u2192 ${leftOption}` : null} </div>
                <div className={"severenessText"}>{rightOption ?  `${10} \u2192 ${rightOption}` : null} </div>
            </div>
            <div className={"healthRates"}>
                {[...Array(rateCount)].map((r, index) => {
                    return <div className={"rateContainer"} key={index} onClick={() => clickedConcernRate(index)}>
                        <SingleRate rate={index + 1} selectedRate={rate} themeColor={themeColor} hasSpecificRate={hasSpecificRate}
                                    status={rate === index + 1 && !hasSpecificRate ? 'selected' : null}/>
                        {hasSpecificRate ? <div className={"specificRateText"}> {index + 1} </div> : null}
                    </div>
                })}
            </div>
            <div className={"healthRateNames"}>
                <div className={"severenessText"}>{leftOption}</div>
                <div className={"severenessText"}>{rightOption}</div>
            </div>
        </div>
    );
}

export default HealthConcernRate;
