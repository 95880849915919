import React, { useEffect, useState } from "react";
import "./User.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  createUserApi,
  deleteUser,
  getUser,
  updateUserApi,
} from "../../../../core/axios/services";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Headline from "../../../components/Comon/Headline/Headline";
import { Box } from "@mui/material";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Button from "../../../components/Comon/Button/Button";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";
import Dropdown from "../../../components/Admin Panel/Dropdown/Dropdown";

const User = ({ showGlobalSnackbar }) => {
  const { id } = useParams();
  const myUser = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    surveys: [],
  });
  const [isNew, setIsNew] = useState(false);
  const [stringUser, setStringUser] = useState("");
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [roles, setRoles] = useState([
    { name: i18n.t("admin"), id: 2 },
    { name: i18n.t("user"), id: 3 },
  ]);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "user":
          updateUser();
          dispatch(resetPopUp());
          break;

        case "newUser":
          createUser();
          dispatch(resetPopUp());
          break;

        case "deleteUser":
          deleteUserData();
          dispatch(resetPopUp());
          break;

        case "userPrivilegies":
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [id, app]);

  useEffect(() => {
    if (parseInt(id) === 0) {
      setIsNew(true);
      user.type = 3;
      setUser({ ...user });
    } else {
      setIsNew(false);
    }

    if (parseInt(id) !== 0) {
      getUserData();
    }
  }, [id]);

  const updateUser = async () => {
    try {
      return await updateUserApi(JSON.parse(stringUser), myUser).then((res) => {
        setUser(res.data.data);
        showGlobalSnackbar(i18n.t("saved"));
      });
    } catch (error) {
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const createUser = async () => {
    try {
      return await createUserApi(JSON.parse(stringUser), myUser).then((res) => {
        showGlobalSnackbar(i18n.t("saved"));
        navigate("/dashboard/users");
      });
    } catch (error) {
      if (error.response.data.errorMessage) {
        showGlobalSnackbar(error.response.data.errorMessage);
      } else {
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const deleteUserData = async () => {
    try {
      return await deleteUser(user.id, myUser).then((res) =>
        navigate("/dashboard/users")
      );
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const getUserData = async () => {
    try {
      return await getUser(id, myUser).then((res) => {
        setUser(res.data.data);
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const changeName = (name) => {
    user.name = name;
    setUser({ ...user });
  };

  const changePhone = (phone) => {
    user.phone = phone;
    setUser({ ...user });
  };

  const changeEmail = (email) => {
    user.email = email;
    setUser({ ...user });
  };

  const changeFirstName = (firstName) => {
    user.firstName = firstName;
    setUser({ ...user });
  };

  const changeLastName = (lastName) => {
    user.lastName = lastName;
    setUser({ ...user });
  };

  const saveUser = () => {
    if (user.phone?.trim().length > 0) {
      if (!user.phone.includes("+")) {
        showGlobalSnackbar(i18n.t("pleaseInsertFullPhoneNumber"));
        return;
      }
    }
    setStringUser(JSON.stringify(user));
    let data = {};
    if (isNew) {
      data = {
        showPopup: true,
        data: {
          title: i18n.t("confirmNewUser"),
          confirmButton: i18n.t("confirmNewUserButton"),
          denyButton: i18n.t("denyNewUserButton"),
          type: "newUser",
        },
      };
    } else {
      data = {
        showPopup: true,
        data: {
          title: i18n.t("confirmUser"),
          confirmButton: i18n.t("confirmUserButton"),
          denyButton: i18n.t("denyUserButton"),
          type: "user",
        },
      };
    }

    dispatch(showPopup(data));
  };

  const deleteUserStart = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmUserDelete"),
        confirmButton: i18n.t("confirmUserDeleteButton"),
        denyButton: i18n.t("denyUserDeleteButton"),
        type: "deleteUser",
      },
    };
    dispatch(showPopup(data));
  };

  const changeRole = (selectedRole) => {
    user.type = selectedRole.id;
    setUser({ ...user });
  };

  const disableForThem = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("permission"),
        confirmButton: i18n.t("close"),
        type: "userPrivilegies",
      },
    };
    dispatch(showPopup(data));
  };

  return (
    <div className="User">
      {isNew ? (
        <Headline
          sx={{ marginBottom: "60px", textAlign: "left !important;" }}
          text={i18n.t("createNewUser")}
          type={"Headline-big-left"}
        />
      ) : (
        <Headline
          sx={{ marginBottom: "60px", textAlign: "left !important;" }}
          text={i18n.t("userProfileInformation") + " " + user.name}
          type={"Headline-big-left"}
        />
      )}

      <Box className={"sectionChange"}>
        <Headline
          text={i18n.t("userProfileOverview")}
          type={"Headline-medium"}
        />

        <Box className={"textInputContainer"}>
          <Box className={"bigTextInput"}>
            <TextInput
              type={"text"}
              setValue={(data) => changeName(data)}
              label={i18n.t("displayName")}
              value={user.name}
              mode={"grey"}
            />
          </Box>
          <Box className={"inputRow"}>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                setValue={(data) => changeFirstName(data)}
                label={i18n.t("firstName")}
                value={user.firstName}
                mode={"grey"}
              />
            </Box>
            <Box className={"smallTextInput"}>
              <TextInput
                sx={{ marginLeft: "24px" }}
                type={"text"}
                setValue={(data) => changeLastName(data)}
                label={i18n.t("lastName")}
                value={user.lastName}
                mode={"grey"}
              />
            </Box>
          </Box>
          <Box className={"inputRow"}>
            <Box className={"smallTextInput"}>
              <TextInput
                type={"text"}
                setValue={(data) => changeEmail(data)}
                label={i18n.t("email")}
                value={user.email}
                mode={"grey"}
              />
            </Box>
            <Box className={"smallTextInput"}>
              <TextInput
                sx={{ marginLeft: "24px" }}
                type={"text"}
                setValue={(data) => changePhone(data)}
                label={i18n.t("phone")}
                value={user.phone}
                mode={"grey"}
              />
            </Box>
          </Box>
          <Box className={"dropdownContainer"}>
            <Dropdown
              disabled={myUser.user.type === 1}
              type={"role"}
              app={app}
              label={
                myUser.user.type === 1 ? i18n.t("role") : i18n.t("chooseRole")
              }
              placeholder={i18n.t("role")}
              options={roles}
              selectedOption={user.type}
              setSelectedOption={(selectedRole) => changeRole(selectedRole)}
            />
          </Box>

          {myUser.user.type === 3 ? (
            <Box className={"buttonContainer"} onClick={() => disableForThem()}>
              <Button
                text={
                  isNew
                    ? i18n.t("createUserProfile")
                    : i18n.t("editUserProfile")
                }
                mode={"small"}
              />
            </Box>
          ) : (
            <Box className={"buttonContainer"} onClick={() => saveUser()}>
              <Button
                text={
                  isNew
                    ? i18n.t("createUserProfile")
                    : i18n.t("editUserProfile")
                }
                mode={"small"}
              />
            </Box>
          )}
        </Box>
      </Box>
      <br />
      <br />

      {user.id != myUser.user.id ? (
        <Box className={"sectionChange bottomSection"}>
          <Headline
            text={i18n.t("deleteUserProfile")}
            type={"Headline-medium"}
          />
          <div className={"deleteWarning"}>
            {i18n.t("deleteUserProfileConformation")}
          </div>

          <div className={"deleteItems"}>
            <div className={"deleteItem"}>{i18n.t("deleteUserInfo1")}</div>
            <div className={"deleteItem"}>{i18n.t("deleteUserInfo2")}</div>
            <div className={"deleteItem"}>{i18n.t("deleteUserInfo3")}</div>
            <div className={"deleteItem"}>{i18n.t("deleteUserInfo4")}</div>
          </div>

          <div className={"deleteWarning"}>{i18n.t("actionUndoneUser")}</div>
          <Box className={"buttonContainer"} onClick={() => deleteUserStart()}>
            <Button text={i18n.t("deleteCenterProfileUser")} mode={"small"} />
          </Box>
        </Box>
      ) : null}
    </div>
  );
};

export default User;
