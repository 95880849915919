import React, { useEffect, useState } from "react";
import "./Search.scss";
import { Box, Input } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  doSearch,
  getMyUser,
  updateSurveySettings,
} from "../../../../core/axios/services";
import { useNavigate } from "react-router-dom";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import Button from "../../Comon/Button/Button";
import CreatePatientModal from "../../CreatePatientModal";

const Search = ({ showGlobalSnackbar, user }) => {
  const { i18n } = useTranslation();
  const [timer, setTimer] = useState(null);
  const [className, setClassName] = useState("SearchInput");
  const [patients, setPatients] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const onKeyDown = () => {
    clearTimeout(timer);
  };

  const onKeyUp = (value) => {
    clearTimeout(timer);
    setTimer(setTimeout(() => search(value), 300));

    if (value.length > 0) {
      setIsComponentVisible(true);
      setClassName("SearchInput ActiveSearch");
    } else {
      clearTimeout(timer);
      setPatients([]);
      setUsers([]);
      setClassName("SearchInput");
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    setPatients([]);
    setUsers([]);
    setClassName("SearchInput");
  }, [isComponentVisible]);

  const openPatient = (patientId) => {
    setPatients([]);
    setUsers([]);
    navigate("/dashboard/patient/" + patientId);
  };

  const openUser = (userId) => {
    setUsers([]);
    setPatients([]);
    navigate("/dashboard/user/" + userId);
  };

  const search = async (value) => {
    if (value.trim().length > 0) {
      try {
        setPatients([]);
        setUsers([]);
        return await doSearch(value, user).then((res) => {
          const filteredPatients = res.data.data.patients;
          setPatients(filteredPatients);

          // const filteredUsers = res.data.data.users;
          // setUsers(filteredUsers);
        });
      } catch (error) {
        //showGlobalSnackbar(i18n.t("thereWasAnError"));
      }
    }
  };

  const onInputClick = async () => {
    try {
      if (user) {
        const res = await doSearch("*", user);
        setPatients(res.data.data.patients.slice(0, 6));
      }
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  return (
    <>
      {" "}
      {location.pathname != "/dashboard/help" ? (
        <div className="Search">
          <div className="searchWrapper">
            <div className={"2 searchInputWrapper"}>
              <img
                src={"/assets/search.svg"}
                className={"searchIcon"}
                alt="searchIcon"
              />
              <Input
                onClick={() => onInputClick()}
                onKeyDown={() => onKeyDown()}
                onKeyUp={(e) => onKeyUp(e.target.value)}
                className={className}
                placeholder={i18n.t("searchLabel")}
                type={"text"}
              />
            </div>

            <CreatePatientModal showGlobalSnackbar={showGlobalSnackbar} />
          </div>

          {users.length > 0 || patients.length > 0 ? (
            <div className={"optionsContainer"} ref={ref}>
              {patients.length > 0 ? (
                <div className={"specificOptionsController"}>
                  <div className={"specificTitle"}>{i18n.t("patients")}</div>
                  {patients.map((patient, index) => {
                    return (
                      <div
                        className={"searchOption"}
                        onClick={() => openPatient(patient.id)}
                        key={index}
                      >
                        {patient.name} {patient.lastName}
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {/* {users.length > 0 ? (
                <div className={"specificOptionsController"}>
                  <div className={"specificTitle"}>{i18n.t("users")}</div>
                  {users.map((user, index) => {
                    return (
                      <div
                        className={"searchOption"}
                        onClick={() => openUser(user.id)}
                        key={index}
                      >
                        {user.name}
                      </div>
                    );
                  })}
                </div>
              ) : null} */}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="Search"></div>
      )}
    </>
  );
};

export default Search;
