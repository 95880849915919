import React from "react";
import "./TestTerms.scss";

const TestTerms = () => {
  return (
    <div className="testPrivacyWrap">
      <h1>Terms of Use for Patients, Clients & Technology Users</h1>
      <span>Effective as of 19 July, 2023</span>

      <div className="testPrivacyContent">
        <p>
          These Terms of Use (this “Agreement”) are a legal agreement between
          you (“you”) and Chiropractic Outcomes OÜ, a company incorporated in
          Estonia (“Company,” “we” or “us”) for use 0f chiropracticoutcomes.com,
          and all related services, features, sites, and content offered by the
          Company.
        </p>
        <h2>1. Acceptance of terms</h2>
        <p>
          Please read this Agreement carefully. By creating an account or
          accessing or using the Software, you acknowledge that you accept and
          agree to be bound by the terms of this Agreement. IF YOU DO NOT AGREE
          TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE SOFTWARE. <br />
          <br />
          We may modify this Agreement from time to time. We will notify you by
          email, or by presenting you with a new version of the Agreement for
          you to accept if we make modifications that materially change your
          rights. Your continued use of the Software after the effective date of
          an updated version of the Agreement will indicate your acceptance of
          the Agreement as modified.
        </p>
        <h2>2. Medical services disclaimer</h2>
        <p>
          THE COMPANY IS NOT A LICENSED MEDICAL CARE PROVIDER AND THE SOFTWARE
          IS NOT INTENDED TO REPLACE PROFESSIONAL MEDICAL ADVICE OR DIAGNOSE,
          TREAT OR MANAGE ANY ILLNESS OR MEDICAL CONDITION. PLEASE CONSULT WITH
          A LICENSED PHYSICIAN OR OTHER QUALIFIED HEALTHCARE PROVIDER BEFORE
          MAKING ANY DECISIONS OR TAKING ANY ACTIONS THAT MAY AFFECT YOUR HEALTH
          AND SAFETY. NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN
          SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ IN CONNECTION WITH THE
          SOFTWARE. ALWAYS CONSULT WITH A HEALTHCARE PROFESSIONAL IF YOU HAVE
          ANY QUESTIONS OR CONCERNS ABOUT YOUR HEALTH OR CONDITION OR EXPERIENCE
          ANY CHANGES IN YOUR CONDITION OR HEALTH STATUS. IF YOU THINK YOU HAVE
          A MEDICAL EMERGENCY, CALL EMERGENCY SERVICES OR GO TO THE NEAREST OPEN
          EMERGENCY ROOM IMMEDIATELY.
          <br />
          <br />
          WE DISCLAIM LIABILITY FOR ANY ERRORS OR OMISSIONS, OR FOR UNINTENDED
          TECHNICAL INACCURACIES, OR TYPOGRAPHICAL ERRORS IN THE PROVIDED
          MATERIALS. NOTHING IN THIS AGREEMENT EXCLUDES OR LIMITS IN ANY WAY OUR
          LIABILITY TO YOU WHERE IT WOULD BE UNLAWFUL TO DO SO.
        </p>
        <h2>3. Registration and eligibility</h2>
        <p>
          To use the Software, you may be required to create or update an
          account (“Account”) and will be asked to provide certain personal
          information, which may include your name, e-mail address, health
          history, symptoms, state of being ratings, etc. This information will
          be held and used in accordance with our privacy policy. You agree that
          you will supply accurate and complete information to the Company, and
          that you will update that information promptly after it changes.
          <br />
          <br />
          To create an Account and access the Software, you must be at least 13
          years old (16 years old in EU) and not barred from using the Software
          under Applicable law.
          <br />
          <br />
          If you are under 18, your parent or guardian must review and accept
          the terms of this Agreement, and by using the Software, you confirm
          that your parent or guardian has so reviewed and accepted this
          Agreement. We reserve the right to limit the availability to users
          under the age of 18 of certain content in the Software, in our sole
          discretion.
        </p>
        <h2>4. Your use of the Software</h2>
        <p>
          Any content you submit through the Software is governed by the
          Company’s Privacy Policy. As a condition of using the Software, you
          agree not to use the Software for any purpose that is prohibited by
          this Agreement. You are responsible for all of your activity in
          connection with the Software and you shall abide by all local, state,
          national, and international laws and regulations and any applicable
          regulatory codes. You agree that if you take any of the following
          actions, you will be materially breaching this Agreement, and you
          agree that you SHALL NOT:
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              resell, rent, lease, loan, sublicense, distribute, or otherwise
              transfer rights to the Software;
            </li>
            <li>
              modify, reverse engineer, decompile or disassemble the Software;
            </li>
            <li>
              copy, adapt, alter, modify, translate, or create derivative works
              of the Software without the written authorization of the Company;
            </li>
            <li>
              permit other individuals to use the Software, including but not
              limited to shared use via a network connection, except under the
              terms of this Agreement;
            </li>
            <li>
              circumvent or disable any technological features or measures in
              the Software for protection of intellectual property rights;
            </li>
            <li>
              use the Software in an attempt to, or in conjunction with, any
              device, program, or service designed to circumvent technological
              measures employed to control access to, or the rights in, a
              content file or other work protected by the copyright laws of any
              jurisdiction;
            </li>
            <li>
              use or access the Software to compile data in a manner that is
              used or usable by a competitive product or service;
            </li>
            <li>
              use your Account to advertise, solicit, or transmit any commercial
              advertisements, including chain letters, junk e-mail or repetitive
              messages to anyone;
            </li>
            <li>use your Account to engage in any illegal conduct;</li>
            <li>
              upload or transmit any communications that infringe or violate the
              rights of any party;
            </li>
            <li>
              upload media of any kind that contain expressions of hate, abuse,
              offensive images or conduct, obscenity, pornography, sexually
              explicit or any material that could give rise to any civil or
              criminal liability under applicable law or regulations or that
              otherwise may be in conflict with this Agreement, and the
              Company’s Privacy Policy; or
            </li>
            <li>
              upload any material that contains software viruses or any other
              computer code, files or programs that is malicious,
              technologically harmful or designed to interrupt, destroy or limit
              the functionality of any computer software, this website or the
              Software.
            </li>
          </ol>
          <p>
            Any such forbidden use shall immediately terminate your license to
            use the Software.
          </p>
        </p>
        <h2>5. Children’s privacy and age restrictions</h2>
        <p>
          We are committed to protecting the privacy of children.
          <br />
          <br />
          You should be aware that this Software is not intended or designed to
          attract children under the age of 13. We do not collect personal data
          from any person we actually know is a child under the age of 13.
          <br />
          <br />
          If you are a resident of the United Kingdom or the European Union, you
          shall be at least 16 years old in order to use the Software. Except to
          the extent prohibited by applicable law, we do not allow the use of
          the Software by United Kingdom or European Union residents younger
          than 16 years old.
        </p>
        <h2>6. Export and economic sanctions control</h2>
        <p>
          The software that supports the Software may be subject to U.S. export
          and re-export control laws and regulations, including the Export
          Administration Regulations (“EAR”) maintained by the U.S. Department
          of Commerce, trade and economic sanctions maintained by the Treasury
          Department’s Office of Foreign Assets Control (“OFAC”), and the
          International Traffic in Arms Regulations (“ITAR”) maintained by the
          Department of State. You represent and warrant that you are (1) not
          located in any country or region that is subject to a U.S. government
          embargo, and (2) are not a denied party as specified in the
          regulations listed above.
          <br />
          <br />
          You agree to comply with all U.S. and foreign export laws and
          regulations to ensure that neither the Software nor any technical data
          related thereto nor any direct product or products derived from or
          based on such technology received from Chiropractic Outcomes under
          these Terms of Use thereof is exported or re-exported directly or
          indirectly in violation of, or used for any purposes prohibited by,
          such laws and regulations.
        </p>
        <h2>7. Limited License to the Software</h2>
        <p>
          We grant you a personal, worldwide, revocable, non-transferable and
          non-exclusive license to access and use the Software for professional
          and non-commercial purposes in accordance with the terms of this
          Agreement. You may not copy, store, modify, distribute, transmit,
          perform, reproduce, publish, license, create derivative works from,
          transfer or sell any text, graphics, logos and other
          source-identifying symbols, designs, icons, images, or other
          information, software or code obtained from the Software without prior
          express written permission from the Company which may be withheld for
          any or no reason. You further agree not to download, display or use
          any content on the Software that is provided by the Company or its
          licensors located on the Software for use in any publications, in
          public performances, on websites other than the Software for any other
          commercial purpose, in connection with products or services that are
          not those of the Company, in any other manner that is likely to cause
          confusion among consumers, that disparages or discredits the Company
          and/or its licensors, that dilutes the strength of the Company or its
          licensor's property, or that otherwise infringes the Company or its
          licensors’ intellectual property rights. You further agree to in no
          other way misuse any content published by the Company or third-party
          content on the Software.
          <br />
          <br />
          All rights, title, and interest in and to the Software not expressly
          granted in this Agreement are reserved by the Company. If you wish to
          use the Company’s software, title, trade name, trademark, service
          mark, logo, domain name and/or any other identification with notable
          brand features or other content owned by the Company, you must obtain
          written permission from the Company. Permission requests may be sent
          to mailto:{" "}
          <a href="mailto:support@chiropracticoutcomes.com">
            support@chiropracticoutcomes.com
          </a>
          <br />
          <br />
          To avoid any doubt, the Company owns all the text, images, photos,
          audio, video, location data, software, code, and all other forms of
          data or communication that the Company creates and makes available in
          connection with the Software, including but not limited to visual
          interfaces, interactive features, graphics, design, compilation of
          User Content (as defined below), and the compilation of aggregate user
          review ratings and all other elements and components of the Software,
          excluding User Content (collectively referred to herein as the
          “Company’s Content”). Except as expressly and unambiguously provided
          herein, we do not grant you any express or implied rights, and all
          rights in and to the Software and the Company’s Content are retained
          by us
        </p>
        <h2>8. License to User Content</h2>
        <p>
          The Software enables you to input personal responses, share health
          history, respond to health metric questions, submit content and log
          certain information into the Software (“User Content”). You retain all
          rights to such User Content that you post, share, or log in the
          Software.
          <br />
          <br />
          By providing your User Content to the Software, you (a) grant the
          Company a non-exclusive, transferable, sublicensable, worldwide,
          royalty-free license to use, copy, exploit, modify, publicly display,
          publicly perform, create derivative works from, incorporate it into
          other works, change, reformat, and distribute your User Content in
          connection with providing and operating the Software and related
          services and/or for the Company’s promotional purposes (for example,
          by displaying on our website, within the Software, in social media, on
          any website or platform in the internet as we may deem appropriate),
          subject to the Privacy Policy; and (b) you agree to indemnify the
          Company and its affiliates, directors, officers, and employees and
          hold them harmless from any and all claims and expenses, including
          attorneys’ fees, arising from the media and/or your failure to company
          with the terms described in this Agreement.
          <br />
          <br />
          The Company reserves the right to review all User Content prior to
          submission to the Software and to remove any content or media for any
          reason, at any time, without prior notice, at our sole discretion.
        </p>
        <h2>9. Use at your own risk</h2>
        <p>
          Our goal is to help make certain health-related information more
          readily available and useful to you. However, the Software cannot and
          does not guarantee health-related improvements or outcomes. Your use
          of the Software and any information, predictions, or suggestions
          provided in the Software are at your sole risk. We make no
          representation or warranty of any kind as to the accuracy of data,
          information, estimates, and predictions that we may provide to you
          through the Software and you agree and understand that the Software is
          not intended to match or serve the same purpose as a medical or
          scientific device or healthcare provider.
        </p>
        <h2>10. Subscriptions</h2>
        <p>
          Chiropractic Outcomes subscription. By accessing Chiropractic Outcomes
          you agree that your purchases are not contingent on the provision of
          any future functionality or features, or dependent on any oral or
          written public statements, and comments made by Chiropractic Outcomes
          regarding such functionality or features.
          <br />
          <br />
          Billing. You may purchase the subscription directly from Chiropractic
          Outcomes or through a third party by paying a subscription fee plus
          applicable taxes in advance on a monthly basis or some other recurring
          interval disclosed to you prior to your purchase.
          <br />
          <br />
          Price and tax changes. Chiropractic Outcomes may from time to time
          make changes to subscriptions, including recurring subscription fees
          and will communicate any price changes to you in advance. Price
          changes will take effect at the start of the next subscription period
          following the date of the price change and, by continuing to use the
          Chiropractic Outcomes subscription after the price change takes
          effect, you will have accepted the new price. If you don’t agree to a
          price change, you can reject the change by unsubscribing from the
          subscription prior to the price change going into effect.
          <br />
          <br />
          Tax rates or other fees are based on the rates applicable at the time
          of your monthly charge. These amounts can change over time with local
          tax requirements in your country, state, territory, county, or city.
          Any change in tax rate will be automatically applied based on the
          account information you provide
          <br />
          <br />
          Renewal and Cancellation. Your payment to Chiropractic Outcomes or the
          third party through which you purchased the subscription will
          automatically renew at the end of the applicable subscription period,
          unless you cancel your subscription before the end of the then-current
          subscription period. You must cancel your subscription before it
          renews to avoid the billing of the fees for the next Subscription
          period. If you purchase your subscription through the
          chiropracticoutcomes.com you can cancel the renewal of your
          subscription at any time by contacting us by email at{" "}
          <a href="mailto:support@chiropracticoutcomes.com">
            support@chiropracticoutcomes.com
          </a>{" "}
          and specify the email you used to register your account..
        </p>
        <h2>11. Passwords</h2>
        <p>
          You are responsible for taking all reasonable steps to ensure that no
          unauthorized person shall have access to your Software passwords or
          account. It is your sole responsibility to (1) control the
          dissemination and use of sign-in name, screen name and passwords; (2)
          authorize, monitor, and control access to and use of your Software
          account and password; (3) promptly inform the Company if you believe
          your account or password has been compromised or if there is any other
          reason you need to deactivate a password. Send us an email at{" "}
          <a href="mailto:support@chiropracticoutcomes.com">
            support@chiropracticoutcomes.com
          </a>{" "}
          You grant the Company and all other persons or entities involved in
          the operation of the Software the right to transmit, monitor,
          retrieve, store, and use your information in connection with the
          operation of the Software. You further acknowledge and agree that the
          Software and account are designed and intended for personal use on an
          individual basis and you should not share your account and/or password
          details with another individual. The Company cannot and does not
          assume any responsibility or liability for any information you submit,
          or your or third parties’ use or misuse of information transmitted or
          received arising from the using the Software, and shall not be
          responsible for any losses arising out of the unauthorized use of your
          account or information resulting from you not following these rules.
        </p>
        <h2>12. Warranty disclaimer</h2>
        <p>
          The Company controls and operates the Software from various locations
          and makes no representation that the Software is appropriate or
          available for use in all locations. The Software or certain features
          of it may not be available in your location or may vary across
          locations.
          <br />
          <br />
          THE SOFTWARE IS PROVIDED “AS IS”, “AS AVAILABLE” AND IS PROVIDED
          WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
          PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR
          USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, SAVE TO THE
          EXTENT REQUIRED BY LAW. NEITHER THE COMPANY, NOR ANY OF ITS OFFICERS,
          DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, REPRESENTATIVES, SUPPLIERS,
          PARTNERS, ADVERTISERS OR CONTENT PROVIDERS WARRANTS, AND EACH OF THEM
          HEREBY EXPRESSLY DISCLAIMS, THAT: (A) THE SOFTWARE WILL BE SECURE OR
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR
          ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR
          THROUGH THE SOFTWARE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;
          (D) THE RESULTS OF USING THE SOFTWARE WILL MEET YOUR REQUIREMENTS. OR
          (E) THE ACCURACY, RELIABILITY, OR COMPLETENESS OF THE CONTENT, TEXT,
          IMAGES, SOFTWARE, GRAPHICS, OR COMMUNICATIONS PROVIDED BY THIRD
          PARTIES ON OR THROUGH THE SOFTWARE. YOUR USE OF THE SOFTWARE IS SOLELY
          AT YOUR OWN RISK. SOME STATES / COUNTRIES DO NOT ALLOW LIMITATIONS ON
          IMPLIED WARRANTIES, SO SOME OR ALL OF THE ABOVE LIMITATIONS MAY NOT
          SOFTWARELY TO YOU.
        </p>
        <h2>13. Limitation of liability</h2>
        <p>
          IN NO EVENT SHALL THE COMPANY OR ANY OF ITS OFFICERS, DIRECTORS,
          AGENTS, AFFILIATES, EMPLOYEES, REPRESENTATIVES, SUPPLIERS, PARTNERS,
          ADVERTISERS, OR DATA PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING
          BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA)
          WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
          NEGLIGENCE), EQUITY OR OTHERWISE, ARISING OUT OF OR IN ANY WAY
          CONNECTED WITH THE USE OR MISUSE OF THIS SOFTWARE. IN NO EVENT WILL
          THE COMPANY’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH
          THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SOFTWARE EXCEED
          THE AMOUNTS YOU HAVE PAID TO THE COMPANY FOR USE OF THE SOFTWARE OR
          ONE HUNDRED EUROS (€100) IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS
          TO THE COMPANY, AS APPLICABLE. SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF LIABILITY, SO THE ABOVE LIMITATIONS MAY NOT
          SOFTWARELY TO YOU. NONE OF THE COMPANY OR ANY OF ITS OFFICERS,
          DIRECTORS, AGENTS, AFFILIATES, EMPLOYEES, REPRESENTATIVES, SUPPLIERS,
          PARTNERS, ADVERTISERS, OR CONTENT PROVIDERS, OR ANY THIRD PARTIES
          MENTIONED ON THE SOFTWARE SHALL BE LIABLE FOR ANY PERSONAL INJURY,
          INCLUDING DEATH, CAUSED BY YOUR USE OR MISUSE OF THE SOFTWARE. THE
          LAWS OF SOME STATES / COUNTRIES IMPOSE RESTRICTIONS ON LIMITING
          LIABILITY. NOTHING IN THIS AGREEMENT EXCLUDES OR LIMITS OUR LIABILITY
          TO YOU WHERE IT WOULD BE UNLAWFUL TO DO SO. WHERE ANY PROVISION IS
          EXPRESSED TO EXCLUDE OR LIMIT LIABILITY TO A GREATER EXTENT THAN
          PERMITTED BY APPLICABLELAW, THAT PROVISION SHALL BE DEEMED TO ONLY
          EXCLUDE OR LIMIT OUR LIABILITY TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW.
        </p>
        <h2>14. Use of mobile devices</h2>
        <p>
          Please note that your carrier’s normal rates and fees, such as text
          messaging and data charges, will still apply if you are using the
          Software on a mobile device.
        </p>
        <h2>15. Third-Party Services and links</h2>
        <p>
          The Software may give you access to links to third-party websites,
          Softwares, or other products or services (“Third Party Services”). The
          Company does not control Third Party Services in any manner and,
          accordingly, the Company is not responsible for the privacy practices
          of such Third Party Services and does not assume any liability
          associated with such Third Party Services. Your linking to or use of
          any Third Party Services other than the Software is at your own risk.
          The Company's inclusion of links to Third Party Services does not
          imply any endorsement of any kind by the Company of the material
          located on or linked to by such Third Party Services and should not be
          deemed as such by any user of the Software. The Company disclaims any
          responsibility for the products or services offered or the information
          contained on any Third Party Services. You need to take appropriate
          steps to determine whether accessing a Third Party Service is
          appropriate, including protecting your personal information and
          privacy in using any such Third Party Services and complying with
          relevant agreements.
          <br />
          <br />
          You shall not link to our websites, Software, content or services in a
          way that is: (i) illegal, (ii) suggests any form of association,
          approval or endorsement with or by us where none exists, (iii) damages
          our reputation or takes advantage of it, or (iv) is unfair.
        </p>
        <h2>16. Your feedback</h2>
        <p>
          We welcome your feedback about the Software. Unless otherwise
          expressly declared, any communications you send to us or publish in
          Software stores are deemed to be submitted on a non-confidential
          basis. You agree that we may decide to publicize such contents at our
          own discretion. You agree to authorize us to make use of such contents
          for free, and revise, modify, adjust and change it contextually, or
          make any other changes, in each case as we deem appropriate.
        </p>
        <h2>17. Enforcement rights</h2>
        <p>
          We are not obligated to monitor access or use of the Software.
          However, we reserve the right to do so for purposes of operating and
          maintaining the Software, ensuring your compliance with this
          Agreement, and complying with applicable legal requirements. We may
          disclose unlawful conduct to law enforcement authorities, and pursuant
          to valid legal process, we may cooperate with law enforcement
          authorities to prosecute users who violate the law. We reserve the
          right (but are not required) to remove or disable any content posted
          to the Software or access to the Software at any time, in each case
          without notice and at our sole discretion if we determine in our sole
          discretion that your content or use of the Software is objectionable
          or in violation this Agreement.
          <br />
          <br />
          We may refuse service, close Accounts, and change eligibility
          requirements at any time.
          <br />
          <br />
          The Company has no liability or responsibility to users of the
          Software or any other person or entity for performance or
          nonperformance of the aforementioned activities.
        </p>
        <h2>18. Changes to the Software</h2>
        <p>
          From time to time and without prior notice to you, we may change,
          expand, and improve the Software. We may also, at any time, cease to
          continue operating part or all of the Software or selectively disable
          certain features of the Software. Your use of the Software does not
          entitle you to the continued provision or availability of the
          Software. Any modification or elimination of the Software or any
          particular features will be done in our sole and absolute discretion
          and without an ongoing obligation or liability to you.
        </p>
        <h2>20. Indemnit</h2>
        <p>
          You agree to defend, indemnify, and hold the Company, its officers,
          directors, employees, agents, affiliates, representatives, licensors,
          suppliers, partners, advertisers and content providers, harmless from
          and against any claims, actions, demands, liabilities and settlements
          including without limitation, reasonable legal and accounting fees,
          arising out of or resulting from, or alleged to result from, your
          violation of this Agreement.
        </p>
        <h2>21. Dispute Resolution</h2>
        <p>
          Unless otherwise required by mandatory laws in your country of
          residence, the Agreement (and any non-contractual disputes/claims
          arising out of or in connection with them) are subject to the laws of
          the state or country listed below, without regard to choice or
          conflicts of law principles.
          <br />
          <br />
          WHERE PERMITTED UNDER THE APPLICABLELAW, YOU AND CHIROPRACTIC OUTCOMES
          AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
          INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and
          Chiropractic Outcomes agree, no arbitrator or judge may consolidate
          more than one person's claims or otherwise preside over any form of a
          representative or class proceeding.
          <br />
          <br />
          If you are a resident of any European Union country or the United
          Kingdom, Switzerland, Norway or Iceland, the governing law and forum
          shall be the laws and courts of your usual place of residence.
          <br />
          <br />
          IF YOU RESIDE IN THE UNITED STATES, THE ARBITRATION AGREEMENT IN THIS
          SECTION 22 APPLY TO YOU. PLEASE READ THEM CAREFULLY.
          <br />
          <br />
          This Arbitration Agreement only applies to you if you reside in the
          United States.
          <br />
          <br />
          You may opt out of this Arbitration Agreement. If you do so, neither
          you nor the Company can require the other to participate in an
          arbitration proceeding.
          <br />
          <br />
          To opt out, you must notify the Company by email{" "}
          <a href="mailto:support@chiropracticoutcomes.com">
            support@chiropracticoutcomes.com
          </a>
          <br />
          <br />
          If you do not reside in the United States, and you nevertheless
          attempt to bring any legal claim against the Company in the United
          States, this Arbitration Agreement will apply for determination of the
          threshold issue of whether this Section 22 applies to you, and all
          other threshold determinations, including residency, arbitrability,
          venue, and applicable law.
          <br />
          <br />
          The Company is committed to participating in a consumer-friendly
          dispute resolution process. To that end, this Agreement provides for a
          two-part process for individuals to whom this Section 22 applies: (1)
          an informal negotiation directly with the Company’s customer service
          team, and, if necessary, (2) a binding arbitration administered by the
          American Arbitration Association (“AAA”). You and the Company each
          retain the right to seek relief in small claims court as an
          alternative to arbitration.
          <br />
          <br />
          At least 30 days prior to initiating an arbitration, you and the
          Company each agree to notify the other party of the dispute in writing
          and attempt in good faith to negotiate an informal resolution. You
          must send your notice of dispute to{" "}
          <a href="mailto:support@chiropracticoutcomes.com">
            support@chiropracticoutcomes.com
          </a>
          .The Company will send its notice of dispute to the email address
          associated with your Company account. A notice of dispute must
          include: the party’s name and preferred contact information, a brief
          description of the dispute, and the relief sought. If the parties are
          unable to resolve the dispute within the 30-day period, only then may
          either party commence arbitration by filing a written Demand for
          Arbitration (available at www.adr.org) with the AAA and providing a
          copy to the other party as specified in the AAA Rules (available at
          www.adr.org).
          <br />
          <br />
          You and the Company mutually agree that any dispute, claim or
          controversy arising out of or relating to this Agreement
          (collectively, “Disputes”) will be settled by binding individual
          arbitration (the “Arbitration Agreement”). If there is a dispute about
          whether this Arbitration Agreement can be enforced or applies to our
          Dispute, you and the Company agree that the arbitrator will decide
          that issue.
          <br />
          <br />
          This Arbitration Agreement evidences a transaction in interstate
          commerce and the Federal Arbitration Act governs all substantive and
          procedural interpretation and enforcement of this provision. The
          arbitration will be administered by AAA in accordance with the
          Consumer Arbitration Rules and/or other AAA arbitration rules
          determined to be applicable by the AAA (the “AAA Rules”) then in
          effect, except as modified here. The AAA Rules are available at
          www.adr.org. In order to initiate arbitration, a completed written
          demand (available at www.adr.org) must be filed with the AAA and
          provided to the other party, as specified in the AAA rules.
          <br />
          <br />
          In order to make the arbitration most convenient to you, the Company
          agrees that any required arbitration hearing may be conducted, at your
          option: (a) in the US county where you reside; (b) in New Castle
          County; (c) via phone or video conference; or (d) if all parties
          agree, by solely the submission of documents to the arbitrator.
          <br />
          <br />
          Your arbitration fees and your share of arbitrator compensation shall
          be governed by the AAA Rules and, where appropriate, limited by the
          AAA Consumer Rules. If such costs are determined by the arbitrator to
          be excessive, the Company will pay all arbitration fees and expenses.
          Either party may make a request that the arbitrator award attorneys’
          fees and costs upon proving that the other party has asserted a claim,
          cross-claim or defense that is groundless in fact or law, brought in
          bad faith or for the purpose of harassment, or is otherwise frivolous,
          as allowed by applicable law and the AAA Rules.
          <br />
          <br />
          The arbitrator’s decision will include the essential findings and
          conclusions upon which the arbitrator based the award. Judgment on the
          arbitration award may be entered in any court with proper
          jurisdiction. The arbitrator may award any relief allowed by law or
          the AAA Rules, but declaratory or injunctive relief may be awarded
          only on an individual basis and only to the extent necessary to
          provide relief warranted by the claimant’s individual claim.
          <br />
          <br />
          No Class Actions or Representative Proceedings. You and the Company
          acknowledge and agree that, to the fullest extent permitted by law, we
          are each waiving the right to participate as a plaintiff or class
          member in any purported class action lawsuit, class-wide arbitration,
          private attorney general action, or any other representative or
          consolidated proceeding. Unless we agree in writing, the arbitrator
          may not consolidate more than one party’s claims and may not otherwise
          preside over any form of any class or representative proceeding. If
          there is a final judicial determination that applicable law precludes
          enforcement of the waiver contained in this paragraph as to any claim,
          cause of action or requested remedy, then that claim, cause of action
          or requested remedy, and only that claim, cause of action or requested
          remedy, will be severed from this agreement to arbitrate and will be
          brought in a court of competent jurisdiction. In the event that a
          claim, cause of action or requested remedy is severed pursuant to this
          paragraph, then you and we agree that the claims, causes of action or
          requested remedies that are not subject to arbitration will be stayed
          until all arbitrable claims, causes of action and requested remedies
          are resolved by the arbitrator.
          <br />
          <br />
          If the Company changes this Section 22 after the date you last
          accepted this Agreement (or accepted any subsequent changes to this
          Agreement), you may reject that change by sending us written notice
          (including by email) within 30 days of the date the change is
          effective. Rejecting a new change, however, does not revoke or alter
          your prior consent to any earlier agreements to arbitrate any Dispute
          between you and the Company (or your prior consent to any subsequent
          changes thereto), which will remain in effect and enforceable as to
          any Dispute between you and the Company.
          <br />
          <br />
          Except as provided in the preceding paragraph, upon termination, all
          provisions of this Agreement, which, by their nature, should survive
          termination, shall survive termination, including, without limitation,
          all ownership provisions, warranty disclaimers, and limitations of
          liability.
          <br />
          <br />
          If for any reason an arbitral tribunal or court of competent
          jurisdiction finds any provision of this Agreement, or a portion
          thereof, to be unenforceable, that provision shall be enforced to the
          maximum extent permissible so as to affect the intent of this
          Agreement, and the remainder of this Agreement shall continue in full
          force and effect. A printed version of this Agreement shall be
          admissible in arbitral, judicial or administrative proceedings.
          <br />
          <br />
          No waiver of by the Company of any term or condition set forth in this
          Agreement shall be deemed a further or continuing waiver of such term
          or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under this
          Agreement shall not constitute a waiver of such right or provision. If
          any provision of this Agreement is held by a court or other tribunal
          of competent jurisdiction to be invalid, illegal or unenforceable for
          any reason, such provision shall be eliminated or limited to the
          minimum extent such that the remaining provisions of this Agreement
          will continue in full force and effect.
        </p>
        <h2>22. Notice and takedown procedures</h2>
        <p>
          If you believe any materials accessible on or from the Software
          infringe your copyright, you may request removal of those materials
          (or access thereto) from this Software by contacting the Company and
          providing the following information:
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Identification of the copyrighted work that you believe to be
              infringed. Please describe the work, and, where possible, include
              a copy or the location of an authorized version of the work.
            </li>
            <li>
              Identification of the material that you believe to be infringing
              and its location. Please describe the material, and provide us
              with its URL or any other pertinent information that will allow us
              to locate the material.
            </li>
            <li>
              Your name, address, telephone number and (if available) e-mail
              address.
            </li>
            <li>
              A statement that you have a good faith belief that the complained
              use of the materials is not authorized by the copyright owner, its
              agent, or the law.
            </li>
            <li>
              A statement that the information that you have supplied is
              accurate, and indicating that “under penalty of perjury,” you are
              the copyright owner or are authorized to act on the copyright
              owner’s behalf.
            </li>
            <li>
              A signature or the electronic equivalent from the copyright holder
              or authorized representative.
            </li>
            <p>
              In an effort to protect the rights of copyright owners, the
              Company maintains a policy for the termination, in appropriate
              circumstances, of subscribers and account holders of the Software
              who are repeat infringers.
              <br />
              <br />
              Questions and comments
              <br />
              <br />
              If you have any comments or questions on any part of the Software
              or any part of these Terms of Use, require support, or have any
              claims, please contact us at{" "}
              <a href="mailto:support@chiropracticoutcomes.com">
                support@chiropracticoutcomes.com
              </a>
            </p>
          </ol>
        </p>
      </div>
    </div>
  );
};

export default TestTerms;
