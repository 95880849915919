import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MobileHeader from "../../components/MobileHeader";
import AmazingResults from "./AmazingResults";
import Baner from "./Baner";
import Benefits from "./Benefits";
import Grow from "./Grow";
import Measur from "./Measur";
import Video from "./Video";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import "./NewLanding.scss";
import Footer from "../../components/Footer";
import { appData } from "../../../core/axios/services";
import { getAppData } from "../../../redux/actions/app";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const NewLanding = ({ showGlobalSnackbar, app }) => {
  const [landingData, setLandingData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.location.host == "stage.chiroforms.com" ||
      window.location.host == "chiroforms.com"
    ) {
      navigate("/not-found");
    }
  }, []);

  useEffect(() => {
    appData().then((res) => {
      dispatch(getAppData(res.data.data));
      setLandingData(res.data.data.landingData);
    });

    if (window.location.pathname === "/") {
      const script = document.createElement("script");
      script.id = "mcjs";
      script.innerHTML =
        '!function (c, h, i, m, p) { m = c.createElement(h), p = c.getElementsByTagName(h)[0], m.async = 1, m.src = i, p.parentNode.insertBefore(m, p) }(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/17bd3ff9b114fce27a71280f2/2dba4a0518534f84a1c6e8db5.js");';
      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      {/* Header */}
      <div className="container-fluid">
        <div class="row  p-0 landing-section landing-section-one">
          <Header />
          <MobileHeader />
        </div>
      </div>
      {/* End Header */}

      {/* Baner */}
      <Baner />
      {/* End Baner */}

      {/* Amazing Results */}
      <AmazingResults />
      {/* End Amazing Results */}

      {/* Benefits */}
      <Benefits />
      {/* End Benefits */}

      {/* Grow Your Practice*/}
      <Grow />
      {/* End Grow Your Practice*/}

      {/* Start Measuring */}
      <Measur />
      {/* End Start Measuring */}

      {/* Video */}
      <Video />
      {/* End Video */}

      {/* What People Say */}
      <Testimonials />
      {/* End What People Say */}

      {/* Contact Us */}
      <ContactUs showGlobalSnackbar={showGlobalSnackbar} />
      {/* End Contact Us */}

      {/* Footer */}
      <Footer />
      {/* End Footer */}
    </>
  );
};

export default NewLanding;
