import React, { useState, useEffect } from "react";
import "./Pricing.scss";
import { appData } from "../../../../core/axios/services";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { payment, paymentCheck } from "../../../../core/axios/services";
import { NavLink, useNavigate } from "react-router-dom";
import { getAppData, resetPopUp } from "../../../../redux/actions/app";
import { useTranslation } from "react-i18next";
import hamburger from "../../../../../src/hamburger.svg";
import close from "../../../../../src/closeHamburger.svg";

// Components
import Logo from "../../../components/Comon/Logo/Logo";
import BusinessPlan from "../../../components/Admin Panel/BusinessPlan/BusinessPlan";
import ConfirmPopUp from "../../../components/Admin Panel/ConfirmPopUp/ConfirmPopUp";
import { showPopup } from "../../../../redux/actions/app";
import Header from "../../../components/Header";
import MobileHeader from "../../../components/MobileHeader";

const Pricing = ({ app, i18n, paid }) => {
  const [logo, setLogo] = useState("/assets/logotm.png");
  const [account, setAccount] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [type, setType] = useState("");
  const [business, setBusiness] = useState([]);
  const [check, setCheck] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [landingData, setLandingData] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    collectAppData().then((data) => {
      // setLogo(data.logo);
      setBusiness(data.businessPlans);
    });
  }, []);

  useEffect(() => {
    appData().then((res) => {
      dispatch(getAppData(res.data.data));
      setLandingData(res.data.data.landingData);
    });
  }, []);

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const checkType = async () => {
    try {
      const res = await payment(type, user);
      setCheck(res.data.url);

      if (res.data.url != null) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      navigate("/signup");
    }
  };

  const updateBusinessPlan = (plan) => {
    if (plan.id === 1 || plan.id === 2) {
      setType(plan.id);
    } else {
      window.location = "mailto:support@chiropracticoutcomes.com";
    }
  };

  console.log(check);

  useEffect(() => {
    if (type) {
      checkType();
    }
  }, [type]);

  const checkPayment = async () => {
    try {
      return paymentCheck(user).then((res) => {
        if (res.data.data.subscribed !== false) {
          navigate("/dashboard");
        }
      });
    } catch {
      alert("error");
    }
  };

  useEffect(() => {
    checkPayment();
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "errorPayment":
          navigate("/pricing");
          dispatch(resetPopUp());
          break;
        default:
          dispatch(resetPopUp());
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }

    if (paid === false) {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("paymentError"),
          confirmButton: i18n.t("paymentPopupButton"),
          type: "errorPayment",
        },
      };
      dispatch(showPopup(data));
      console.log(data);
    }
  }, [paid]);

  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };

  const handleHamburger = () => {
    setOpen(true);
  };

  const handleClosehamburger = () => {
    setOpen(false);
  };

  return (
    <div className="pricingWrapper">
      {/* Header */}
      <div className="container-fluid ">
        <div className="row  p-0 landing-section landing-section-one">
          <Header />

          {/* Mobile header */}
          <MobileHeader />
        </div>
        {/* End mobile header */}
      </div>
      {/* End Header */}

      <div className="pricingContent">
        <div className="content">
          <h2>{t("choosePlan")}</h2>
          <p>
            {t("pricingPlan")} <br />
            {t("pricingPlanBottom")}
          </p>

          <div className="pricingCard">
            {business.map((businessPlan, index) => {
              return (
                <Box className={"otherBusinessPlanContainer"} key={index}>
                  {businessPlan.name === "Advanced" ? (
                    <div className="mostPopular">Most popular</div>
                  ) : (
                    <div className="hide">hide</div>
                  )}

                  <BusinessPlan
                    isSignup={true}
                    changePlan={(plan) => updateBusinessPlan(plan)}
                    app={app}
                    i18n={i18n}
                    isSelected={false}
                    businessPlan={businessPlan}
                    isUpgradable={true}
                    mode={"other"}
                  />
                </Box>
              );
            })}
          </div>
        </div>
      </div>

      <div className="contactUs">
        <p>
          {t("contactQuesitons")}{" "}
          <a href="mailto: support@chiropracticoutcomes.com ">
            {t("contactQuesitonsUs")}
          </a>
          .
        </p>
      </div>
      {!paid ? <ConfirmPopUp app={app} /> : null}
    </div>
  );
};

export default Pricing;
