const get_api = () => {
    let API = process.env.REACT_APP_API;
    let API_STORAGE = process.env.REACT_APP_API_STORAGE;
    let API_WEBVIEW = process.env.REACT_APP_API_WEBVIEW;

    return {
        API,
        API_STORAGE,
        API_WEBVIEW,
    };
};

export const {API, API_STORAGE, API_WEBVIEW} = get_api();
