import React, { useEffect, useState } from "react";
import "./ProfileSettings.scss";
import Headline from "../../../components/Comon/Headline/Headline";
import { Box } from "@mui/material";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Button from "../../../components/Comon/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPopUp,
  showPopup,
  showSnackbar,
} from "../../../../redux/actions/app";
import axios from "axios";
import { API } from "../../../../constants/services/base";
import { addUser, logoutUser } from "../../../../redux/actions/user";
import { deleteUser, putUser } from "../../../../core/axios/services";
import { useNavigate } from "react-router-dom";

const ProfileSettings = ({ app, i18n, showGlobalSnackbar }) => {
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState(null);
  const [stringProfile, setStringProfile] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    setProfile(user.user);

    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "profile":
          updateUser();
          dispatch(resetPopUp());
          break;
        case "deleteUser":
          deleteUserData();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [app, user]);

  const deleteUserData = async () => {
    try {
      return await deleteUser(user.id, user).then((res) => {
        dispatch(logoutUser());
        navigate("/login");
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const updateUser = async () => {
    try {
      return putUser(JSON.parse(stringProfile), user).then((res) => {
        let message = i18n.t("saved");
        showGlobalSnackbar(message);
        getUser();
      });
    } catch {
      let message = i18n.t("thereWasAnError");
      showGlobalSnackbar(message);
    }
  };

  const getUser = async () => {
    await axios.get(`${API}/user`, axiosConfig).then((res) => {
      dispatch(addUser(res.data.data));
    });
  };

  const changeName = (name) => {
    profile.name = name;
    setProfile({ ...profile });
  };

  const changeFirstName = (name) => {
    profile.firstName = name;
    setProfile({ ...profile });
  };

  const changeLastName = (name) => {
    profile.lastName = name;
    setProfile({ ...profile });
  };

  const changePhone = (phone) => {
    profile.phone = phone;
    setProfile({ ...profile });
  };

  const saveProfile = () => {
    if (profile.phone != null && profile.phone.trim().length > 0) {
      if (!profile.phone.includes("+")) {
        showGlobalSnackbar(i18n.t("pleaseInsertFullPhoneNumber"));
        return;
      }
    }

    setStringProfile(JSON.stringify(profile));
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmProfile"),
        confirmButton: i18n.t("confirmProfileButton"),
        denyButton: i18n.t("denyProfileButton"),
        type: "profile",
      },
    };
    dispatch(showPopup(data));
  };

  const deleteUserStart = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmUserDelete"),
        confirmButton: i18n.t("confirmUserDeleteButton"),
        denyButton: i18n.t("denyUserDeleteButton"),
        type: "deleteUser",
      },
    };
    dispatch(showPopup(data));
  };

  return (
    <div className="ProfileSettings">
      <Box className={"mainProfile"}>
        <Headline
          text={i18n.t("profileInformation")}
          type={"Headline-medium"}
        />

        <Box className={"sectionChange"}>
          <Box className={"textInputContainer"}>
            <TextInput
              type={"text"}
              setValue={(data) => changeName(data)}
              label={i18n.t("displayName")}
              value={profile?.name}
              mode={"grey"}
            />
            <TextInput
              type={"text"}
              setValue={(data) => changeFirstName(data)}
              label={i18n.t("firstName")}
              value={profile?.firstName}
              mode={"grey"}
            />
            <TextInput
              type={"text"}
              setValue={(data) => changeLastName(data)}
              label={i18n.t("lastName")}
              value={profile?.lastName}
              mode={"grey"}
            />
            <TextInput
              type={"text"}
              setValue={(data) => changePhone(data)}
              label={i18n.t("phone")}
              value={profile?.phone}
              mode={"grey"}
            />
            <Box className={"buttonContainer"} onClick={() => saveProfile()}>
              <Button text={i18n.t("editProfileInformation")} mode={"small"} />
            </Box>
          </Box>
        </Box>
        <br />
        <br />

        {/* {user.user.type === 2 ? (
          <Box className={"sectionChange bottomSection"}>
            <Headline
              text={i18n.t("deleteUserProfile")}
              type={"Headline-medium"}
            />
            <div className={"deleteWarning"}>
              {i18n.t("deleteUserProfileConformation")}
            </div>

            <div className={"deleteItems"}>
              <div className={"deleteItem"}>{i18n.t("deleteUserInfo1")}</div>
              <div className={"deleteItem"}>{i18n.t("deleteUserInfo2")}</div>
              <div className={"deleteItem"}>{i18n.t("deleteUserInfo3")}</div>
              <div className={"deleteItem"}>{i18n.t("deleteUserInfo4")}</div>
            </div>

            <div className={"deleteWarning"}>{i18n.t("actionUndoneUser")}</div>
            <Box
              className={"buttonContainer"}
              onClick={() => deleteUserStart()}
            >
              <Button text={i18n.t("deleteCenterProfileUser")} mode={"small"} />
            </Box>
          </Box>
        ) : null} */}
      </Box>
    </div>
  );
};

export default ProfileSettings;
