import * as types from "../types/actionTypes";

const initialState = {
  settings: null,
  patient: null,
  patientId: null,
  data: null,
  previousSurveyData: null,
  page: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SURVEY_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case types.UPDATE_SURVEY_PATIENT_ID:
      return {
        ...state,
        patient: null,
        patientId: action.payload,
      };
    case types.UPDATE_SURVEY_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };
    case types.UPDATE_SURVEY_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.UPDATE_SURVEY_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case types.DELETE_SURVEY_DATA:
      return {
        ...state,
        data: initialState.data,
        patient: initialState.patient
      }
    case types.UPDATE_PREVIOUS_SURVEY_DATA:
      return {
        ...state,
        previousSurveyData: action.payload,
      };
    case types.DELETE_PREVIOUS_SURVEY_DATA:
      return {
        ...state,
        previousSurveyData: initialState.data,
        patient: initialState.patient
      }
    default:
      return state;
  }
};

export default reducer;
