import React, {useEffect, useState} from 'react';
import './TabLine.scss';
import getThemes from "../../../../constants/colors/getTheme";

const TabLine = ({app, total, step}) => {
    const [activeStyle, setActiveStyle] = useState({});


    useEffect(() => {
        setActiveStyle({
            width: 100 / total + '%',
            marginLeft: (100 / total) * step + '%',
            backgroundColor: getThemes('mainColor', app.appTheme),
            height: '4px',
            marginTop: '-1px'
        })
    }, [step, app]);


    return (
      <div className="TabLine">
          <div className={"activeTabLine"} style={activeStyle}/>
      </div>
  );
};

export default TabLine;
