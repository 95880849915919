import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./PractitionersProfile.scss";
import { useTranslation } from "react-i18next";
import Headline from "../../../components/Comon/Headline/Headline";
import TextInput from "../../../components/Comon/TextInput/TextInput";
import Button from "../../../components/Comon/Button/Button";
import { useNavigate } from "react-router-dom";
import {
  deletePractitioner,
  updatePractitioner,
} from "../../../../core/axios/services";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";
import { useDispatch } from "react-redux";

const Profile = ({
  practitioner,
  setPractitioner,
  showGlobalSnackbar,
  user,
  app,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "deletePractitioner":
          deletePractitioneProfile();
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [app]);

  useEffect(() => {
    if (practitioner) {
      setFirstName(practitioner.firstName);
      setLastName(practitioner.lastName);
      setPhone(practitioner.phone);
      setEmail(practitioner.email);
    }
  }, [practitioner]);
  const updateValue = (e, type) => {
    switch (type) {
      case "firstName":
        setFirstName(e);
        break;
      case "lastName":
        setLastName(e);
        break;
      case "phone":
        setPhone(e);
        break;
      case "email":
        setEmail(e);
        break;
    }
  };

  const editPractitioner = async () => {
    try {
      const data = {
        id: practitioner.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      };
      return await updatePractitioner(user, data).then((res) => {
        showGlobalSnackbar(i18n.t("saved"));
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const deleteStart = () => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("confirmPractitionerDelete"),
        confirmButton: i18n.t("confirmPractitionerDeleteButton"),
        denyButton: i18n.t("denyUserDeleteButton"),
        type: "deletePractitioner",
      },
    };
    dispatch(showPopup(data));
  };

  const deletePractitioneProfile = async () => {
    try {
      return await deletePractitioner(user, practitioner.id).then((res) => {
        showGlobalSnackbar(i18n.t("practitionerDeleted"));
        navigate("/dashboard/practitioners");
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };
  return (
    <>
      {practitioner ? (
        <div className="profileContent">
          <Headline
            sx={{
              marginBottom: "60px",
              textAlign: "left !important;",
            }}
            text={i18n.t("practitionerProfileInformations")}
            type={"Headline-big-left"}
          />

          <Box className={"sectionChange"}>
            <Headline
              text={i18n.t("practitionerOveriview")}
              type={"Headline-medium"}
            />
            <Box className={"textInputContainer"}>
              <Box className={"inputRow"}>
                <Box className={"smallTextInput"}>
                  <TextInput
                    type={"text"}
                    label={i18n.t("firstName")}
                    placeholder={i18n.t("enterPractitionerName")}
                    mode={"grey"}
                    value={firstName}
                    setValue={(e) => updateValue(e, "firstName")}
                  />
                </Box>
                <Box className={"smallTextInput"}>
                  <TextInput
                    sx={{ marginLeft: "24px" }}
                    type={"text"}
                    label={i18n.t("lastName")}
                    placeholder={i18n.t("enterPractitionerLastName")}
                    mode={"grey"}
                    value={lastName}
                    setValue={(e) => updateValue(e, "lastName")}
                  />
                </Box>
              </Box>
              <Box className={"inputRow"}>
                <Box className={"smallTextInput"}>
                  <TextInput
                    type={"text"}
                    label={i18n.t("email")}
                    mode={"grey"}
                    placeholder={i18n.t("enterPractitionerEmail")}
                    value={email}
                    setValue={(e) => updateValue(e, "email")}
                  />
                </Box>
                <Box className={"smallTextInput"}>
                  <TextInput
                    sx={{ marginLeft: "24px" }}
                    type={"number"}
                    label={i18n.t("phoneOptional")}
                    placeholder={i18n.t("enterPractitionerPhone")}
                    mode={"grey"}
                    value={phone}
                    setValue={(e) => updateValue(e, "phone")}
                  />
                </Box>
              </Box>

              <Box className={"buttonContainer"} onClick={editPractitioner}>
                <Button
                  text={i18n.t("editPractitionerProfile")}
                  mode={"small"}
                />
              </Box>
            </Box>
          </Box>

          <Box className={"sectionChange bottomSection"}>
            <Headline text={i18n.t("deleteProfile")} type={"Headline-medium"} />

            <div className={"deleteWarning"}>{i18n.t("byDeleteProfile")}</div>

            <div className={"deleteItems"}>
              <div className={"deleteItem"}>{i18n.t("practitionerData")}</div>
              <div className={"deleteItem"}>
                {i18n.t("practitionerDataSecond")}
              </div>
            </div>

            <div className={"deleteWarning"}>{i18n.t("actionUndoneUser")}</div>
            <Box className={"buttonContainer"} onClick={deleteStart}>
              <Button
                text={i18n.t("deletePractitioneProfile")}
                mode={"small"}
              />
            </Box>
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default Profile;
