import React, { useState, useEffect, useMemo } from "react";
import "./HelpCenter.scss";
import Headline from "../../../components/Comon/Headline/Headline";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { getMyVideoCenter } from "../../../../core/axios/services";
import { useSelector } from "react-redux";
import PlayIcon from "../../../components/Survey/PlayIcon/PlayIcon";
import shadow from "../../../../../src/Shadow.png";
import AllVideos from "../../../components/Survey/VideoPlayer/AllVideos";
import getThemes from "../../../../constants/colors/getTheme";
import Accordion from "../SurveySettings/Accordion";
import demoClose from "../../../../../src/demoClose.svg";
import packageJson from "../../../../../package.json";
import linkImage from "../../../../../src/link1x.svg";
// import { Dna } from "react-loader-spinner";

const HelpCenter = () => {
  const { i18n } = useTranslation();
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const [video, setVideo] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [videoPlayIndex, setVideoPlayIndex] = useState(false);
  const [videoSize, setVideoSize] = useState("");
  const [centers, setCenters] = useState([]);
  const themeColor = useMemo(() => {
    return getThemes("mainColor", app.appTheme);
  }, [app.appTheme]);

  const [activeIndex, setActiveIndex] = useState(null);

  const [clickedId, setClickedId] = useState(localStorage.getItem("video"));

  const [loader, setLoader] = useState(false);

  const helpVideos = async () => {
    setLoader(false);
    return getMyVideoCenter(user)
      .then((res) => {
        setCenters(res.data.data.centers);
        setLoader(true);
      })
      .catch(() => {
        console.log("error");
      });
  };

  useEffect(() => {
    helpVideos();
  }, [video]);

  const yourFunction = (id) => {
    const idclick = id;
    setActiveIndex(idclick);
  };

  const [currentVideoId, setCurrentVideoId] = useState(null);

  const handlePlay = (id) => {
    const idclick = id;
    setActiveIndex(idclick);
    setCurrentVideoId(id);
  };

  const handlePause = () => {
    setCurrentVideoId(null);
  };

  const [openDemo, setOpenDemo] = useState(false);
  const [popupDetails, setPopupDetails] = useState({
    name: "",
    id: "",
  });
  const [helpLinks, setHelpLinks] = useState([]);

  const handleWatchDemo = (item) => {
    // const popupId = item.id;
    // const popupName = item.name;
    // console.log(popupId, popupName);
    setPopupDetails(item);
    setOpenDemo(true);
    setHelpLinks(item.help_links);
  };

  const handleDemoClose = () => {
    setOpenDemo(false);
  };

  const [loaderDownload, setLoaderDownload] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  const handleClickLink = async (url, isFile) => {
    setClickedItem(url);
    setLoaderDownload(true);
    if (!isFile) {
      window.open(url, "_blank").focus();
      setLoaderDownload(false);
    } else {
      try {
        //get file name, last part of url after /
        const fileNameSplits = url.split("/");
        const fileName = fileNameSplits[fileNameSplits.length - 1];

        // new link is a link sent to our server because of CORS
        const newUrl = process.env.REACT_APP_API + "/link/" + btoa(url);

        // get response of the url and blob it
        const response = await fetch(newUrl);
        const blob = await response.blob();

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        // Set the download attribute to specify the file name
        downloadLink.download = fileName; // Change the file name as needed

        // Append the link to the document
        document.body.appendChild(downloadLink);

        // Trigger the click event to start the download
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
        setLoaderDownload(false);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  const linkArr = [
    {
      id: 1,
      link: "www.google.com",
      text: "Link number 1",
    },
    {
      id: 2,
      link: "www.youtube.com",
      text: "Link",
    },
    {
      id: 3,
      link: "www.youtube.com",
      text: "Extra link with text",
    },
    {
      id: 3,
      link: "www.youtube.com",
      text: "Extra link with text",
    },
    {
      id: 3,
      link: "www.youtube.com",
      text: "Extra link with text",
    },
    {
      id: 3,
      link: "www.youtube.com",
      text: "Extra link with text",
    },
  ];

  return (
    <div className="HelpCenter">
      <Headline text={i18n.t("helpCenter")} type={"Headline-big-left"} />
      <br />
      <div className={"helpCenterMain"}>
        <p className={"helpCenterText"}>
          {i18n.t("helpCenterText")}{" "}
          <a
            className={"helpCenterLink"}
            href="mailto:support@chiropracticoutcomes.com"
          >
            support@chiropracticoutcomes.com
          </a>
        </p>

        <div className="helpCenterContent">
          {centers.map((center, index) => {
            return (
              <Accordion title={center.name} key={index}>
                <ul className="dropdownList">
                  {Array.isArray(center.videos) &&
                    center.videos.map((item, index) => {
                      return (
                        <li key={item.id}>
                          <a onClick={() => handleWatchDemo(item)}>
                            {item.title}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </Accordion>
            );
          })}
        </div>

        {openDemo && (
          <div className="demoOverlay">
            <div className="demoContent">
              <div>
                <img
                  className="demoClose"
                  src={demoClose}
                  alt="demoClose"
                  onClick={handleDemoClose}
                />
                <AllVideos
                  themeColor={themeColor}
                  url={popupDetails.video}
                  videoSize={videoSize}
                  landing={false}
                  className="videoClip"
                  volume={100}
                />

                {helpLinks?.length > 0 && (
                  <div className="videoLinks">
                    {helpLinks.map((item, index) => {
                      // Check if item.link exists and is not null
                      if (item.link) {
                        // Check if item.link is a string
                        const isString = typeof item.link === "string";

                        if (isString) {
                          // Check if item.link looks like a URL
                          const isFile =
                            item.link.split("/").pop().indexOf(".") > -1;

                          return item.link.includes("https://") ||
                            item.link.includes("www") ? (
                            <div
                              key={index}
                              onClick={() => handleClickLink(item.link, isFile)}
                            >
                              <img src={linkImage} alt={linkImage} />{" "}
                              <p>
                                {loaderDownload && clickedItem === item.link
                                  ? "downloading..."
                                  : `${item.text}`}
                              </p>
                            </div>
                          ) : (
                            <div key={index}>
                              {" "}
                              <img src={linkImage} alt={linkImage} />{" "}
                              {item.text}
                            </div>
                          );
                        } else {
                          // Handle the case where item.link is not a string
                          return (
                            <div key={index}>
                              {" "}
                              <img src={linkImage} alt={linkImage} />{" "}
                              {item.text}
                            </div>
                          );
                        }
                      } else {
                        // Handle the case where item.link is null or undefined
                        return (
                          <div key={index}>
                            {" "}
                            <img src={linkImage} alt={linkImage} /> {item.text}
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="webVersion">v{packageJson.version}</div>
    </div>
  );
};

export default HelpCenter;
