import React, {useEffect} from 'react';
import './SingleRate.scss';
import RateStar from "../../../pages/Survey/RateStar";

const SingleRate = ({themeColor, status, rate, hasSpecificRate, selectedRate}) => {
  return (
      <div className="SingleRate" style={status === 'selected' ? {backgroundColor: themeColor, color: '#FFFFFF'} : null}>
          <div className={"singleRateNumber"}>

              { hasSpecificRate ?
                <RateStar stroke={themeColor} fill={selectedRate >= rate ? themeColor : 'none'} />
                  : <span>{rate}</span> }

          </div>
      </div>
  );
}

export default SingleRate;
