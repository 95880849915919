import React from "react";
import starImage from "../../../../../src/fullStar.svg";
import emptyStar from "../../../../../src/emptyStar.svg";
import "./PractitionersProfile.scss";

const StarRating = ({ rating }) => {
  const maxStars = 5; // You can change this based on your requirements
  const filledStars = Math.floor(rating);
  const remainder = rating - filledStars;

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= maxStars; i++) {
      if (i <= filledStars) {
        stars.push(<img key={i} src={starImage} alt={starImage} />);
      } else if (i === filledStars + 1 && remainder > 0) {
        stars.push(<img key={i} src={starImage} alt={starImage} />);
      } else {
        stars.push(<img key={i} src={emptyStar} alt={emptyStar} />);
      }
    }
    return stars;
  };

  return <div className="starStyle">{renderStars()}</div>;
};

export default StarRating;
