import React, { useState, useEffect, useRef } from "react";
import "./CustomisableQuestions.scss";
import trash from "../../../../../src/trash.svg";
import enable from "../../../../../src/enable.svg";
import star from "../../../../../src/star.svg";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { showPopup } from "../../../../redux/actions/app";
import { useDispatch } from "react-redux";
import { getMyUser } from "../../../../core/axios/services";
import { useSelector } from "react-redux";
import TextInput from "../../Comon/TextInput/TextInput";

const Index = ({
  items,
  updateItemTitle,
  updateItemText,
  handleDeleteField,
  answersLang,
  businessPlan,
  userType,
  openEndedSectionTitle,
  updateOpenEndedSectionTitle,
  openEndedSectionTitleError
}) => {
  const [active, setActive] = useState(null);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const user = useSelector((state) => state.user);
  const [userSetting, setUserSetting] = useState("");

  const getMeData = async () => {
    return getMyUser(user)
      .then((res) => {
        setUserSetting(res.data.data.type);
      })
      .catch(() => {});
  };


  useEffect(() => {
    getMeData();
  }, []);

  useEffect(() => {
    i18n.cloneInstance({ lng: "en" });
    i18n.cloneInstance({ lng: "es" });

    i18n.cloneInstance({ lng: answersLang });
    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log("items   ", items);
  }, [items]);

  useEffect(() => {
    console.log("answersLang   ", answersLang);
    i18n.cloneInstance({ lng: answersLang });
  }, [answersLang]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // Click occurred outside the boxes
      setActive(null); // Remove active state
    }
  };

  const checkIfPossibleToEdit = (id) => {
    let possibleToEdit = false;
    if (businessPlan != 1) {
      possibleToEdit = true;
    }

    if (!possibleToEdit) {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("changePricePlan"),
          confirmButton: i18n.t("changePricePlanButton"),
          denyButton: i18n.t("cancel"),
          type: "changePricePlan",
        },
      };

      const dataUser = {
        showPopup: true,
        data: {
          title: i18n.t("noPermision"),
          confirmButton: i18n.t("ok"),
          type: "userPopup",
        },
      };

      if (userType == 2) {
        dispatch(showPopup(data));
      }

      if (userSetting === 3) {
        dispatch(showPopup(dataUser));
      }
    } else {
      setActive(id);
    }
  };


  return (
    <div className="CustomisableWrapper" ref={wrapperRef}>


      <div style={{marginBottom:'-20px'}}>
        <TextInput
            type={"text"}
            setValue={(value) => updateOpenEndedSectionTitle(value)}
            label="Section title"
            value={openEndedSectionTitle}
            mode={"grey"}
            errorMessage={openEndedSectionTitleError}
        />
      </div>


      {items &&
        items.map((item, index) => (
          <div
            className={`CustomisableSection ${
              item.isActive === false ? "active" : ""
            }`}
            key={index}
            onClick={() => checkIfPossibleToEdit(item.id)}
          >
            <div className={item.title?.length > 85 ? "spacing" : "title"}>
              {/*{item.title?.length > 90 ? (*/}
              {/*    <p className="scrollMore">{i18n.t("scrollToSeeMore", {lng: answersLang})}</p>*/}
              {/*) : (*/}
              {/*    ""*/}
              {/*)}*/}
              <textarea
                value={item.title} // Use defaultValue instead of value
                onChange={(e) => updateItemTitle(item.id, e.target.value)} // Use onBlur event instead of onChange
                placeholder={i18n.t("enterTitle", { lng: answersLang })}
                className={item.title?.length > 90 ? "haveSpace" : ""}
                disabled={item.isActive === false}
                maxlength="250"
              ></textarea>
            </div>
            <div className="questionField">
              {/*{item.text?.length > 90 ? (*/}
              {/*    <p className="scrollMore">{i18n.t("scrollToSeeMore")}</p>*/}
              {/*) : (*/}
              {/*    ""*/}
              {/*)}*/}
              {item.order != 2 &&
              item.order != 3 &&
              item.order != 4 &&
              item.order != 6 ? (
                <textarea
                  value={item.description} // Use defaultValue instead of value
                  onChange={(e) => updateItemText(item.id, e.target.value)} // Use onBlur event instead of onChange
                  placeholder={i18n.t("enterDescription", { lng: answersLang })}
                  className={item.text?.length > 90 ? "haveSpace" : ""}
                  disabled={item.isActive === false}
                  maxlength="250"
                />
              ) : item.order === 2 ? (
                <div className="order2">
                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="yes"
                      name="yes"
                      value="yes"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      {i18n.t("yes", { lng: answersLang })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="no"
                      name="no"
                      value="no"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      {t("no", { lng: answersLang })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      {t("other", { lng: answersLang })}
                    </label>
                  </div>
                </div>
              ) : item.order === 3 ? (
                <div className="order3">
                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="yes"
                      name="yes"
                      value="yes"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      {i18n.t("followRecommended", { lng: answersLang })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="no"
                      name="no"
                      value="no"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      {i18n.t("timesAWeek", { lng: answersLang, time: 3 })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      {i18n.t("timesAWeek", { lng: answersLang, time: 2 })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      {i18n.t("onceWeek", { lng: answersLang })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      disabled={true}
                    />
                    <label htmlFor="vehicle1">
                      {i18n.t("everyTwoWeeks", { lng: answersLang })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      disabled={true}
                    />
                    <label htmlFor="vehicle1">
                      {i18n.t("onceMonth", { lng: answersLang })}
                    </label>
                  </div>

                  <div className="checkboxWrapper">
                    <input
                      type="checkbox"
                      id="other"
                      name="other"
                      value="other"
                      disabled={true}
                    />
                    <label for="vehicle1">
                      <label htmlFor="vehicle1">
                        {i18n.t("other", { lng: answersLang })}
                      </label>
                    </label>
                  </div>
                </div>
              ) : item.order === 4 ? (
                <div className="order4">
                  <div className="star">
                    <img src={star} alt="star" width="100%" height="100%" />1
                  </div>
                  <div className="star">
                    <img src={star} alt="star" width="100%" height="100%" />2
                  </div>
                  <div className="star">
                    <img src={star} alt="star" width="100%" height="100%" />3
                  </div>
                  <div className="star">
                    <img src={star} alt="star" width="100%" height="100%" />4
                  </div>
                  <div className="star">
                    <img src={star} alt="star" width="100%" height="100%" />5
                  </div>
                </div>
              ) : item.order === 6 ? (
                <div className="order5">
                  <div className="numbers">1</div>
                  <div className="numbers">2</div>
                  <div className="numbers">3</div>
                  <div className="numbers">4</div>
                  <div className="numbers">5</div>
                  <div className="numbers">6</div>
                  <div className="numbers">7</div>
                  <div className="numbers">8</div>
                  <div className="numbers">9</div>
                  <div className="numbers">10</div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className={`deleteField`}>
              <div className="test" id={`tooltip_${item.id}`}>
                <img
                  src={item.isActive === true ? trash : enable}
                  onClick={() => handleDeleteField(item.id)}
                  alt="Delete"
                />
                <ReactTooltip
                  anchorId={`tooltip_${item.id}`}
                  place="right"
                  content={
                    item.isActive === true
                      ? "Deactivate the question"
                      : "Enable the question"
                  }
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Index;
