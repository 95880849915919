import React, { useEffect } from "react";
import "./PlayIcon.scss";

const PlayIcon = ({ themeColor }) => {
  return (
    <div className={"PlayIconContainer"}>
      <div className="PlayIcon" style={{ backgroundColor: themeColor }}>
        <img src={"/assets/Icon_Play.svg"} alt="play icon" />
      </div>
    </div>
  );
};

export default PlayIcon;
