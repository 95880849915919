import React, {useEffect, useState} from 'react';
import './Button.scss';
import {Box} from "@mui/material";
import getThemes from "../../../../constants/colors/getTheme";
import {useSelector} from "react-redux";


const Button = ({text, mode, color, border, disabled = false}) => {
    const app = useSelector((state) => state.app);
    const [buttonStyle, setButtonStyle] = useState({});
    const [textStyle, setTextStyle] = useState({});

    useEffect(() => {

        setButtonStyle({
            backgroundColor: getThemes('mainColor', app.appTheme),
            borderColor: getThemes('mainColor', app.appTheme)
        })

        if (color !== "default" && color != null) {

            if (color === 'white') {
                setButtonStyle({
                    backgroundColor: color,
                    borderColor: getThemes('mainColor', app.appTheme)
                })
            } else if (color === 'transparent') {
                setButtonStyle({
                    backgroundColor: color,
                    borderColor: getThemes('mainColor', app.appTheme),

                })
            } else {
                setButtonStyle({
                    backgroundColor: color,
                    borderColor: color
                })
            }

        }

        if (mode === "small") {
            if (color === "white") {
                setTextStyle({
                    ...textStyle,
                    fontSize: '14px',
                    color: 'black'
                });
            } else if (color === "transparent") {
                setTextStyle({
                    ...textStyle,
                    fontSize: '14px',
                    color: getThemes('mainColor', app.appTheme),
                });
            } else {
                setTextStyle({
                    ...textStyle,
                    fontSize: '14px',
                    color: "white"
                });
            }

        }


        if (disabled === true) {
            setButtonStyle({
                backgroundColor: "#C5C5C5",
                borderColor: "#C5C5C5",
            })

            setTextStyle({
                fontSize: '14px',
                color: "#A0A0A0"
            });
        }

        if (border === 'red') {
            setButtonStyle({
                ...buttonStyle,
                borderColor: "red ",
                backgroundColor: "white",

            });
        }


    }, [color, app, disabled]);

    return (
        <Box className="Button" style={buttonStyle}>
            <div className="buttonText" style={textStyle}> {text} </div>
        </Box>
    );
};

export default Button;
