import React from "react";
import "./TestPrivacy.scss";

const TestPrivacy = () => {
  return (
    <div className="testPrivacyWrap">
      <h1>Data Privacy Notice for Patients, Clients & Technology Use</h1>
      <span>Updated at 2023-03-18</span>

      <div className="testPrivacyContent">
        <h2>Purpose of the Disclaimer</h2>
        <p>
          This disclaimer is intended to inform users about the limitations,
          responsibilities, and risks associated with the use of Chiropractic
          Outcomes’ patient data collection software (hereinafter referred to as
          "the Software" or “the Site”). By using the Software, users
          acknowledge that they have read, understood, and agreed to the terms
          and conditions outlined in this disclaimer.
        </p>
        <h2>Software Functionality and Intended Use</h2>
        <p>
          The Software is designed to collect, store, and manage patient data
          for wellness purposes. It facilitates data entry, organization, and
          retrieval for authorized wellness professionals and entities. The
          Software is not intended to provide medical advice, diagnosis, or
          treatment.
        </p>
        <h2>Data Security and Confidentiality</h2>
        <p>
          Chiropractic Outcomes is committed to maintaining the security and
          confidentiality of patient data collected through the Software.
          Patients/clients/users acknowledge and accept that there are inherent
          risks associated with the electronic transmission and storage of
          sensitive information.
        </p>
        <h2>User Responsibilities</h2>
        <p>
          Users of the Software are responsible for ensuring compliance with
          applicable laws, regulations, and ethical guidelines governing the
          collection, use, and protection of patient data. Users must obtain
          appropriate patient consent, adhere to data protection standards, and
          implement necessary safeguards to protect patient privacy.
        </p>
        <h2>Our Privacy Policy</h2>
        <p>
          Your privacy is very important to us. This policy outlines who we are,
          what personal information we collect, how we use it, and how you may
          update your personal information.
          <br />
          We do not and will not sell your personal information. We do not
          discriminate against individuals who exercise their privacy rights.
        </p>
        <h2>Your personal data – what is it?</h2>
        <p>
          Personal data relates to a living individual who can be identified
          from that data. Identification can be by the information alone or in
          conjunction with any other information in the data controller’s
          possession or likely to come into such possession. The processing of
          personal data of EEA residents is governed by the EU General Data
          Protection Regulation and that of UK residents is governed by the UK
          General Data Protection Regulation; when we refer to “GDPR” we mean
          the EEA and UK regulations.
        </p>
        <h2>Who are we?</h2>
        <p>
          Chiropractic Outcomes OÜ (“we”, “us” or “our”) is in receipt of your
          personal information pursuant to a purchase between Chiropractic
          Outcomes and one of our clients or pursuant to information you have
          provided directly to us. This means that either our client or you, as
          applicable, is the data controller who determines the purposes and
          means by which Chiropractic Outcomes, as data processor, processes
          your personal data, including sending to third party applications
          where required.
        </p>
        <h2>How do we process your personal data?</h2>
        <p>
          Chiropractic Outcomes complies with its obligations under relevant
          data privacy laws, including the GDPR, by keeping personal data up to
          date; by storing and destroying it securely; by not collecting or
          retaining excessive amounts of data; by protecting personal data from
          loss, misuse, unauthorized access and disclosure, and by ensuring that
          appropriate technical measures are in place to protect personal data.
          <br />
          Your personal data that was provided to Chiropractic Outcomes will be
          stored in Chiropractic Outcomes’ data centers in the United States
          and/or Europe for the purpose of providing you with access to our
          suite of services, technology platform, and solutions.
        </p>
        <h2>Why are we collecting your personal data?</h2>
        <p>
          We use your personal data for the following purposes:
          <ul>
            <li>
              To provide access to Chiropractic Outcomes’ suite of services,
              technology platform, and solutions - To administer and manage user
              accounts and take action with respect to user accounts
            </li>
            <li>To provide user support</li>
            <li>To look into any complaints or questions you may raise</li>
            <li>
              To inform you of opportunities, events and activities we or the
              Chiropractic Outcomes client are undertaking (e.g. upgrades,
              downtime, training, feedback)
            </li>
            <li>
              To update you on any changes or improvements in the technology
              products
            </li>
            <li>
              To gather business intelligence about usage trends which may be
              derived from personal information
            </li>
            <li>
              To transmit data directly to another data processor, to a client
              of Chiropractic Outcomes or to a client of a Chiropractic Outcomes
              client in connection with services being rendered to any such
              parties
            </li>
          </ul>
        </p>
        <h2>What type of personal data do we have?</h2>
        <p>
          The personal data about you that we retain allows you to successfully
          work in our technology product and is usually limited to the
          following:
          <ul>
            <li>
              Name, email address, and a username if you are using one of our,
            </li>
            <li>
              Survey responses, health metrics, open feedback/comments/ratings
            </li>
            <li>
              The company you are directly working for or providing services to
              while using our technology platform.
            </li>
          </ul>
        </p>
        <h2>What is the legal basis for processing your personal data?</h2>
        <p>
          We are processing your personal information under several legal bases,
          including because it is:
          <ul>
            <li>necessary for compliance with our legal obligations</li>
            <li>
              necessary for the performance of a contract between Chiropractic
              Outcomes and you, Chiropractic Outcomes and a Chiropractic
              Outcomes client or between you and a Chiropractic Outcomes client
            </li>
            <li>
              necessary for our legitimate business interests (e.g., engaging
              with Chiropractic Outcomes clients in order to satisfy contract
              requirements and for record keeping purposes)
            </li>
            <li>
              necessary for the establishment, exercise, or defense of legal
              claims and/o
            </li>
            <li>pursuant to your consen</li>
          </ul>
        </p>
        <h2>Sharing your personal data</h2>
        <p>
          Your personal data will be treated as strictly confidential and will
          be shared only internally among Chiropractic Outcomes employees,
          clients, and in certain limited circumstances, with third parties,
          such as other technology applications with respect to which
          Chiropractic Outcomes’ client has directed Chiropractic Outcomes to
          integrate.
        </p>
        <h2>How long do we keep your personal data?</h2>
        <p>
          We will keep your personal data for no longer than reasonably
          necessary for our ongoing business relationship and for record keeping
          purposes.
        </p>
        <h2>Your rights and your personal data</h2>
        <p>
          Individuals in the European Economic Area (EEA), the United Kingdom,
          Brazil, the State of California, and in numerous other jurisdictions
          across the globe have certain statutory rights in relation to their
          personal data. Subject to any exemptions provided by the law, you may
          have rights with respect to your personal data, including, but not
          limited to:
          <ul>
            <li>
              The right to request a copy of your personal data which
              Chiropractic Outcomes holds about you (for EEA or United Kingdom
              residents)
            </li>
            <li>
              The right to know the categories and purposes of personal data
              that we collect about you (for California residents)
            </li>
            <li>
              The right to request that Chiropractic Outcomes corrects any
              personal data if it is found to be inaccurate or out of date (for
              EEA or United Kingdom residents)
            </li>
            <li>
              The right to request your personal data is erased where it is no
              longer legally necessary for Chiropractic Outcomes to retain such
              data
            </li>
            <li>
              The right to withdraw your consent to the processing at any time
              (for EEA or United Kingdom residents), including to opt-out from
              all communication from all marketing communications from.
            </li>
            <li>
              The right to request that Chiropractic Outcomes provide you, as
              the data subject, with your personal data and where possible, to
              transmit that data directly to another data controller (for EEA,
              United Kingdom or California residents)
            </li>
            <li>
              The right, where there is a dispute in relation to the accuracy or
              processing of your personal data, to request a restriction is
              placed on further processing (for EEA or United Kingdom residents)
            </li>
            <li>
              The right to lodge a complaint with governmental agencies or Data
              Protection Authorities as provided for in the GDPR (for EEA or
              United Kingdom residents).
            </li>
          </ul>
          To exercise your rights to data rectification, erasure, portability,
          access, restricted processing and/or opt out of Chiropractic Outcomes
          processing your personal data, please contact{" "}
          <a href="mailto:support@chiropracticoutcomes.com">
            support@chiropracticoutcomes.com
          </a>
        </p>
        <h2>Storage of Data</h2>
        <p>
          Chiropractic Outcomes complies with its obligations under the GDPR by
          keeping personal data up to date; by storing and destroying it
          securely; by not retaining excessive amounts of data; by protecting
          personal data from loss, misuse, unauthorized access and disclosure
          and by ensuring that appropriate technical measures are in place to
          protect personal data.
        </p>
        <h2>Further Processing</h2>
        <p>
          If we wish to use your personal data for a new purpose not covered by
          this Data Privacy Notice, we will provide you with a new notice
          explaining this new use prior to commencing the processing and setting
          out the relevant purposes and processing conditions. Where and
          whenever necessary, we will seek your prior consent to the new
          processing.
        </p>
        <h2>Limited Liability</h2>
        <p>
          Chiropractic Outcomes shall not be held liable for any damages,
          losses, or liabilities arising from the use of the Software, including
          but not limited to, errors, omissions, data breaches, or unauthorized
          access to patient data. Users assume all risks associated with the use
          of the Software.
        </p>
        <h2>Third-Party Services</h2>
        <p>
          The Software may integrate or rely on third-party services, such as
          cloud storage providers or data processors. While Chiropractic
          Outcomes endeavors to select reputable service providers, it cannot be
          held liable for the actions, security measures, or data breaches of
          these third-party services.
        </p>
        <h2>Contact Details</h2>
        <p>
          If you have any further queries or complaints, please contact:
          <br /> <br />
          Brock McCurdy
          <br />
          Chiropractic Outcomes
          <br />
          <a href="mailto:support@chiropracticoutcomes.com">
            support@chiropracticoutcomes.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default TestPrivacy;
