import React from 'react';
import './TextInputLabel.scss';


const TextInputLabel = ({text, labelClass = ''}) => {
    return (
        <span className={'TextInputLabel ' + labelClass}>{text}</span>
    );
}


export default TextInputLabel;
